import { Box, Text, Button, Grid, Flex } from '@chakra-ui/core';
import { jsx, css, keyframes } from '@emotion/core';
import React, { useEffect, useState } from 'react';
import Card from '../components/Card';
import { ReduxProps, connector } from '../components/home/HomeConstants';
import { ICartItem } from '../redux/cart/CartTypes';
import Axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import { configFile } from '../configFile';
import Footer from '../components/footer/Footer';
import Navbar from '../components/navbar/Navbar';

const CouponScreen = (reduxProps: ReduxProps): JSX.Element => {
  interface ICoupon {
    validTo: string;
    pictureId: number | null;
    filePath: string;
    codeToUse: string;
    isMulti: boolean;
    amount: number;
  }
  interface IFile {
    id: number;
    filePath: string;
  }
  const [coupons, setCoupons] = useState<any>();
  const [promoCoupons, setPromoCoupons] = useState<any>();
  const [promoCouponsT, setPromoCouponsT] = useState<any>();

  const location = useLocation();
  const currentUrl = location.pathname;
  const currentCity = currentUrl.split('/')[1];

  const getCurrentWeekNumber = () => {
    // Create a new date object
    const now = new Date();

    // Set the target date to January 1st of the current year
    const targetDate = new Date(now.getFullYear(), 0, 1);

    // Calculate the difference in milliseconds between the current date and January 1st
    // @ts-ignore
    const timeDifference = now - targetDate;

    // Calculate the number of days elapsed
    const daysElapsed = Math.floor(timeDifference / (24 * 60 * 60 * 1000));

    // Calculate the week number (add 1 to start from week 1)
    const weekNumber = Math.ceil((daysElapsed + targetDate.getDay() + 1) / 7);

    return weekNumber % 2 === 0 ? 0 : 1;
  };

  const getCurrentPromos = (items: any, tmrw?: boolean) => {
    const today = new Date();
    const day = today.getDay();

    return items[tmrw ? day + 1 : day]
      .split(',')
      .map((str) => parseInt(str, 10));
  };

  const fetchCoupons = async () => {
    try {
      const couponData = await (await Axios.get(`/coupons`)).data;
      const promotions = await (await Axios.get('/coupons/getPromos')).data;

      let pictureIdArray: Array<number> = [];
      couponData.forEach((coupon: ICoupon) => {
        if (coupon.pictureId) {
          pictureIdArray.push(coupon.pictureId);
        }
      });

      const files = await (
        await Axios.post('/coupons/getFilePaths', {
          pictureIdArray,
        })
      ).data;

      couponData.forEach((coupon: ICoupon) => {
        files.forEach((file: IFile) => {
          if (coupon.pictureId === file.id) {
            coupon.filePath = file.filePath;
          }
        });
      });
      const promosToFilter = getCurrentPromos(
        promotions[getCurrentWeekNumber()]
      );

      setCoupons(couponData.filter((i) => !promosToFilter.includes(i.id)));
      console.log(couponData.filter((i) => !promosToFilter.includes(i.id)));

      setPromoCoupons(couponData.filter((i) => promosToFilter.includes(i.id)));
      setPromoCouponsT(
        couponData.filter((i) =>
          getCurrentPromos(promotions[getCurrentWeekNumber()], true).includes(
            i.id
          )
        )
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (reduxProps.isLoggedIn) {
      fetchCoupons();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reduxProps.isLoggedIn]);

  return reduxProps.isLoggedIn ? (
    <>
      <Navbar />
      <Box as='main' color='black' mt={8} w='100%'>
        <Card
          p={1}
          ml={8}
          w='200px'
          borderRadius='8px 8px 0 0'
          textAlign='center'
          textTransform='uppercase'
          fontWeight='bold'
        >
          Kuponok
        </Card>
        <Card shadow='2px 2px 2px 0px rgba(0,0,0,0.75)'>
          <Text fontWeight='bold'>
            {' '}
            Itt találhatod a jelenleg aktuális kuponjainkat.
          </Text>
          <Text fontWeight='bold'>
            A kuponok csak a termékek kosárba helyezése után használhatók fel.
          </Text>
        </Card>
        {coupons?.constructor === Array && (
          <Grid
            gridTemplateColumns={[
              'repeat(auto-fill, 100%)',
              'repeat(auto-fill, 100%)',
              '1fr 1fr',
              '1fr 1fr',
            ]}
            gap={6}
            marginTop='20px'
          >
            {promoCoupons
              ?.filter(
                (coupon) =>
                  coupon.whichCity === currentCity ||
                  coupon.whichCity === 'BOTH'
              )
              .map((coupon: ICoupon, index: number) => (
                <CouponCard
                  isPromo
                  key={index}
                  validTo={coupon?.validTo}
                  filePath={coupon?.filePath ? coupon.filePath : null}
                  cartItems={reduxProps?.cartItems}
                  code={coupon?.codeToUse}
                  isMulti={coupon?.isMulti}
                  amount={coupon?.amount}
                />
              ))}
            {promoCouponsT
              ?.filter(
                (coupon) =>
                  coupon.whichCity === currentCity ||
                  coupon.whichCity === 'BOTH'
              )
              .map((coupon: ICoupon, index: number) => (
                <CouponCard
                  isPromo
                  isPromoT
                  key={index}
                  validTo={coupon?.validTo}
                  filePath={coupon?.filePath ? coupon.filePath : null}
                  cartItems={reduxProps?.cartItems}
                  code={coupon?.codeToUse}
                  isMulti={coupon?.isMulti}
                  amount={coupon?.amount}
                />
              ))}
            {coupons
              ?.filter(
                (coupon) =>
                  coupon.displayedOnFrontend &&
                  (coupon.whichCity === currentCity ||
                    coupon.whichCity === 'BOTH')
              )
              .map((coupon: ICoupon, index: number) => (
                <CouponCard
                  key={index}
                  validTo={coupon?.validTo}
                  filePath={coupon?.filePath ? coupon.filePath : null}
                  cartItems={reduxProps?.cartItems}
                  code={coupon?.codeToUse}
                  isMulti={coupon?.isMulti}
                  amount={coupon?.amount}
                />
              ))}
          </Grid>
        )}
      </Box>
      <Footer />
    </>
  ) : (
    <>
      <Navbar />
      <Box as='main' color='black' mt={8} w='100%'>
        <Card shadow='2px 2px 2px 0px rgba(0,0,0,0.75)'>
          Ez az oldal csak bejelentkezett felhasználóknak érhető el.
        </Card>
      </Box>
      <Footer />
    </>
  );
};

const CouponCard = ({
  validTo,
  filePath,
  cartItems,
  code,
  isMulti,
  amount,
  isPromo,
  isPromoT,
}: {
  validTo: string;
  filePath: string | null;
  cartItems: ICartItem[];
  code: string;
  isMulti: boolean;
  amount: number;
  isPromo?: boolean;
  isPromoT?: boolean;
}) => {
  const formatMonth = (monthNumber: number) => {
    if (monthNumber < 10) {
      return '0' + monthNumber.toString();
    } else {
      return monthNumber;
    }
  };

  const pulse = keyframes`
  0% {
    transform: scale(1.03);
  }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 15px rgba(255, 10, 10,0);
  }
    100% {
      transform: scale(1.03);
    box-shadow: 0 0 0 0 rgba(255, 10, 10,0);
  }
`;

  const validToDate = new Date(validTo);

  const validToUTC = `${formatMonth(
    validToDate.getUTCMonth() + 1
  )}.${formatMonth(validToDate.getUTCDate())}`;

  const history = useNavigate();
  const location = useLocation();
  const city = location.pathname.split('/')[1];

  const handleActivate = () => {
    if (cartItems && cartItems?.length > 0)
      history(`/${city}/cart?withCoupon=${code}`);
  };

  return (
    <>
      <Box
        w='100%'
        bg='white'
        height='300px'
        borderRadius='6px'
        backgroundImage={`url(${configFile.serverBaseUrlPicture}${filePath})`}
        backgroundPosition='center'
        backgroundSize='cover'
        position='relative'
        overflow='hidden'
      >
        {!isMulti && (
          <Flex
            backgroundColor='rgb(218, 21, 21)'
            borderRadius='6px'
            top='3'
            p={2}
            right='3'
            flexDirection='column'
            justifyContent='center'
            alignItems='center'
            position='absolute'
          >
            <Text fontWeight='bold' fontSize='md' color='white'>
              {amount} db
            </Text>
          </Flex>
        )}
        {isPromo && (
          <Button
            top='1'
            left='3'
            position='absolute'
            justifyContent='center'
            alignItems='center'
            borderRadius='6px'
            backgroundColor={isPromoT ? 'orange.500' : 'rgb(218, 21, 21)'}
            p={3}
            mt='10px'
            boxShadow='0 0 0 0 rgba(255, 10, 10,0.5)'
            css={css`
              animation: ${pulse} 1.5s infinite;
            `}
            _hover={{ cursor: 'auto' }}
          >
            <Text fontSize={16} color='white'>
              {isPromoT ? 'Holnapi kupon ajánlat' : 'Mai kupon ajánlat'}
            </Text>
          </Button>
        )}
        <Flex position='absolute' bottom='0' right='0' flexDirection='row'>
          {/** 
          <Flex
            flexDirection='column'
            justifyContent='center'
            alignItems='center'
            p={3}
            px={5}
            backgroundColor='orange.500'
            color='white'
            fontWeight='bold'
            transform='skewX(-30deg)'
            transformOrigin='bottom right'
          >
            <Text fontSize='md' transform='skewX(30deg)'>
              Érvényes
            </Text>
            <Text fontSize='md' transform='skewX(30deg)'>
              {validToUTC}
              {` ${validToDate.getHours()}:${validToDate.getMinutes()}`}-ig
            </Text>
          </Flex> */}
          <Flex>
            <Button
              height={16}
              backgroundColor='rgb(218, 21, 21)'
              borderRadius='0px'
              borderTopLeftRadius='20px'
              color='white'
              _hover={{
                backgroundColor: 'red.600',
              }}
              pr={6}
              pl={6}
              onClick={handleActivate}
              isDisabled={isPromoT}
            >
              <Text fontWeight='bold' fontSize='2xl' textAlign='center'>
                Felhasználom
              </Text>
            </Button>
          </Flex>
        </Flex>
      </Box>
    </>
  );
};

export default connector(CouponScreen);
