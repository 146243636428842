import { Link, LinkProps } from "@chakra-ui/core";
import React from "react";
import { Link as ReactRouterLink } from "react-router-dom";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const LinkAsAny = Link as any;

export function InfoLink({ ...props }: LinkProps): JSX.Element {
  return <Link _hover={{ textDecoration: "none" }} {...props} />;
}

export function NavLink({ ...props }: LinkProps): JSX.Element {
  return (
    <Link
      p={2}
      _hover={{ color: "white", textDecoration: "none" }}
      {...props}
    />
  );
}

export function InfoRouterLink({ href, ...props }: LinkProps): JSX.Element {
  return (
    <LinkAsAny
      as={ReactRouterLink}
      to={href}
      _hover={{ textDecoration: "none" }}
      {...props}
    />
  );
}

export function NavRouterLink({ href, ...props }: LinkProps): JSX.Element {
  return (
    <LinkAsAny
      as={ReactRouterLink}
      to={href}
      p={2}
      _hover={{ color: "white", textDecoration: "none" }}
      {...props}
    />
  );
}
