export const szolnokData = [
  {
    name: 'Abonyi út',
    category: 'a',
  },
  {
    name: 'Acél utca',
    category: 'a',
  },
  {
    name: 'Áchim András utca',
    category: 'a',
  },
  {
    name: 'Ács utca',
    category: 'a',
  },
  {
    name: 'Ady Endre utca',
    category: 'a',
  },
  {
    name: 'Ág utca',
    category: 'a',
  },
  {
    name: 'Alcsi utca',
    category: 'a',
  },
  {
    name: 'Álmos utca',
    category: 'a',
  },
  {
    name: 'Alvégi utca',
    category: 'a',
  },
  {
    name: 'Aradi köz',
    category: 'a',
  },
  {
    name: 'Aradi utca',
    category: 'a',
  },
  {
    name: 'Aranyi Sándor utca',
    category: 'a',
  },
  {
    name: 'Arany János utca',
    category: 'a',
  },
  {
    name: 'Aranyos utca',
    category: 'a',
  },
  {
    name: 'Arató utca',
    category: 'a',
  },
  {
    name: 'Aréna utca',
    category: 'a',
  },
  {
    name: 'Árok utca',
    category: 'a',
  },
  {
    name: 'Árpád utca',
    category: 'a',
  },
  {
    name: 'Attila utca',
    category: 'a',
  },
  {
    name: 'Aulich utca',
    category: 'a',
  },
  {
    name: 'Avar utca',
    category: 'a',
  },
  {
    name: 'Bacsó Nándor utca',
    category: 'a',
  },
  {
    name: 'Bajcsy-Zsilinszky út',
    category: 'a',
  },
  {
    name: 'Bajnok utca',
    category: 'a',
  },
  {
    name: 'Bajtárs utca',
    category: 'a',
  },
  {
    name: 'Balassa Bálint utca',
    category: 'a',
  },
  {
    name: 'Balogh Ádám utca',
    category: 'a',
  },
  {
    name: 'Balogh Béla utca',
    category: 'a',
  },
  {
    name: 'Bálvány utca',
    category: 'a',
  },
  {
    name: 'Bánát utca',
    category: 'a',
  },
  {
    name: 'Bán utca',
    category: 'a',
  },
  {
    name: 'Bányai Kornél utca',
    category: 'a',
  },
  {
    name: 'Bányász utca',
    category: 'a',
  },
  {
    name: 'Barátság utca',
    category: 'a',
  },
  {
    name: 'Barázda utca',
    category: 'a',
  },
  {
    name: 'Bárka utca',
    category: 'a',
  },
  {
    name: 'Baross utca',
    category: 'a',
  },
  {
    name: 'Bartók Béla utca',
    category: 'a',
  },
  {
    name: 'Bástya utca',
    category: 'a',
  },
  {
    name: 'Báthory István utca',
    category: 'a',
  },
  {
    name: 'Batsányi utca',
    category: 'a',
  },
  {
    name: 'Batthyány utca',
    category: 'a',
  },
  {
    name: 'Béke utca',
    category: 'a',
  },
  {
    name: 'Béla király utca',
    category: 'a',
  },
  {
    name: 'Bem utca',
    category: 'a',
  },
  {
    name: 'Benedek utca',
    category: 'a',
  },
  {
    name: 'Bercsényi utca',
    category: 'a',
  },
  {
    name: 'Berzsenyi Dániel utca',
    category: 'a',
  },
  {
    name: 'Bethlen Gábor utca',
    category: 'a',
  },
  {
    name: 'Bihari Sándor utca',
    category: 'a',
  },
  {
    name: 'Bimbó utca',
    category: 'a',
  },
  {
    name: 'Bocskai István utca',
    category: 'a',
  },
  {
    name: 'Bogár utca',
    category: 'a',
  },
  {
    name: 'Boglárka utca',
    category: 'a',
  },
  {
    name: 'Bognár utca',
    category: 'a',
  },
  {
    name: 'Bojtár utca',
    category: 'a',
  },
  {
    name: 'Bokor utca',
    category: 'a',
  },
  {
    name: 'Bokréta utca',
    category: 'a',
  },
  {
    name: 'Boldog Sándor István körút',
    category: 'a',
  },
  {
    name: 'Bolt köz',
    category: 'a',
  },
  {
    name: 'Bonifác utca',
    category: 'a',
  },
  {
    name: 'Bors utca',
    category: 'a',
  },
  {
    name: 'Botár Imre utca',
    category: 'a',
  },
  {
    name: 'Botond utca',
    category: 'a',
  },
  {
    name: 'Budai Nagy Antal utca',
    category: 'a',
  },
  {
    name: 'Budapest utca',
    category: 'a',
  },
  {
    name: 'Bujdosó utca',
    category: 'a',
  },
  {
    name: 'Bulcsu utca',
    category: 'a',
  },
  {
    name: 'Búzavirág utca',
    category: 'a',
  },
  {
    name: 'Buzogány utca',
    category: 'a',
  },
  {
    name: 'Büge utca',
    category: 'a',
  },
  {
    name: 'Ceglédi utca',
    category: 'a',
  },
  {
    name: 'Czakó Elemér utca',
    category: 'a',
  },
  {
    name: 'Csaba utca',
    category: 'a',
  },
  {
    name: 'Csáklya utca',
    category: 'a',
  },
  {
    name: 'Csallóköz utca',
    category: 'a',
  },
  {
    name: 'Csalogány utca',
    category: 'a',
  },
  {
    name: 'Csendes utca',
    category: 'a',
  },
  {
    name: 'Cserép utca',
    category: 'a',
  },
  {
    name: 'Cserje utca',
    category: 'a',
  },
  {
    name: 'Csillag utca',
    category: 'a',
  },
  {
    name: 'Csóka utca',
    category: 'a',
  },
  {
    name: 'Csokonai utca',
    category: 'a',
  },
  {
    name: 'Csokor utca',
    category: 'a',
  },
  {
    name: 'Csont utca',
    category: 'a',
  },
  {
    name: 'Damjanich út',
    category: 'a',
  },
  {
    name: 'Dandár utca',
    category: 'a',
  },
  {
    name: 'Darázs utca',
    category: 'a',
  },
  {
    name: 'Daru utca',
    category: 'a',
  },
  {
    name: 'Délibáb utca',
    category: 'a',
  },
  {
    name: 'Delta utca',
    category: 'a',
  },
  {
    name: 'Dembinszky utca',
    category: 'a',
  },
  {
    name: 'Dobó István utca',
    category: 'a',
  },
  {
    name: 'Dobos Károly utca',
    category: 'a',
  },
  {
    name: 'Dózsa György utca',
    category: 'a',
  },
  {
    name: 'Dráva utca',
    category: 'a',
  },
  {
    name: 'Dr. Balogh Kálmán utca',
    category: 'a',
  },
  {
    name: 'Dr. Béres József sétány',
    category: 'a',
  },
  {
    name: 'Dr. Durst János utca',
    category: 'a',
  },
  {
    name: 'Dr. Elek István utca',
    category: 'a',
  },
  {
    name: 'Dr. Hegedűs Lajos sétány',
    category: 'a',
  },
  {
    name: 'Dr. Kronberg János utca',
    category: 'a',
  },
  {
    name: 'Dr. Sarkady László utca',
    category: 'a',
  },
  {
    name: 'Dr. Sebestény Gyula út',
    category: 'a',
  },
  {
    name: 'Egyetértés utca',
    category: 'a',
  },
  {
    name: 'Ék utca',
    category: 'a',
  },
  {
    name: 'Ellmann Elvira utca',
    category: 'a',
  },
  {
    name: 'Előd utca',
    category: 'a',
  },
  {
    name: 'Előre utca',
    category: 'a',
  },
  {
    name: 'Eötvös tér',
    category: 'a',
  },
  {
    name: 'Erdész út',
    category: 'a',
  },
  {
    name: 'Fácán utca',
    category: 'a',
  },
  {
    name: 'Fakopács utca',
    category: 'a',
  },
  {
    name: 'Farkas utca',
    category: 'a',
  },
  {
    name: 'Fáy András utca',
    category: 'a',
  },
  {
    name: 'Fék utca',
    category: 'a',
  },
  {
    name: 'Félcsizma utca',
    category: 'a',
  },
  {
    name: 'Felhő utca',
    category: 'a',
  },
  {
    name: 'Fényes Adolf utca',
    category: 'a',
  },
  {
    name: 'Fenyő utca',
    category: 'a',
  },
  {
    name: 'Fertő utca',
    category: 'a',
  },
  {
    name: 'Fillér utca',
    category: 'a',
  },
  {
    name: 'Fiumei utca',
    category: 'a',
  },
  {
    name: 'Fiumei út',
    category: 'a',
  },
  {
    name: 'Fogoly utca',
    category: 'a',
  },
  {
    name: 'Fúró utca',
    category: 'a',
  },
  {
    name: 'Fuvaros utca',
    category: 'a',
  },
  {
    name: 'Füge utca',
    category: 'a',
  },
  {
    name: 'Fűrész utca',
    category: 'a',
  },
  {
    name: 'Fürj utca',
    category: 'a',
  },
  {
    name: 'Füzér utca',
    category: 'a',
  },
  {
    name: 'Gábor Áron tér',
    category: 'a',
  },
  {
    name: 'Galagonya utca',
    category: 'a',
  },
  {
    name: 'Galamb utca',
    category: 'a',
  },
  {
    name: 'Garázsok útja',
    category: 'a',
  },
  {
    name: 'Gárdonyi Géza utca',
    category: 'a',
  },
  {
    name: 'Garibaldi utca',
    category: 'a',
  },
  {
    name: 'Gát utca',
    category: 'a',
  },
  {
    name: 'Gáz utca',
    category: 'a',
  },
  {
    name: 'Geleji Katona István utca',
    category: 'a',
  },
  {
    name: 'Gém utca',
    category: 'a',
  },
  {
    name: 'Gép utca',
    category: 'a',
  },
  {
    name: 'Gerbera utca',
    category: 'a',
  },
  {
    name: 'Gerle utca',
    category: 'a',
  },
  {
    name: 'Glykais Gyula utca',
    category: 'a',
  },
  {
    name: 'Gólya park',
    category: 'a',
  },
  {
    name: 'Gólya utca',
    category: 'a',
  },
  {
    name: 'Gomba utca',
    category: 'a',
  },
  {
    name: 'Gőzhajó utca',
    category: 'a',
  },
  {
    name: 'Gurító utca',
    category: 'a',
  },
  {
    name: 'Gutenberg tér',
    category: 'a',
  },
  {
    name: 'Guyon Richárd utca',
    category: 'a',
  },
  {
    name: 'Gyár utca',
    category: 'a',
  },
  {
    name: 'Gyermekváros utca',
    category: 'a',
  },
  {
    name: 'Gyík utca',
    category: 'a',
  },
  {
    name: 'Gyopár utca',
    category: 'a',
  },
  {
    name: 'Gyökér utca',
    category: 'a',
  },
  {
    name: 'Gyöngyvirág utca',
    category: 'a',
  },
  {
    name: 'Győrffy István utca',
    category: 'a',
  },
  {
    name: 'Gyufa utca',
    category: 'a',
  },
  {
    name: 'Hajdú utca',
    category: 'a',
  },
  {
    name: 'Hajnóczy József utca',
    category: 'a',
  },
  {
    name: 'Halász utca',
    category: 'a',
  },
  {
    name: 'Halom utca',
    category: 'a',
  },
  {
    name: 'Háló utca',
    category: 'a',
  },
  {
    name: 'Hangya utca',
    category: 'a',
  },
  {
    name: 'Hant utca',
    category: 'a',
  },
  {
    name: 'Hanzély Pál utca',
    category: 'a',
  },
  {
    name: 'Harang utca',
    category: 'a',
  },
  {
    name: 'Harcsa utca',
    category: 'a',
  },
  {
    name: 'Harmat utca',
    category: 'a',
  },
  {
    name: 'Háromrózsa utca',
    category: 'a',
  },
  {
    name: 'Hársfa utca',
    category: 'a',
  },
  {
    name: 'Határ utca',
    category: 'a',
  },
  {
    name: 'Hattyú utca',
    category: 'a',
  },
  {
    name: 'Havas utca',
    category: 'a',
  },
  {
    name: 'Hét vezér utca',
    category: 'a',
  },
  {
    name: 'Hild János tér',
    category: 'a',
  },
  {
    name: 'Hild Viktor utca',
    category: 'a',
  },
  {
    name: 'Himba utca',
    category: 'a',
  },
  {
    name: 'Hód köz',
    category: 'a',
  },
  {
    name: 'Hold utca',
    category: 'a',
  },
  {
    name: 'Holló utca',
    category: 'a',
  },
  {
    name: 'Homokbányai út',
    category: 'a',
  },
  {
    name: 'Horánszky utca',
    category: 'a',
  },
  {
    name: 'Horog utca',
    category: 'a',
  },
  {
    name: 'Hóvirág utca',
    category: 'a',
  },
  {
    name: 'Hősök tere',
    category: 'a',
  },
  {
    name: 'Hubay Ferenc utca',
    category: 'a',
  },
  {
    name: 'Hullám utca',
    category: 'a',
  },
  {
    name: 'Hunor utca',
    category: 'a',
  },
  {
    name: 'Hunyadi János utca',
    category: 'a',
  },
  {
    name: 'Ibolya utca',
    category: 'a',
  },
  {
    name: 'Ifjúság utca',
    category: 'a',
  },
  {
    name: 'Ilona utca',
    category: 'a',
  },
  {
    name: 'Indóház utca',
    category: 'a',
  },
  {
    name: 'Ipar utca',
    category: 'a',
  },
  {
    name: 'Irgalmas utca',
    category: 'a',
  },
  {
    name: 'Iszap utca',
    category: 'a',
  },
  {
    name: 'Jácint utca',
    category: 'a',
  },
  {
    name: 'Jászkürt utca',
    category: 'a',
  },
  {
    name: 'Jázmin utca',
    category: 'a',
  },
  {
    name: 'Jókai utca',
    category: 'a',
  },
  {
    name: 'Jósika utca',
    category: 'a',
  },
  {
    name: 'József Attila utca',
    category: 'a',
  },
  {
    name: 'József utca',
    category: 'a',
  },
  {
    name: 'Jubileum tér',
    category: 'a',
  },
  {
    name: 'Kaán Károly utca',
    category: 'a',
  },
  {
    name: 'Kacsa utca',
    category: 'a',
  },
  {
    name: 'Káka út',
    category: 'a',
  },
  {
    name: 'Kalapács utca',
    category: 'a',
  },
  {
    name: 'Kalász utca',
    category: 'a',
  },
  {
    name: 'Kalmár utca',
    category: 'a',
  },
  {
    name: 'Kamilla utca',
    category: 'a',
  },
  {
    name: 'Kandó Kálmán tér',
    category: 'a',
  },
  {
    name: 'Kántor utca',
    category: 'a',
  },
  {
    name: 'Kapás utca',
    category: 'a',
  },
  {
    name: 'Kapisztrán Szent János utca',
    category: 'a',
  },
  {
    name: 'Kápolna utca',
    category: 'a',
  },
  {
    name: 'Kárász utca',
    category: 'a',
  },
  {
    name: 'Karczag László utca',
    category: 'a',
  },
  {
    name: 'Kard utca',
    category: 'a',
  },
  {
    name: 'Károly Róbert utca',
    category: 'a',
  },
  {
    name: 'Kassai utca',
    category: 'a',
  },
  {
    name: 'Kasza utca',
    category: 'a',
  },
  {
    name: 'Kazán utca',
    category: 'a',
  },
  {
    name: 'Kazinczy utca',
    category: 'a',
  },
  {
    name: 'Kecskeméti utca',
    category: 'a',
  },
  {
    name: 'Kellner Gyula utca',
    category: 'a',
  },
  {
    name: 'Kender utca',
    category: 'a',
  },
  {
    name: 'Kerék utca',
    category: 'a',
  },
  {
    name: 'Kert utca',
    category: 'a',
  },
  {
    name: 'Kígyó utca',
    category: 'a',
  },
  {
    name: 'Kisfaludy utca',
    category: 'a',
  },
  {
    name: 'Kisgyep utca',
    category: 'a',
  },
  {
    name: 'Kiss Ferenc utca',
    category: 'a',
  },
  {
    name: 'Klapka utca',
    category: 'a',
  },
  {
    name: 'Kolozsvári utca',
    category: 'a',
  },
  {
    name: 'Konstantin utca',
    category: 'a',
  },
  {
    name: 'Korall utca',
    category: 'a',
  },
  {
    name: 'Kossuth Lajos út',
    category: 'a',
  },
  {
    name: 'Kossuth tér',
    category: 'a',
  },
  {
    name: 'Koszorú utca',
    category: 'a',
  },
  {
    name: 'Kőműves utca',
    category: 'a',
  },
  {
    name: 'Könyv utca',
    category: 'a',
  },
  {
    name: 'Kőolaj utca',
    category: 'a',
  },
  {
    name: 'Kőrösi út',
    category: 'a',
  },
  {
    name: 'Kreutzer Balázs köz',
    category: 'a',
  },
  {
    name: 'Kubik utca',
    category: 'a',
  },
  {
    name: 'Kulcs utca',
    category: 'a',
  },
  {
    name: 'Kuruc utca',
    category: 'a',
  },
  {
    name: 'Kút utca',
    category: 'a',
  },
  {
    name: 'Kürt utca',
    category: 'a',
  },
  {
    name: 'Küry Albert utca',
    category: 'a',
  },
  {
    name: 'Lakat utca',
    category: 'a',
  },
  {
    name: 'Lengyel légió utca',
    category: 'a',
  },
  {
    name: 'Lepke utca',
    category: 'a',
  },
  {
    name: 'Levél utca',
    category: 'a',
  },
  {
    name: 'Levente utca',
    category: 'a',
  },
  {
    name: 'Liget utca',
    category: 'a',
  },
  {
    name: 'Liliom utca',
    category: 'a',
  },
  {
    name: 'Lomb utca',
    category: 'a',
  },
  {
    name: 'Lovas István utca',
    category: 'a',
  },
  {
    name: 'Lőtéri utca',
    category: 'a',
  },
  {
    name: 'Madách utca',
    category: 'a',
  },
  {
    name: 'Magyar Árpád tér',
    category: 'a',
  },
  {
    name: 'Magyar utca',
    category: 'a',
  },
  {
    name: 'Málna utca',
    category: 'a',
  },
  {
    name: 'Malom utca',
    category: 'a',
  },
  {
    name: 'Mályva utca',
    category: 'a',
  },
  {
    name: 'Margaréta utca',
    category: 'a',
  },
  {
    name: 'Mária út',
    category: 'a',
  },
  {
    name: 'Markotányos utca',
    category: 'a',
  },
  {
    name: 'Maros utca',
    category: 'a',
  },
  {
    name: 'Martinovics utca',
    category: 'a',
  },
  {
    name: 'Mártírok útja',
    category: 'a',
  },
  {
    name: 'Márvány utca',
    category: 'a',
  },
  {
    name: 'Mátyás király út',
    category: 'a',
  },
  {
    name: 'Mázsa utca',
    category: 'a',
  },
  {
    name: 'Mécsvirág utca',
    category: 'a',
  },
  {
    name: 'Medárd utca',
    category: 'a',
  },
  {
    name: 'Meder utca',
    category: 'a',
  },
  {
    name: 'Medve utca',
    category: 'a',
  },
  {
    name: 'Meggyes László utca',
    category: 'a',
  },
  {
    name: 'Méhész utca',
    category: 'a',
  },
  {
    name: 'Mester utca',
    category: 'a',
  },
  {
    name: 'Mészáros Lőrinc utca',
    category: 'a',
  },
  {
    name: 'Mező utca',
    category: 'a',
  },
  {
    name: 'Mikes utca',
    category: 'a',
  },
  {
    name: 'Mikszáth Kálmán utca',
    category: 'a',
  },
  {
    name: 'Mikszáth köz',
    category: 'a',
  },
  {
    name: 'Mindszenty József bíboros utca',
    category: 'a',
  },
  {
    name: 'Mocsár utca',
    category: 'a',
  },
  {
    name: 'Moha utca',
    category: 'a',
  },
  {
    name: 'Mókus utca',
    category: 'a',
  },
  {
    name: 'Móra Ferenc utca',
    category: 'a',
  },
  {
    name: 'Móricz Zsigmond utca',
    category: 'a',
  },
  {
    name: 'Mosonyi Mihály utca',
    category: 'a',
  },
  {
    name: 'Motor utca',
    category: 'a',
  },
  {
    name: 'Mozdony utca',
    category: 'a',
  },
  {
    name: 'Muskátli utca',
    category: 'a',
  },
  {
    name: 'Nád utca',
    category: 'a',
  },
  {
    name: 'Nagy Imre körút',
    category: 'a',
  },
  {
    name: 'Nagysándor József út',
    category: 'a',
  },
  {
    name: 'Nap utca',
    category: 'a',
  },
  {
    name: 'Nefelejcs utca',
    category: 'a',
  },
  {
    name: 'Nemes Gerzson sétány',
    category: 'a',
  },
  {
    name: 'Nyárfa utca',
    category: 'a',
  },
  {
    name: 'Nyár utca',
    category: 'a',
  },
  {
    name: 'Nyíl út',
    category: 'a',
  },
  {
    name: 'Nyírfa utca',
    category: 'a',
  },
  {
    name: 'Nyomdász utca',
    category: 'a',
  },
  {
    name: 'Nyúl utca',
    category: 'a',
  },
  {
    name: 'Obsitos utca',
    category: 'a',
  },
  {
    name: 'Olajbányász sétány',
    category: 'a',
  },
  {
    name: 'Olajbányász utca',
    category: 'a',
  },
  {
    name: 'Orbán utca',
    category: 'a',
  },
  {
    name: 'Orgona utca',
    category: 'a',
  },
  {
    name: 'Orosz György utca',
    category: 'a',
  },
  {
    name: 'Oroszlán utca',
    category: 'a',
  },
  {
    name: 'Ostor utca',
    category: 'a',
  },
  {
    name: 'Óvoda utca',
    category: 'a',
  },
  {
    name: 'Őrház utca',
    category: 'a',
  },
  {
    name: 'Ősz utca',
    category: 'a',
  },
  {
    name: 'Őz utca',
    category: 'a',
  },
  {
    name: 'Pacsirta utca',
    category: 'a',
  },
  {
    name: 'Páfrány utca',
    category: 'a',
  },
  {
    name: 'Pajzs út',
    category: 'a',
  },
  {
    name: 'Pálma utca',
    category: 'a',
  },
  {
    name: 'Panda köz',
    category: 'a',
  },
  {
    name: 'Panel utca',
    category: 'a',
  },
  {
    name: 'Párduc utca',
    category: 'a',
  },
  {
    name: 'Patak utca',
    category: 'a',
  },
  {
    name: 'Páva utca',
    category: 'a',
  },
  {
    name: 'Pázsit utca',
    category: 'a',
  },
  {
    name: 'Perc utca',
    category: 'a',
  },
  {
    name: 'Perczel Mór utca',
    category: 'a',
  },
  {
    name: 'Péter Pál utca',
    category: 'a',
  },
  {
    name: 'Petőfi Sándor utca',
    category: 'a',
  },
  {
    name: 'Pillangó utca',
    category: 'a',
  },
  {
    name: 'Pintér utca',
    category: 'a',
  },
  {
    name: 'Pipacs tér',
    category: 'a',
  },
  {
    name: 'Pipacs utca',
    category: 'a',
  },
  {
    name: 'Pityó utca',
    category: 'a',
  },
  {
    name: 'Pólya Tibor utca',
    category: 'a',
  },
  {
    name: 'Pongrác utca',
    category: 'a',
  },
  {
    name: 'Pozsonyi út',
    category: 'a',
  },
  {
    name: 'Pöltenberg tábornok utca',
    category: 'a',
  },
  {
    name: 'Prizma utca',
    category: 'a',
  },
  {
    name: 'Puskás Tivadar körút',
    category: 'a',
  },
  {
    name: 'Puszta utca',
    category: 'a',
  },
  {
    name: 'Rába utca',
    category: 'a',
  },
  {
    name: 'Rákóczi út',
    category: 'a',
  },
  {
    name: 'Raktár utca',
    category: 'a',
  },
  {
    name: 'Rák utca',
    category: 'a',
  },
  {
    name: 'Rege utca',
    category: 'a',
  },
  {
    name: 'Remete utca',
    category: 'a',
  },
  {
    name: 'Rét utca',
    category: 'a',
  },
  {
    name: 'Rezeda utca',
    category: 'a',
  },
  {
    name: 'Réz utca',
    category: 'a',
  },
  {
    name: 'Rodostó utca',
    category: 'a',
  },
  {
    name: 'Róka utca',
    category: 'a',
  },
  {
    name: 'Rozgonyi utca',
    category: 'a',
  },
  {
    name: 'Rozmaring utca',
    category: 'a',
  },
  {
    name: 'Rózsa utca',
    category: 'a',
  },
  {
    name: 'Sajtó utca',
    category: 'a',
  },
  {
    name: 'Sándor Pál utca',
    category: 'a',
  },
  {
    name: 'Sárkány utca',
    category: 'a',
  },
  {
    name: 'Sarló utca',
    category: 'a',
  },
  {
    name: 'Sashalmi út',
    category: 'a',
  },
  {
    name: 'Sás utca',
    category: 'a',
  },
  {
    name: 'Sebestyén Éva köz',
    category: 'a',
  },
  {
    name: 'Selyem utca',
    category: 'a',
  },
  {
    name: 'Seregély utca',
    category: 'a',
  },
  {
    name: 'Sín utca',
    category: 'a',
  },
  {
    name: 'Sirály utca',
    category: 'a',
  },
  {
    name: 'Sóház utca',
    category: 'a',
  },
  {
    name: 'Sólyom utca',
    category: 'a',
  },
  {
    name: 'Sorompó utca',
    category: 'a',
  },
  {
    name: 'Stadion utca',
    category: 'a',
  },
  {
    name: 'Sutu utca',
    category: 'a',
  },
  {
    name: 'Sütő utca',
    category: 'a',
  },
  {
    name: 'Szabadság tér',
    category: 'a',
  },
  {
    name: 'Szalonka utca',
    category: 'a',
  },
  {
    name: 'Szántó körút',
    category: 'a',
  },
  {
    name: 'Szapáry utca',
    category: 'a',
  },
  {
    name: 'Szarvas utca',
    category: 'a',
  },
  {
    name: 'Száva utca',
    category: 'a',
  },
  {
    name: 'Százados utca',
    category: 'a',
  },
  {
    name: 'Széchenyi István körút',
    category: 'a',
  },
  {
    name: 'Széchenyi utca',
    category: 'a',
  },
  {
    name: 'Szeder utca',
    category: 'a',
  },
  {
    name: 'Szegedi Kis István köz',
    category: 'a',
  },
  {
    name: 'Szegfű utca',
    category: 'a',
  },
  {
    name: 'Székely utca',
    category: 'a',
  },
  {
    name: 'Szél utca',
    category: 'a',
  },
  {
    name: 'Szent István király utca',
    category: 'a',
  },
  {
    name: 'Szent István tér',
    category: 'a',
  },
  {
    name: 'Szent László király utca',
    category: 'a',
  },
  {
    name: 'Szép utca',
    category: 'a',
  },
  {
    name: 'Sziget utca',
    category: 'a',
  },
  {
    name: 'Szigligeti utca',
    category: 'a',
  },
  {
    name: 'Szigony utca',
    category: 'a',
  },
  {
    name: 'Szivattyú utca',
    category: 'a',
  },
  {
    name: 'Szív utca',
    category: 'a',
  },
  {
    name: 'Szolnok Ispán körút',
    category: 'a',
  },
  {
    name: 'Szondy utca',
    category: 'a',
  },
  {
    name: 'Sztrájk utca',
    category: 'a',
  },
  {
    name: 'Tabántelep',
    category: 'a',
  },
  {
    name: 'Tabán utca',
    category: 'a',
  },
  {
    name: 'Tábor utca',
    category: 'a',
  },
  {
    name: 'Tanács utca',
    category: 'a',
  },
  {
    name: 'Táncsics Mihály utca',
    category: 'a',
  },
  {
    name: 'Tarló utca',
    category: 'a',
  },
  {
    name: 'Tavasz utca',
    category: 'a',
  },
  {
    name: 'Tavirózsa utca',
    category: 'a',
  },
  {
    name: 'Téglagyári út',
    category: 'a',
  },
  {
    name: 'Tégla utca',
    category: 'a',
  },
  {
    name: 'Telep utca',
    category: 'a',
  },
  {
    name: 'Tél utca',
    category: 'a',
  },
  {
    name: 'Temető utca',
    category: 'a',
  },
  {
    name: 'Templom út',
    category: 'a',
  },
  {
    name: 'Tenisz utca',
    category: 'a',
  },
  {
    name: 'Terv utca',
    category: 'a',
  },
  {
    name: 'Teve utca',
    category: 'a',
  },
  {
    name: 'Thököly út',
    category: 'a',
  },
  {
    name: 'Tigris utca',
    category: 'a',
  },
  {
    name: 'Tiszai hajósok tere',
    category: 'a',
  },
  {
    name: 'Tiszaligeti sétány',
    category: 'a',
  },
  {
    name: 'Tiszaparti sétány',
    category: 'a',
  },
  {
    name: 'Tisza utca',
    category: 'a',
  },
  {
    name: 'Tófenék utca',
    category: 'a',
  },
  {
    name: 'Toldi utca',
    category: 'a',
  },
  {
    name: 'Tomory Pál utca',
    category: 'a',
  },
  {
    name: 'Tompa Mihály utca',
    category: 'a',
  },
  {
    name: 'Tópart utca',
    category: 'a',
  },
  {
    name: 'Torony utca',
    category: 'a',
  },
  {
    name: 'Tószegi út 1-47. (páratlan oldal)',
    category: 'a',
  },
  {
    name: 'Tószegi út 2-18. (páros oldal)',
    category: 'a',
  },
  {
    name: 'Tószegi út 21. Megyei Kórház',
    category: 'a',
  },
  {
    name: 'Tóth Mór utca',
    category: 'a',
  },
  {
    name: 'Tó utca',
    category: 'a',
  },
  {
    name: 'Török utca',
    category: 'a',
  },
  {
    name: 'Törteli utca',
    category: 'a',
  },
  {
    name: 'Tőr utca',
    category: 'a',
  },
  {
    name: 'Traktor utca',
    category: 'a',
  },
  {
    name: 'Tulipán utca',
    category: 'a',
  },
  {
    name: 'Tutaj utca',
    category: 'a',
  },
  {
    name: 'Túzok utca',
    category: 'a',
  },
  {
    name: 'Tücsök utca',
    category: 'a',
  },
  {
    name: 'Tüske utca',
    category: 'a',
  },
  {
    name: 'Tüzér utca',
    category: 'a',
  },
  {
    name: 'Tüzikovács utca',
    category: 'a',
  },
  {
    name: 'Újszászi út',
    category: 'a',
  },
  {
    name: 'Üdülősor',
    category: 'a',
  },
  {
    name: 'Ürge utca',
    category: 'a',
  },
  {
    name: 'Üstökös utca',
    category: 'a',
  },
  {
    name: 'Üteg utca',
    category: 'a',
  },
  {
    name: 'Vadász utca',
    category: 'a',
  },
  {
    name: 'Vadvirág utca',
    category: 'a',
  },
  {
    name: 'Vágóhíd utca',
    category: 'a',
  },
  {
    name: 'Vak Bottyán utca',
    category: 'a',
  },
  {
    name: 'Varjú utca',
    category: 'a',
  },
  {
    name: 'Várkonyi István tér',
    category: 'a',
  },
  {
    name: 'Városmajor lakópark',
    category: 'a',
  },
  {
    name: 'Városmajor utca',
    category: 'a',
  },
  {
    name: 'Vásárhelyi Pál utca',
    category: 'a',
  },
  {
    name: 'Vásártér utca',
    category: 'a',
  },
  {
    name: 'Vas Gereben utca',
    category: 'a',
  },
  {
    name: 'Vas utca',
    category: 'a',
  },
  {
    name: 'Vasút utca',
    category: 'a',
  },
  {
    name: 'Vasvári Pál utca',
    category: 'a',
  },
  {
    name: 'Vécsey utca',
    category: 'a',
  },
  {
    name: 'Veder utca',
    category: 'a',
  },
  {
    name: 'Verbunkos utca',
    category: 'a',
  },
  {
    name: 'Vércse utca',
    category: 'a',
  },
  {
    name: 'Verseghy park',
    category: 'a',
  },
  {
    name: 'Verseghy út',
    category: 'a',
  },
  {
    name: 'Véső utca',
    category: 'a',
  },
  {
    name: 'Vidra utca',
    category: 'a',
  },
  {
    name: 'Vihar utca',
    category: 'a',
  },
  {
    name: 'Viola utca',
    category: 'a',
  },
  {
    name: 'Virág utca',
    category: 'a',
  },
  {
    name: 'Vitéz Szathmári József utca',
    category: 'a',
  },
  {
    name: 'Vitorla utca',
    category: 'a',
  },
  {
    name: 'Vit utca',
    category: 'a',
  },
  {
    name: 'Vízmű utca',
    category: 'a',
  },
  {
    name: 'Vonó utca',
    category: 'a',
  },
  {
    name: 'Vörösmarty utca',
    category: 'a',
  },
  {
    name: 'Wágner Gusztáv út',
    category: 'a',
  },
  {
    name: 'Zagyvahíd utca',
    category: 'a',
  },
  {
    name: 'Zagyvaparti sétány',
    category: 'a',
  },
  {
    name: 'Zápor utca',
    category: 'a',
  },
  {
    name: 'Zászló utca',
    category: 'a',
  },
  {
    name: 'Zerge utca',
    category: 'a',
  },
  {
    name: 'Zöldfa utca',
    category: 'a',
  },
  {
    name: 'Zrínyi utca',
    category: 'a',
  },
  {
    name: 'Zsálya utca',
    category: 'a',
  },
  {
    name: 'Zsindely utca',
    category: 'a',
  },
  {
    name: 'Ábránd köz',
    category: 'b',
  },
  {
    name: 'Ágnes utca',
    category: 'b',
  },
  {
    name: 'Alkony utca',
    category: 'b',
  },
  {
    name: 'Álom utca',
    category: 'b',
  },
  {
    name: 'Anna utca',
    category: 'b',
  },
  {
    name: 'Aranka utca',
    category: 'b',
  },
  {
    name: 'Árnyas utca',
    category: 'b',
  },
  {
    name: 'Bánki Donát utca',
    category: 'b',
  },
  {
    name: 'Besenyszögi út',
    category: 'b',
  },
  {
    name: 'Besenyszögi út Milléri-halastó',
    category: 'b',
  },
  {
    name: 'Blanka utca',
    category: 'b',
  },
  {
    name: 'Csend utca',
    category: 'b',
  },
  {
    name: 'Csenge utca',
    category: 'b',
  },
  {
    name: 'Csonka János utca',
    category: 'b',
  },
  {
    name: 'Debreceni utca',
    category: 'b',
  },
  {
    name: 'Déri Miksa körút',
    category: 'b',
  },
  {
    name: 'Dorottya utca',
    category: 'b',
  },
  {
    name: 'Edit utca',
    category: 'b',
  },
  {
    name: 'Emese utca',
    category: 'b',
  },
  {
    name: 'Erzsébet utca',
    category: 'b',
  },
  {
    name: 'Etelka utca',
    category: 'b',
  },
  {
    name: 'Éva utca',
    category: 'b',
  },
  {
    name: 'Felső Szandai rét',
    category: 'b',
  },
  {
    name: 'Fény utca',
    category: 'b',
  },
  {
    name: 'Fenyves utca',
    category: 'b',
  },
  {
    name: 'Gagarin utca',
    category: 'b',
  },
  {
    name: 'Galamb József utca',
    category: 'b',
  },
  {
    name: 'Gizella utca',
    category: 'b',
  },
  {
    name: 'Hajnal utca',
    category: 'b',
  },
  {
    name: 'Halsütő utca',
    category: 'b',
  },
  {
    name: 'Holt-Tiszapart út',
    category: 'b',
  },
  {
    name: 'Horgász utca',
    category: 'b',
  },
  {
    name: 'Horgony utca',
    category: 'b',
  },
  {
    name: 'Irén utca',
    category: 'b',
  },
  {
    name: 'Irinyi János utca',
    category: 'b',
  },
  {
    name: 'Jedlik Ányos utca',
    category: 'b',
  },
  {
    name: 'Jolán utca',
    category: 'b',
  },
  {
    name: 'Judit utca',
    category: 'b',
  },
  {
    name: 'Júlia utca',
    category: 'b',
  },
  {
    name: 'Kajak utca',
    category: 'b',
  },
  {
    name: 'Katalin utca',
    category: 'b',
  },
  {
    name: 'Kedves sor',
    category: 'b',
  },
  {
    name: 'Keszeg utca',
    category: 'b',
  },
  {
    name: 'Klára utca',
    category: 'b',
  },
  {
    name: 'Kombájn utca',
    category: 'b',
  },
  {
    name: 'Krisztina utca',
    category: 'b',
  },
  {
    name: 'Ladik utca',
    category: 'b',
  },
  {
    name: 'Lilla utca',
    category: 'b',
  },
  {
    name: 'Margit utca',
    category: 'b',
  },
  {
    name: 'Márta utca',
    category: 'b',
  },
  {
    name: 'Napsugár utca',
    category: 'b',
  },
  {
    name: 'Otthon utca',
    category: 'b',
  },
  {
    name: 'Paprika utca',
    category: 'b',
  },
  {
    name: 'Piroskai út',
    category: 'b',
  },
  {
    name: 'Ponty utca',
    category: 'b',
  },
  {
    name: 'Répa utca',
    category: 'b',
  },
  {
    name: 'Retek utca',
    category: 'b',
  },
  {
    name: 'Rétpart utca',
    category: 'b',
  },
  {
    name: 'Rita utca',
    category: 'b',
  },
  {
    name: 'Segura utca',
    category: 'b',
  },
  {
    name: 'Szellő utca',
    category: 'b',
  },
  {
    name: 'Szivárvány utca',
    category: 'b',
  },
  {
    name: 'Tihany utca',
    category: 'b',
  },
  {
    name: 'Tiszavirág utca',
    category: 'b',
  },
  {
    name: 'Tószegi út 49-től (páratlan oldal)',
    category: 'b',
  },
  {
    name: 'Tószegi út 20-tól (páros oldal)',
    category: 'b',
  },
  {
    name: 'Tölgy utca',
    category: 'b',
  },
  {
    name: 'Tünde utca',
    category: 'b',
  },
  {
    name: 'Vegyiművek lakótelep',
    category: 'b',
  },
  {
    name: 'Virágos utca',
    category: 'b',
  },
  {
    name: 'Vízpart krt. Pajti P. Panzió',
    category: 'b',
  },
  {
    name: 'Vízpart krt. 2-28/B',
    category: 'b',
  },
  {
    name: 'Vízpart krt. 1-27.',
    category: 'b',
  },
  {
    name: 'Aba Novák Vilmos utca',
    category: 'c',
  },
  {
    name: 'Áfonya utca',
    category: 'c',
  },
  {
    name: 'Ajándék utca',
    category: 'c',
  },
  {
    name: 'Akácfa utca',
    category: 'c',
  },
  {
    name: 'Alcsisziget utca',
    category: 'c',
  },
  {
    name: 'Alma utca',
    category: 'c',
  },
  {
    name: 'Almavirág utca',
    category: 'c',
  },
  {
    name: 'Apáczai Csere János utca',
    category: 'c',
  },
  {
    name: 'Árpa utca',
    category: 'c',
  },
  {
    name: 'Ásó utca',
    category: 'c',
  },
  {
    name: 'Babér utca',
    category: 'c',
  },
  {
    name: 'Babits Mihály utca',
    category: 'c',
  },
  {
    name: 'Bagoly utca',
    category: 'c',
  },
  {
    name: 'Bakcsó utca',
    category: 'c',
  },
  {
    name: 'Balázs Béla utca',
    category: 'c',
  },
  {
    name: 'Barack utca',
    category: 'c',
  },
  {
    name: 'Baranyó Sándor utca',
    category: 'c',
  },
  {
    name: 'Barcsay Jenő utca',
    category: 'c',
  },
  {
    name: 'Benedek Elek utca',
    category: 'c',
  },
  {
    name: 'Berek utca',
    category: 'c',
  },
  {
    name: 'Bernáth Aurél utca',
    category: 'c',
  },
  {
    name: 'Bíbic utca',
    category: 'c',
  },
  {
    name: 'Bodza utca',
    category: 'c',
  },
  {
    name: 'Boróka utca',
    category: 'c',
  },
  {
    name: 'Borostyán utca',
    category: 'c',
  },
  {
    name: 'Borsó utca',
    category: 'c',
  },
  {
    name: 'Bótos Sándor utca',
    category: 'c',
  },
  {
    name: 'Bródy Sándor utca',
    category: 'c',
  },
  {
    name: 'Búvár utca',
    category: 'c',
  },
  {
    name: 'Búza utca',
    category: 'c',
  },
  {
    name: 'Chiovini Ferenc utca',
    category: 'c',
  },
  {
    name: 'Cinke utca',
    category: 'c',
  },
  {
    name: 'Császármadár utca',
    category: 'c',
  },
  {
    name: 'Cseresznye utca',
    category: 'c',
  },
  {
    name: 'Cserfa utca',
    category: 'c',
  },
  {
    name: 'Csiga utca',
    category: 'c',
  },
  {
    name: 'Csiky Gergely utca',
    category: 'c',
  },
  {
    name: 'Csók István utca',
    category: 'c',
  },
  {
    name: 'Csónak utca',
    category: 'c',
  },
  {
    name: 'Csorda utca',
    category: 'c',
  },
  {
    name: 'Csurgó utca',
    category: 'c',
  },
  {
    name: 'Dankasirály utca',
    category: 'c',
  },
  {
    name: 'Deák Ferenc utca',
    category: 'c',
  },
  {
    name: 'Dió utca',
    category: 'c',
  },
  {
    name: 'Egres utca',
    category: 'c',
  },
  {
    name: 'Eper köz',
    category: 'c',
  },
  {
    name: 'Eper utca',
    category: 'c',
  },
  {
    name: 'Erkel Ferenc utca',
    category: 'c',
  },
  {
    name: 'Esze Tamás utca',
    category: 'c',
  },
  {
    name: 'Evező utca',
    category: 'c',
  },
  {
    name: 'Faragó utca',
    category: 'c',
  },
  {
    name: 'Fazekas Mihály utca',
    category: 'c',
  },
  {
    name: 'Fecske utca',
    category: 'c',
  },
  {
    name: 'Fekete István utca',
    category: 'c',
  },
  {
    name: 'Ferenczy Károly utca',
    category: 'c',
  },
  {
    name: 'Festő utca',
    category: 'c',
  },
  {
    name: 'Fészek utca',
    category: 'c',
  },
  {
    name: 'Forgó utca',
    category: 'c',
  },
  {
    name: 'Fürdő utca',
    category: 'c',
  },
  {
    name: 'Fürt utca',
    category: 'c',
  },
  {
    name: 'Füst Milán utca',
    category: 'c',
  },
  {
    name: 'Gácsi Mihály utca',
    category: 'c',
  },
  {
    name: 'Gátőr utca',
    category: 'c',
  },
  {
    name: 'Gereblye utca',
    category: 'c',
  },
  {
    name: 'Gesztenyés utca',
    category: 'c',
  },
  {
    name: 'Görbe utca',
    category: 'c',
  },
  {
    name: 'Gulácsi Lajos utca',
    category: 'c',
  },
  {
    name: 'Gyalog utca',
    category: 'c',
  },
  {
    name: 'Hajó utca',
    category: 'c',
  },
  {
    name: 'Hamvas utca',
    category: 'c',
  },
  {
    name: 'Hamza utca',
    category: 'c',
  },
  {
    name: 'Hangos utca',
    category: 'c',
  },
  {
    name: 'Harkály utca',
    category: 'c',
  },
  {
    name: 'Hasznos István utca',
    category: 'c',
  },
  {
    name: 'Héja utca',
    category: 'c',
  },
  {
    name: 'Heltai Jenő utca',
    category: 'c',
  },
  {
    name: 'Honvéd utca',
    category: 'c',
  },
  {
    name: 'Hordó utca',
    category: 'c',
  },
  {
    name: 'Jávorfa utca',
    category: 'c',
  },
  {
    name: 'Jégmadár utca',
    category: 'c',
  },
  {
    name: 'Juhász Gyula utca',
    category: 'c',
  },
  {
    name: 'Kaffka Margit utca',
    category: 'c',
  },
  {
    name: 'Kakukk utca',
    category: 'c',
  },
  {
    name: 'Kanalasgém utca',
    category: 'c',
  },
  {
    name: 'Karikás utca',
    category: 'c',
  },
  {
    name: 'Karinthy Frigyes út',
    category: 'c',
  },
  {
    name: 'Kassák Lajos utca',
    category: 'c',
  },
  {
    name: 'Katona József utca',
    category: 'c',
  },
  {
    name: 'Kertész út',
    category: 'c',
  },
  {
    name: 'Kikelet utca',
    category: 'c',
  },
  {
    name: 'Kilián György utca',
    category: 'c',
  },
  {
    name: 'Királyka utca',
    category: 'c',
  },
  {
    name: 'Kiss János utca',
    category: 'c',
  },
  {
    name: 'Kócsag utca',
    category: 'c',
  },
  {
    name: 'Kocsoros út',
    category: 'c',
  },
  {
    name: 'Koszta József utca',
    category: 'c',
  },
  {
    name: 'Kosztolányi Dezső utca',
    category: 'c',
  },
  {
    name: 'Kökény utca',
    category: 'c',
  },
  {
    name: 'Kölcsey Ferenc utca',
    category: 'c',
  },
  {
    name: 'Körte utca',
    category: 'c',
  },
  {
    name: 'Kővirág utca',
    category: 'c',
  },
  {
    name: 'Krúdy Gyula utca',
    category: 'c',
  },
  {
    name: 'Labda utca',
    category: 'c',
  },
  {
    name: 'Lechner Ödön utca',
    category: 'c',
  },
  {
    name: 'Lencse utca',
    category: 'c',
  },
  {
    name: 'Lotz Károly utca',
    category: 'c',
  },
  {
    name: 'Mandula utca',
    category: 'c',
  },
  {
    name: 'Mányoki Ádám utca',
    category: 'c',
  },
  {
    name: 'Mednyánszky László utca',
    category: 'c',
  },
  {
    name: 'Meggyfa utca',
    category: 'c',
  },
  {
    name: 'Ménes utca',
    category: 'c',
  },
  {
    name: 'Mészáros Lajos utca',
    category: 'c',
  },
  {
    name: 'Mészöly Géza utca',
    category: 'c',
  },
  {
    name: 'Mirtusz utca',
    category: 'c',
  },
  {
    name: 'Mogyoró utca',
    category: 'c',
  },
  {
    name: 'Molnár Ferenc utca',
    category: 'c',
  },
  {
    name: 'Munkácsy Mihály utca',
    category: 'c',
  },
  {
    name: 'Nádas utca',
    category: 'c',
  },
  {
    name: 'Nádirigó utca',
    category: 'c',
  },
  {
    name: 'Nagy Lajos utca',
    category: 'c',
  },
  {
    name: 'Nagymező mellékköz',
    category: 'c',
  },
  {
    name: 'Nagymező utca',
    category: 'c',
  },
  {
    name: 'Nyaraló utca',
    category: 'c',
  },
  {
    name: 'Odú utca',
    category: 'c',
  },
  {
    name: 'Ökörszem utca',
    category: 'c',
  },
  {
    name: 'Örkény István utca',
    category: 'c',
  },
  {
    name: 'Paál László utca',
    category: 'c',
  },
  {
    name: 'Patay Mihály utca',
    category: 'c',
  },
  {
    name: 'Patkó utca',
    category: 'c',
  },
  {
    name: 'Pázmány Péter utca',
    category: 'c',
  },
  {
    name: 'Péchy Mihály utca',
    category: 'c',
  },
  {
    name: 'Pettenkofen utca',
    category: 'c',
  },
  {
    name: 'Platán utca',
    category: 'c',
  },
  {
    name: 'Pollack Mihály utca',
    category: 'c',
  },
  {
    name: 'Poszáta utca',
    category: 'c',
  },
  {
    name: 'Radnóti Miklós utca',
    category: 'c',
  },
  {
    name: 'Réce utca',
    category: 'c',
  },
  {
    name: 'Rékassy Csaba utca',
    category: 'c',
  },
  {
    name: 'Repülőtérre Vezető út',
    category: 'c',
  },
  {
    name: 'Rétisas utca',
    category: 'c',
  },
  {
    name: 'Rezgő utca',
    category: 'c',
  },
  {
    name: 'Ribizli utca',
    category: 'c',
  },
  {
    name: 'Rozs utca',
    category: 'c',
  },
  {
    name: 'Rudnay Gyula utca',
    category: 'c',
  },
  {
    name: 'Sas utca',
    category: 'c',
  },
  {
    name: 'Simon Ferenc utca',
    category: 'c',
  },
  {
    name: 'Sportrepülőtér',
    category: 'c',
  },
  {
    name: 'Sport utca',
    category: 'c',
  },
  {
    name: 'Strand utca',
    category: 'c',
  },
  {
    name: 'Stromfeld Aurél utca',
    category: 'c',
  },
  {
    name: 'Suba utca',
    category: 'c',
  },
  {
    name: 'Szabó Lőrinc utca',
    category: 'c',
  },
  {
    name: 'Szabó Pál utca',
    category: 'c',
  },
  {
    name: 'Szandai Sándor utca',
    category: 'c',
  },
  {
    name: 'Szárcsa utca',
    category: 'c',
  },
  {
    name: 'Szarka utca',
    category: 'c',
  },
  {
    name: 'Székely Bertalan utca',
    category: 'c',
  },
  {
    name: 'Széna utca',
    category: 'c',
  },
  {
    name: 'Szerb Antal utca',
    category: 'c',
  },
  {
    name: 'Szilvás utca',
    category: 'c',
  },
  {
    name: 'Szinyei Merse Pál utca',
    category: 'c',
  },
  {
    name: 'Szlányi Lajos utca',
    category: 'c',
  },
  {
    name: 'Szőlő utca',
    category: 'c',
  },
  {
    name: 'Szőnyi István utca',
    category: 'c',
  },
  {
    name: 'Szűcs utca',
    category: 'c',
  },
  {
    name: 'Szüret utca',
    category: 'c',
  },
  {
    name: 'Szűrszabó utca',
    category: 'c',
  },
  {
    name: 'Tallér utca',
    category: 'c',
  },
  {
    name: 'Tamási Áron utca',
    category: 'c',
  },
  {
    name: 'Tapló utca',
    category: 'c',
  },
  {
    name: 'Tersánszky Józsi Jenő utca',
    category: 'c',
  },
  {
    name: 'Tinódi utca',
    category: 'c',
  },
  {
    name: 'Tóth Árpád utca',
    category: 'c',
  },
  {
    name: 'Tömörkény utca',
    category: 'c',
  },
  {
    name: 'Uszály utca',
    category: 'c',
  },
  {
    name: 'Úszó utca',
    category: 'c',
  },
  {
    name: 'Váci Mihály utca',
    category: 'c',
  },
  {
    name: 'Vadgalamb utca',
    category: 'c',
  },
  {
    name: 'Vadkacsa utca',
    category: 'c',
  },
  {
    name: 'Vadliba utca',
    category: 'c',
  },
  {
    name: 'Vadrózsa utca',
    category: 'c',
  },
  {
    name: 'Vajda János utca',
    category: 'c',
  },
  {
    name: 'Varsány utca',
    category: 'c',
  },
  {
    name: 'Vénusz utca',
    category: 'c',
  },
  {
    name: 'Venyige utca',
    category: 'c',
  },
  {
    name: 'Veréb utca',
    category: 'c',
  },
  {
    name: 'Veres Péter utca',
    category: 'c',
  },
  {
    name: 'Vitéz Tóth Lajos utca',
    category: 'c',
  },
  {
    name: 'Vizimalom utca',
    category: 'c',
  },
  {
    name: 'Vízpart krt. 30-.',
    category: 'c',
  },
  {
    name: 'Vízpart krt. 29-.',
    category: 'c',
  },
  {
    name: 'Vörösmező utca',
    category: 'c',
  },
  {
    name: 'Wittmann Viktor utca',
    category: 'c',
  },
  {
    name: 'Ybl Miklós utca',
    category: 'c',
  },
  {
    name: 'Zeller utca',
    category: 'c',
  },
  {
    name: 'Zombory Lajos utca',
    category: 'c',
  },
  {
    name: 'Ugar tanya',
    category: 'c',
  },
];

export const kecskemetData = [
  {
    name: 'Arany János utca',
    category: 'a',
  },
  {
    name: 'Bajcsy-Zsilinszky Endre körút',
    category: 'a',
  },
  {
    name: 'Bánk bán utca',
    category: 'a',
  },
  {
    name: 'Bem utca',
    category: 'a',
  },
  {
    name: 'Beniczky Ferenc utca',
    category: 'a',
  },
  {
    name: 'Búzás Mihály utca',
    category: 'a',
  },
  {
    name: 'Csányi János körút',
    category: 'a',
  },
  {
    name: 'Csongrádi utca',
    category: 'a',
  },
  {
    name: 'Deák Ferenc tér',
    category: 'a',
  },
  {
    name: 'Erkel Ferenc utca',
    category: 'a',
  },
  {
    name: 'Ferenczy utca',
    category: 'a',
  },
  {
    name: 'Festő utca',
    category: 'a',
  },
  {
    name: 'Görögtemplom utca',
    category: 'a',
  },
  {
    name: 'Horváth Döme körút',
    category: 'a',
  },
  {
    name: 'Kálvin tér',
    category: 'a',
  },
  {
    name: 'Katona József tér',
    category: 'a',
  },
  {
    name: 'Katona József utca',
    category: 'a',
  },
  {
    name: 'Kéttemplom köz',
    category: 'a',
  },
  {
    name: 'Kisfaludy utca',
    category: 'a',
  },
  {
    name: 'Koháry István körút',
    category: 'a',
  },
  {
    name: 'Korona utca',
    category: 'a',
  },
  {
    name: 'Kossuth tér',
    category: 'a',
  },
  {
    name: 'László Károly utca',
    category: 'a',
  },
  {
    name: 'Lechner Ödön utca',
    category: 'a',
  },
  {
    name: 'Lestár tér',
    category: 'a',
  },
  {
    name: 'Luther köz',
    category: 'a',
  },
  {
    name: 'Plébánia köz',
    category: 'a',
  },
  {
    name: 'Szabadság tér',
    category: 'a',
  },
  {
    name: 'Széchenyi tér',
    category: 'a',
  },
  {
    name: 'Trombita utca',
    category: 'a',
  },
  {
    name: 'Vörösmarty utca',
    category: 'a',
  },
  {
    name: 'Ádám Jenő utca',
    category: 'b',
  },
  {
    name: 'Ady Endre utca',
    category: 'b',
  },
  {
    name: 'Áfonya utca',
    category: 'b',
  },
  {
    name: 'Agancs utca',
    category: 'b',
  },
  {
    name: 'Agyag utca',
    category: 'b',
  },
  {
    name: 'Akácfa utca',
    category: 'b',
  },
  {
    name: 'Akadémia körút',
    category: 'b',
  },
  {
    name: 'Alkony utca',
    category: 'b',
  },
  {
    name: 'Alkotás utca',
    category: 'b',
  },
  {
    name: 'Alma utca (Szeleifalu)',
    category: 'b',
  },
  {
    name: 'Alpár utca',
    category: 'b',
  },
  {
    name: 'Alsószéktói utca',
    category: 'b',
  },
  {
    name: 'Andor Ilona utca',
    category: 'b',
  },
  {
    name: 'Apály utca',
    category: 'b',
  },
  {
    name: 'Apponyi Albert tér',
    category: 'b',
  },
  {
    name: 'Áprily Lajos utca',
    category: 'b',
  },
  {
    name: 'Aracsi utca',
    category: 'b',
  },
  {
    name: 'Aradi vértanúk tere',
    category: 'b',
  },
  {
    name: 'Aranybulla utca',
    category: 'b',
  },
  {
    name: 'Aranyeső utca',
    category: 'b',
  },
  {
    name: 'Aranyforint utca',
    category: 'b',
  },
  {
    name: 'Arborétum lakópark',
    category: 'b',
  },
  {
    name: 'Arborétum utca',
    category: 'b',
  },
  {
    name: 'Árok utca',
    category: 'b',
  },
  {
    name: 'Árpád körút',
    category: 'b',
  },
  {
    name: 'Attila utca',
    category: 'b',
  },
  {
    name: 'Avar utca',
    category: 'b',
  },
  {
    name: 'Babér utca',
    category: 'b',
  },
  {
    name: 'Babits Mihály utca',
    category: 'b',
  },
  {
    name: 'Bácskai utca',
    category: 'b',
  },
  {
    name: 'Badacsony utca',
    category: 'b',
  },
  {
    name: 'Bagi László utca',
    category: 'b',
  },
  {
    name: 'Bagoly utca',
    category: 'b',
  },
  {
    name: 'Bajza József utca',
    category: 'b',
  },
  {
    name: 'Bakócz Tamás utca',
    category: 'b',
  },
  {
    name: 'Bakule Márton utca',
    category: 'b',
  },
  {
    name: 'Balassi Bálint utca',
    category: 'b',
  },
  {
    name: 'Balaton utca',
    category: 'b',
  },
  {
    name: 'Balay utca',
    category: 'b',
  },
  {
    name: 'Bálint Sándor utca',
    category: 'b',
  },
  {
    name: 'Bálvány utca',
    category: 'b',
  },
  {
    name: 'Bambusz utca',
    category: 'b',
  },
  {
    name: 'Bánáti utca',
    category: 'b',
  },
  {
    name: 'Bánffy utca',
    category: 'b',
  },
  {
    name: 'Bánság utca',
    category: 'b',
  },
  {
    name: 'Barackos utca',
    category: 'b',
  },
  {
    name: 'Bárdos Lajos utca',
    category: 'b',
  },
  {
    name: 'Baross utca',
    category: 'b',
  },
  {
    name: 'Bártfa utca',
    category: 'b',
  },
  {
    name: 'Báthori utca',
    category: 'b',
  },
  {
    name: 'Batthyány utca',
    category: 'b',
  },
  {
    name: 'Beethoven utca',
    category: 'b',
  },
  {
    name: 'Béke fasor',
    category: 'b',
  },
  {
    name: 'Békéscsabai út',
    category: 'b',
  },
  {
    name: 'Benczúr Gyula utca',
    category: 'b',
  },
  {
    name: 'Benedek Elek utca',
    category: 'b',
  },
  {
    name: 'Bérc utca',
    category: 'b',
  },
  {
    name: 'Bercsényi utca',
    category: 'b',
  },
  {
    name: 'Beregszászi utca',
    category: 'b',
  },
  {
    name: 'Berek utca',
    category: 'b',
  },
  {
    name: 'Berettyó utca',
    category: 'b',
  },
  {
    name: 'Berlioz utca',
    category: 'b',
  },
  {
    name: 'Berzsenyi Dániel utca',
    category: 'b',
  },
  {
    name: 'Besenyő utca',
    category: 'b',
  },
  {
    name: 'Bethlen körút',
    category: 'b',
  },
  {
    name: 'Bezerédy utca',
    category: 'b',
  },
  {
    name: 'Bibó István utca',
    category: 'b',
  },
  {
    name: 'Bíbor utca',
    category: 'b',
  },
  {
    name: 'Bihar utca',
    category: 'b',
  },
  {
    name: 'Bika utca',
    category: 'b',
  },
  {
    name: 'Bimbó utca',
    category: 'b',
  },
  {
    name: 'Bíró Lajos utca',
    category: 'b',
  },
  {
    name: 'Bocskai utca',
    category: 'b',
  },
  {
    name: 'Bodzai utca',
    category: 'b',
  },
  {
    name: 'Boglárka utca',
    category: 'b',
  },
  {
    name: 'Bogovics köz',
    category: 'b',
  },
  {
    name: 'Bojtorján utca',
    category: 'b',
  },
  {
    name: 'Bokor utca',
    category: 'b',
  },
  {
    name: 'Bokréta utca',
    category: 'b',
  },
  {
    name: 'Boldog utca',
    category: 'b',
  },
  {
    name: 'Boldogasszony tér',
    category: 'b',
  },
  {
    name: 'Bolyai utca',
    category: 'b',
  },
  {
    name: 'Bónis utca',
    category: 'b',
  },
  {
    name: 'Borostyán utca',
    category: 'b',
  },
  {
    name: 'Borszéki utca',
    category: 'b',
  },
  {
    name: 'Borz utca',
    category: 'b',
  },
  {
    name: 'Botond utca',
    category: 'b',
  },
  {
    name: 'Bölöni György utca',
    category: 'b',
  },
  {
    name: 'Böszörményi utca',
    category: 'b',
  },
  {
    name: 'Brahms utca',
    category: 'b',
  },
  {
    name: 'Branyiszkó köz',
    category: 'b',
  },
  {
    name: 'Brassói utca',
    category: 'b',
  },
  {
    name: 'Brigadéros utca',
    category: 'b',
  },
  {
    name: 'Budai kapu',
    category: 'b',
  },
  {
    name: 'Budai út',
    category: 'b',
  },
  {
    name: 'Budai utca',
    category: 'b',
  },
  {
    name: 'Burga utca',
    category: 'b',
  },
  {
    name: 'Búzakalász utca',
    category: 'b',
  },
  {
    name: 'Búzaszentelő utca',
    category: 'b',
  },
  {
    name: 'Búzavirág utca',
    category: 'b',
  },
  {
    name: 'Buzogány utca',
    category: 'b',
  },
  {
    name: 'Bükköny utca',
    category: 'b',
  },
  {
    name: 'Cédrus utca',
    category: 'b',
  },
  {
    name: 'Cédulaház utca',
    category: 'b',
  },
  {
    name: 'Ceglédi út',
    category: 'b',
  },
  {
    name: 'Ciklámen utca',
    category: 'b',
  },
  {
    name: 'Cimbalmos utca',
    category: 'b',
  },
  {
    name: 'Corvin János utca',
    category: 'b',
  },
  {
    name: 'Corvina utca',
    category: 'b',
  },
  {
    name: 'Czollner köz',
    category: 'b',
  },
  {
    name: 'Czollner tér',
    category: 'b',
  },
  {
    name: 'Czuczor Gergely utca',
    category: 'b',
  },
  {
    name: 'Csaba utca',
    category: 'b',
  },
  {
    name: 'Csabagyöngye utca',
    category: 'b',
  },
  {
    name: 'Csabay Géza körút',
    category: 'b',
  },
  {
    name: 'Csákány utca',
    category: 'b',
  },
  {
    name: 'Csáktornyai utca',
    category: 'b',
  },
  {
    name: 'Csáky utca',
    category: 'b',
  },
  {
    name: 'Csalogány utca',
    category: 'b',
  },
  {
    name: 'Csángó utca',
    category: 'b',
  },
  {
    name: 'Csányi utca',
    category: 'b',
  },
  {
    name: 'Csap utca',
    category: 'b',
  },
  {
    name: 'Csárda utca',
    category: 'b',
  },
  {
    name: 'Csend utca',
    category: 'b',
  },
  {
    name: 'Csengery utca',
    category: 'b',
  },
  {
    name: 'Csengő utca',
    category: 'b',
  },
  {
    name: 'Cserép utca',
    category: 'b',
  },
  {
    name: 'Cserhalom utca',
    category: 'b',
  },
  {
    name: 'Cserhát utca',
    category: 'b',
  },
  {
    name: 'Cserje utca',
    category: 'b',
  },
  {
    name: 'Csikós utca',
    category: 'b',
  },
  {
    name: 'Csíksomlyói utca',
    category: 'b',
  },
  {
    name: 'Csillag utca',
    category: 'b',
  },
  {
    name: 'Csilléri utca',
    category: 'b',
  },
  {
    name: 'Csipke utca',
    category: 'b',
  },
  {
    name: 'Csóka utca',
    category: 'b',
  },
  {
    name: 'Csókás József utca',
    category: 'b',
  },
  {
    name: 'Csokonai utca',
    category: 'b',
  },
  {
    name: 'Csokor utca',
    category: 'b',
  },
  {
    name: 'Csongor utca',
    category: 'b',
  },
  {
    name: 'Csukáséri utca',
    category: 'b',
  },
  {
    name: 'Damjanich utca',
    category: 'b',
  },
  {
    name: 'Dankó utca',
    category: 'b',
  },
  {
    name: 'Dárda utca',
    category: 'b',
  },
  {
    name: 'Daróczi köz',
    category: 'b',
  },
  {
    name: 'Daru utca',
    category: 'b',
  },
  {
    name: 'Dellő utca',
    category: 'b',
  },
  {
    name: 'Déryné utca',
    category: 'b',
  },
  {
    name: 'Dévény utca',
    category: 'b',
  },
  {
    name: 'Diófa utca',
    category: 'b',
  },
  {
    name: 'Dobó István körút',
    category: 'b',
  },
  {
    name: 'Dohány utca',
    category: 'b',
  },
  {
    name: 'Dohnányi Ernő utca',
    category: 'b',
  },
  {
    name: 'Domb lakópark',
    category: 'b',
  },
  {
    name: 'Domby Lajos utca',
    category: 'b',
  },
  {
    name: 'Dózsa György út',
    category: 'b',
  },
  {
    name: 'Dömötör utca',
    category: 'b',
  },
  {
    name: 'Dr. Szobonya Zoltán utca',
    category: 'b',
  },
  {
    name: 'Dráva utca',
    category: 'b',
  },
  {
    name: 'Drégely utca',
    category: 'b',
  },
  {
    name: 'Dsida Jenő utca',
    category: 'b',
  },
  {
    name: 'Dugovics Titusz utca',
    category: 'b',
  },
  {
    name: 'Duna utca',
    category: 'b',
  },
  {
    name: 'Dunaföldvári út',
    category: 'b',
  },
  {
    name: 'Egervár utca',
    category: 'b',
  },
  {
    name: 'Egressy utca',
    category: 'b',
  },
  {
    name: 'Egyetértés utca',
    category: 'b',
  },
  {
    name: 'Énekes utca',
    category: 'b',
  },
  {
    name: 'Eötvös utca',
    category: 'b',
  },
  {
    name: 'Eper utca',
    category: 'b',
  },
  {
    name: 'Epreskert utca',
    category: 'b',
  },
  {
    name: 'Erdei Ferenc tér',
    category: 'b',
  },
  {
    name: 'Erdész utca',
    category: 'b',
  },
  {
    name: 'Erdő utca (Petőfiváros)',
    category: 'b',
  },
  {
    name: 'Erdősi Imre utca',
    category: 'b',
  },
  {
    name: 'Erzsébet körút',
    category: 'b',
  },
  {
    name: 'Erzsébet utca',
    category: 'b',
  },
  {
    name: 'Estike utca',
    category: 'b',
  },
  {
    name: 'Esze Tamás utca',
    category: 'b',
  },
  {
    name: 'Eszperantó utca',
    category: 'b',
  },
  {
    name: 'Ezüstdénár utca',
    category: 'b',
  },
  {
    name: 'Fábián köz',
    category: 'b',
  },
  {
    name: 'Fadrusz János utca',
    category: 'b',
  },
  {
    name: 'Fagyöngy utca',
    category: 'b',
  },
  {
    name: 'Fáklya utca',
    category: 'b',
  },
  {
    name: 'Faragó Béla fasor',
    category: 'b',
  },
  {
    name: 'Fazekas köz',
    category: 'b',
  },
  {
    name: 'Fazekas Mihály utca',
    category: 'b',
  },
  {
    name: 'Fecske utca',
    category: 'b',
  },
  {
    name: 'Fehér utca',
    category: 'b',
  },
  {
    name: 'Fehérvári utca',
    category: 'b',
  },
  {
    name: 'Felhő utca',
    category: 'b',
  },
  {
    name: 'Felsőkomárnok utca',
    category: 'b',
  },
  {
    name: 'Fényes Adolf utca',
    category: 'b',
  },
  {
    name: 'Fenyves utca',
    category: 'b',
  },
  {
    name: 'Ferenczy Ida utca',
    category: 'b',
  },
  {
    name: 'Ferencsik János utca',
    category: 'b',
  },
  {
    name: 'Fészek utca',
    category: 'b',
  },
  {
    name: 'Fodros utca',
    category: 'b',
  },
  {
    name: 'Fogaras utca',
    category: 'b',
  },
  {
    name: 'Folyóka sor',
    category: 'b',
  },
  {
    name: 'Folyondár utca',
    category: 'b',
  },
  {
    name: 'Forradalom utca',
    category: 'b',
  },
  {
    name: 'Forrás utca',
    category: 'b',
  },
  {
    name: 'Frangepán sétány',
    category: 'b',
  },
  {
    name: 'Frankel Leó utca',
    category: 'b',
  },
  {
    name: 'Fráter György utca',
    category: 'b',
  },
  {
    name: 'Futár utca',
    category: 'b',
  },
  {
    name: 'Fuvar utca',
    category: 'b',
  },
  {
    name: 'Fülemüle utca',
    category: 'b',
  },
  {
    name: 'Fürdő utca',
    category: 'b',
  },
  {
    name: 'Füst Milán utca',
    category: 'b',
  },
  {
    name: 'Füzes utca',
    category: 'b',
  },
  {
    name: 'Fűzfa utca',
    category: 'b',
  },
  {
    name: 'Fűzfás köz',
    category: 'b',
  },
  {
    name: 'Gábor Áron utca',
    category: 'b',
  },
  {
    name: 'Gábor Dénes utca',
    category: 'b',
  },
  {
    name: 'Galagonya utca',
    category: 'b',
  },
  {
    name: 'Galamb utca',
    category: 'b',
  },
  {
    name: 'Garam utca',
    category: 'b',
  },
  {
    name: 'Garay utca',
    category: 'b',
  },
  {
    name: 'Gárdonyi Géza utca',
    category: 'b',
  },
  {
    name: 'Gáspár András körút',
    category: 'b',
  },
  {
    name: 'Gát utca',
    category: 'b',
  },
  {
    name: 'Gázló utca',
    category: 'b',
  },
  {
    name: 'Georg Knorr utca',
    category: 'b',
  },
  {
    name: 'Gerecse utca',
    category: 'b',
  },
  {
    name: 'Gerlice utca',
    category: 'b',
  },
  {
    name: 'Gesztenyefa utca',
    category: 'b',
  },
  {
    name: 'Géza fejedelem körút',
    category: 'b',
  },
  {
    name: 'Gizella tér',
    category: 'b',
  },
  {
    name: 'Gólya utca',
    category: 'b',
  },
  {
    name: 'Gödöllő utca',
    category: 'b',
  },
  {
    name: 'Gödröstemető utca',
    category: 'b',
  },
  {
    name: 'Görbe utca',
    category: 'b',
  },
  {
    name: 'Görgey Artúr utca',
    category: 'b',
  },
  {
    name: 'Gőzhajó utca',
    category: 'b',
  },
  {
    name: 'Gőzmalom utca',
    category: 'b',
  },
  {
    name: 'Gránit utca',
    category: 'b',
  },
  {
    name: 'Gutenberg utca',
    category: 'b',
  },
  {
    name: 'Gyalog köz',
    category: 'b',
  },
  {
    name: 'Gyenes István utca',
    category: 'b',
  },
  {
    name: 'Gyenes Mihály tér',
    category: 'b',
  },
  {
    name: 'Gyergyói utca',
    category: 'b',
  },
  {
    name: 'Gyimesi utca',
    category: 'b',
  },
  {
    name: 'Gyopáros utca',
    category: 'b',
  },
  {
    name: 'Gyöngyös köz',
    category: 'b',
  },
  {
    name: 'Gyöngyvirág utca',
    category: 'b',
  },
  {
    name: 'Győzelem utca',
    category: 'b',
  },
  {
    name: 'Gyulai Pál utca',
    category: 'b',
  },
  {
    name: 'Hajagos utca',
    category: 'b',
  },
  {
    name: 'Hajdú utca',
    category: 'b',
  },
  {
    name: 'Hajnal utca',
    category: 'b',
  },
  {
    name: 'Hajó utca',
    category: 'b',
  },
  {
    name: 'Hajtás utca',
    category: 'b',
  },
  {
    name: 'Halasi út',
    category: 'b',
  },
  {
    name: 'Halász utca',
    category: 'b',
  },
  {
    name: 'Hanság utca',
    category: 'b',
  },
  {
    name: 'Hanusz István utca',
    category: 'b',
  },
  {
    name: 'Hargita utca',
    category: 'b',
  },
  {
    name: 'Harmat utca',
    category: 'b',
  },
  {
    name: 'Harsona utca',
    category: 'b',
  },
  {
    name: 'Határ utca',
    category: 'b',
  },
  {
    name: 'Hattyú utca',
    category: 'b',
  },
  {
    name: 'Hegedűs köz',
    category: 'b',
  },
  {
    name: 'Hegy utca',
    category: 'b',
  },
  {
    name: 'Herman Ottó tér',
    category: 'b',
  },
  {
    name: 'Hernád utca',
    category: 'b',
  },
  {
    name: 'Hétvezér utca',
    category: 'b',
  },
  {
    name: 'Hévíz utca',
    category: 'b',
  },
  {
    name: 'Híd utca',
    category: 'b',
  },
  {
    name: 'Hínár utca',
    category: 'b',
  },
  {
    name: 'Hitel utca',
    category: 'b',
  },
  {
    name: 'Hoffman János utca',
    category: 'b',
  },
  {
    name: 'Hold utca',
    category: 'b',
  },
  {
    name: 'Holdfény utca',
    category: 'b',
  },
  {
    name: 'Holló utca',
    category: 'b',
  },
  {
    name: 'Homok utca',
    category: 'b',
  },
  {
    name: 'Homokkő utca',
    category: 'b',
  },
  {
    name: 'Homokszem utca',
    category: 'b',
  },
  {
    name: 'Horák Jenő utca',
    category: 'b',
  },
  {
    name: 'Horgász utca',
    category: 'b',
  },
  {
    name: 'Hornyik János körút',
    category: 'b',
  },
  {
    name: 'Horog utca',
    category: 'b',
  },
  {
    name: 'Horváth Cirill tér',
    category: 'b',
  },
  {
    name: 'Hosszú utca',
    category: 'b',
  },
  {
    name: 'Hóvirág utca',
    category: 'b',
  },
  {
    name: 'Hunor utca',
    category: 'b',
  },
  {
    name: 'Hunyadi János tér',
    category: 'b',
  },
  {
    name: 'Hunyadi János utca',
    category: 'b',
  },
  {
    name: 'Huszár utca',
    category: 'b',
  },
  {
    name: 'Ibolya utca',
    category: 'b',
  },
  {
    name: 'Ifjúság útja',
    category: 'b',
  },
  {
    name: 'III. Béla körút',
    category: 'b',
  },
  {
    name: 'Illatos utca',
    category: 'b',
  },
  {
    name: 'Ilona utca',
    category: 'b',
  },
  {
    name: 'Illyés Gyula utca',
    category: 'b',
  },
  {
    name: 'Ipar utca',
    category: 'b',
  },
  {
    name: 'Ipoly utca',
    category: 'b',
  },
  {
    name: 'Irányi István utca',
    category: 'b',
  },
  {
    name: 'Irinyi utca',
    category: 'b',
  },
  {
    name: 'Isaszegi utca',
    category: 'b',
  },
  {
    name: 'Ispán utca',
    category: 'b',
  },
  {
    name: 'Iványi Grünwald Béla utca',
    category: 'b',
  },
  {
    name: 'Izzó utca',
    category: 'b',
  },
  {
    name: 'Izsáki út',
    category: 'b',
  },
  {
    name: 'Jácint utca',
    category: 'b',
  },
  {
    name: 'János vitéz utca',
    category: 'b',
  },
  {
    name: 'Janus Pannonius utca',
    category: 'b',
  },
  {
    name: 'Járdányi Pál utca',
    category: 'b',
  },
  {
    name: 'Jász utca',
    category: 'b',
  },
  {
    name: 'Jázmin utca',
    category: 'b',
  },
  {
    name: 'Jégvirág utca',
    category: 'b',
  },
  {
    name: 'Jelky András utca',
    category: 'b',
  },
  {
    name: 'Jogar utca',
    category: 'b',
  },
  {
    name: 'Jókai utca',
    category: 'b',
  },
  {
    name: 'Jósika utca',
    category: 'b',
  },
  {
    name: 'József Attila utca',
    category: 'b',
  },
  {
    name: 'Juhar utca',
    category: 'b',
  },
  {
    name: 'Juhász utca',
    category: 'b',
  },
  {
    name: 'Julianus barát utca',
    category: 'b',
  },
  {
    name: 'Kacsa köz',
    category: 'b',
  },
  {
    name: 'Kacsóh Pongrác utca',
    category: 'b',
  },
  {
    name: 'Kada Elek utca',
    category: 'b',
  },
  {
    name: 'Kaffka Margit utca',
    category: 'b',
  },
  {
    name: 'Kagyló utca',
    category: 'b',
  },
  {
    name: 'Kakas köz',
    category: 'b',
  },
  {
    name: 'Kalap utca',
    category: 'b',
  },
  {
    name: 'Kalász utca',
    category: 'b',
  },
  {
    name: 'Kalocsa utca',
    category: 'b',
  },
  {
    name: 'Kálti Márk utca',
    category: 'b',
  },
  {
    name: 'Kandó Kálmán utca',
    category: 'b',
  },
  {
    name: 'Kanizsa utca',
    category: 'b',
  },
  {
    name: 'Kanyar utca',
    category: 'b',
  },
  {
    name: 'Kapás utca',
    category: 'b',
  },
  {
    name: 'Kapisztrán János utca',
    category: 'b',
  },
  {
    name: 'Kápolna utca',
    category: 'b',
  },
  {
    name: 'Káposztás köz',
    category: 'b',
  },
  {
    name: 'Kaptár utca',
    category: 'b',
  },
  {
    name: 'Kard utca',
    category: 'b',
  },
  {
    name: 'Karinthy Frigyes utca',
    category: 'b',
  },
  {
    name: 'Károli Gáspár utca',
    category: 'b',
  },
  {
    name: 'Károly Róbert körút',
    category: 'b',
  },
  {
    name: 'Károlyi utca',
    category: 'b',
  },
  {
    name: 'Kárpát utca',
    category: 'b',
  },
  {
    name: 'Kassai utca',
    category: 'b',
  },
  {
    name: 'Kassák Lajos utca',
    category: 'b',
  },
  {
    name: 'Kasza utca',
    category: 'b',
  },
  {
    name: 'Kaszap utca',
    category: 'b',
  },
  {
    name: 'Katona József park',
    category: 'b',
  },
  {
    name: 'Kazal utca',
    category: 'b',
  },
  {
    name: 'Kazinczy utca',
    category: 'b',
  },
  {
    name: 'Kecske köz',
    category: 'b',
  },
  {
    name: 'Kecskeméti Végh Mihály tér',
    category: 'b',
  },
  {
    name: 'Kékestető utca',
    category: 'b',
  },
  {
    name: 'Kékfestő utca',
    category: 'b',
  },
  {
    name: 'Kelemen László utca',
    category: 'b',
  },
  {
    name: 'Kenderessy utca',
    category: 'b',
  },
  {
    name: 'Kerekes utca',
    category: 'b',
  },
  {
    name: 'Kereskedő utca',
    category: 'b',
  },
  {
    name: 'Kereszt utca',
    category: 'b',
  },
  {
    name: 'Kerkápoly utca',
    category: 'b',
  },
  {
    name: 'Kertész utca',
    category: 'b',
  },
  {
    name: 'Kinizsi utca',
    category: 'b',
  },
  {
    name: 'Kis Hegedűs köz',
    category: 'b',
  },
  {
    name: 'Kis utca',
    category: 'b',
  },
  {
    name: 'Kisdobos utca',
    category: 'b',
  },
  {
    name: 'Kiskert tér',
    category: 'b',
  },
  {
    name: 'Kiskőrösi út',
    category: 'b',
  },
  {
    name: 'Kiskun utca',
    category: 'b',
  },
  {
    name: 'Kiskunfélegyházai út',
    category: 'b',
  },
  {
    name: 'Kistemplom utca',
    category: 'b',
  },
  {
    name: 'Klapka utca',
    category: 'b',
  },
  {
    name: 'Klauzál Gábor tér',
    category: 'b',
  },
  {
    name: 'Klebelsberg Kuno utca',
    category: 'b',
  },
  {
    name: 'Kócsag utca',
    category: 'b',
  },
  {
    name: 'Kodály Zoltán tér',
    category: 'b',
  },
  {
    name: 'Kodolányi János utca',
    category: 'b',
  },
  {
    name: 'Kolostor utca',
    category: 'b',
  },
  {
    name: 'Kolozsvári utca',
    category: 'b',
  },
  {
    name: 'Koppány utca',
    category: 'b',
  },
  {
    name: 'Kórház utca',
    category: 'b',
  },
  {
    name: 'Korondi utca',
    category: 'b',
  },
  {
    name: 'Korsó utca',
    category: 'b',
  },
  {
    name: 'Kós Károly utca',
    category: 'b',
  },
  {
    name: 'Kossuth körút',
    category: 'b',
  },
  {
    name: 'Kosztolányi Dezső utca',
    category: 'b',
  },
  {
    name: 'Kőfaragó utca',
    category: 'b',
  },
  {
    name: 'Kőhíd utca',
    category: 'b',
  },
  {
    name: 'Kökény utca',
    category: 'b',
  },
  {
    name: 'Kölcsey utca',
    category: 'b',
  },
  {
    name: 'Könyves Kálmán körút',
    category: 'b',
  },
  {
    name: 'Kőrösi Csoma Sándor utca',
    category: 'b',
  },
  {
    name: 'Kőrösihegy',
    category: 'b',
  },
  {
    name: 'Kőszeg utca',
    category: 'b',
  },
  {
    name: 'Kővágó utca',
    category: 'b',
  },
  {
    name: 'Közép utca',
    category: 'b',
  },
  {
    name: 'Kristály tér',
    category: 'b',
  },
  {
    name: 'Kullai köz',
    category: 'b',
  },
  {
    name: 'Kupa utca',
    category: 'b',
  },
  {
    name: 'Kuruc körút',
    category: 'b',
  },
  {
    name: 'Kurucz tér',
    category: 'b',
  },
  {
    name: 'Küküllő utca',
    category: 'b',
  },
  {
    name: 'Külső-Szegedi út',
    category: 'b',
  },
  {
    name: 'Kürt utca',
    category: 'b',
  },
  {
    name: 'Kvarc utca',
    category: 'b',
  },
  {
    name: 'Lajta utca',
    category: 'b',
  },
  {
    name: 'Lajtha László utca',
    category: 'b',
  },
  {
    name: 'ű',
    category: 'b',
  },
  {
    name: 'Lant utca',
    category: 'b',
  },
  {
    name: 'Legenda utca',
    category: 'b',
  },
  {
    name: 'Lehár Ferenc utca',
    category: 'b',
  },
  {
    name: 'Lehel utca',
    category: 'b',
  },
  {
    name: 'Levendula utca',
    category: 'b',
  },
  {
    name: 'Liget utca',
    category: 'b',
  },
  {
    name: 'Likőrgyári út',
    category: 'b',
  },
  {
    name: 'Liliom utca',
    category: 'b',
  },
  {
    name: 'Liszt Ferenc utca',
    category: 'b',
  },
  {
    name: 'Lorántffy Zsuzsanna utca',
    category: 'b',
  },
  {
    name: 'Losonczy utca',
    category: 'b',
  },
  {
    name: 'Lovag utca',
    category: 'b',
  },
  {
    name: 'Lóverseny utca',
    category: 'b',
  },
  {
    name: 'Lőcsei utca',
    category: 'b',
  },
  {
    name: 'Lukács György utca',
    category: 'b',
  },
  {
    name: 'Lunkányi János utca',
    category: 'b',
  },
  {
    name: 'Madách Imre utca',
    category: 'b',
  },
  {
    name: 'Madár utca',
    category: 'b',
  },
  {
    name: 'Magtár utca',
    category: 'b',
  },
  {
    name: 'Magyar utca',
    category: 'b',
  },
  {
    name: 'Majális utca',
    category: 'b',
  },
  {
    name: 'Malom köz',
    category: 'b',
  },
  {
    name: 'Márai Sándor utca',
    category: 'b',
  },
  {
    name: 'Március 15. utca',
    category: 'b',
  },
  {
    name: 'Margaréta utca',
    category: 'b',
  },
  {
    name: 'Margit utca',
    category: 'b',
  },
  {
    name: 'Mária körút',
    category: 'b',
  },
  {
    name: 'Mária köz',
    category: 'b',
  },
  {
    name: 'Mária utca',
    category: 'b',
  },
  {
    name: 'Márkus Géza utca',
    category: 'b',
  },
  {
    name: 'Maros utca',
    category: 'b',
  },
  {
    name: 'Martinovics utca',
    category: 'b',
  },
  {
    name: 'Mártírok útja',
    category: 'b',
  },
  {
    name: 'Márton Áron utca',
    category: 'b',
  },
  {
    name: 'Martos Flóra utca',
    category: 'b',
  },
  {
    name: 'Márvány utca',
    category: 'b',
  },
  {
    name: 'Mathiász János utca',
    category: 'b',
  },
  {
    name: 'Mátis Kálmán utca',
    category: 'b',
  },
  {
    name: 'Matkói út',
    category: 'b',
  },
  {
    name: 'Mátra utca',
    category: 'b',
  },
  {
    name: 'Mátyás király körút',
    category: 'b',
  },
  {
    name: 'Mátyási utca',
    category: 'b',
  },
  {
    name: 'Mécses utca',
    category: 'b',
  },
  {
    name: 'Mécsvirág utca',
    category: 'b',
  },
  {
    name: 'Meggy utca',
    category: 'b',
  },
  {
    name: 'Melinda utca',
    category: 'b',
  },
  {
    name: 'Mérleg utca',
    category: 'b',
  },
  {
    name: 'Mester utca',
    category: 'b',
  },
  {
    name: 'Mészáros Lázár tér',
    category: 'b',
  },
  {
    name: 'Mészöly Gyula tér',
    category: 'b',
  },
  {
    name: 'Mészöly Gyula út',
    category: 'b',
  },
  {
    name: 'Mezei utca',
    category: 'b',
  },
  {
    name: 'Mezőszél sor',
    category: 'b',
  },
  {
    name: 'Mihó utca',
    category: 'b',
  },
  {
    name: 'Mikes utca',
    category: 'b',
  },
  {
    name: 'Mikszáth Kálmán körút',
    category: 'b',
  },
  {
    name: 'Mindszenti körút',
    category: 'b',
  },
  {
    name: 'Moha utca',
    category: 'b',
  },
  {
    name: 'Momofuku utca',
    category: 'b',
  },
  {
    name: 'Monostori út',
    category: 'b',
  },
  {
    name: 'Monostori utca',
    category: 'b',
  },
  {
    name: 'Móra Ferenc utca',
    category: 'b',
  },
  {
    name: 'Mosoly utca',
    category: 'b',
  },
  {
    name: 'Mozart utca',
    category: 'b',
  },
  {
    name: 'Mozsár utca',
    category: 'b',
  },
  {
    name: 'Munkácsy utca',
    category: 'b',
  },
  {
    name: 'Muraközy János utca',
    category: 'b',
  },
  {
    name: 'Murányi utca',
    category: 'b',
  },
  {
    name: 'Muskotály utca',
    category: 'b',
  },
  {
    name: 'Műkerti sétány',
    category: 'b',
  },
  {
    name: 'Művész utca',
    category: 'b',
  },
  {
    name: 'Nádas utca',
    category: 'b',
  },
  {
    name: 'Nádasdy Kálmán utca (helyesírás; y betű)',
    category: 'b',
  },
  {
    name: 'Nádor utca',
    category: 'b',
  },
  {
    name: 'Nagy Lajos király körút',
    category: 'b',
  },
  {
    name: 'Nagy László utca',
    category: 'b',
  },
  {
    name: 'Nagyenyed utca',
    category: 'b',
  },
  {
    name: 'Nagykőrösi utca',
    category: 'b',
  },
  {
    name: 'Nagyváradi utca',
    category: 'b',
  },
  {
    name: 'Nándorfehérvári utca',
    category: 'b',
  },
  {
    name: 'Nap utca',
    category: 'b',
  },
  {
    name: 'Napraforgó utca',
    category: 'b',
  },
  {
    name: 'Napsugár utca',
    category: 'b',
  },
  {
    name: 'Nefelejcs utca',
    category: 'b',
  },
  {
    name: 'Nekcsei Demeter utca',
    category: 'b',
  },
  {
    name: 'Nemesszeghy Márta utca',
    category: 'b',
  },
  {
    name: 'Nomád utca',
    category: 'b',
  },
  {
    name: 'Noszlopy Gáspár park',
    category: 'b',
  },
  {
    name: 'Nyár utca',
    category: 'b',
  },
  {
    name: 'Nyereg utca',
    category: 'b',
  },
  {
    name: 'Nyíl utca',
    category: 'b',
  },
  {
    name: 'Nyíri út',
    category: 'b',
  },
  {
    name: 'Nyírő József köz',
    category: 'b',
  },
  {
    name: 'Nyitra utca',
    category: 'b',
  },
  {
    name: 'Nyomda utca',
    category: 'b',
  },
  {
    name: 'Nyugati körút',
    category: 'b',
  },
  {
    name: 'Obsitos utca',
    category: 'b',
  },
  {
    name: 'Ojtozi utca',
    category: 'b',
  },
  {
    name: 'Olimpia utca',
    category: 'b',
  },
  {
    name: 'Olt utca',
    category: 'b',
  },
  {
    name: 'Orgona utca',
    category: 'b',
  },
  {
    name: 'Ótemető utca',
    category: 'b',
  },
  {
    name: 'Öböl utca',
    category: 'b',
  },
  {
    name: 'Öntöző utca',
    category: 'b',
  },
  {
    name: 'Örkény István utca',
    category: 'b',
  },
  {
    name: 'Őrtorony utca',
    category: 'b',
  },
  {
    name: 'Ösvény utca',
    category: 'b',
  },
  {
    name: 'Ősz utca',
    category: 'b',
  },
  {
    name: 'Őz utca',
    category: 'b',
  },
  {
    name: 'Pablo Casals utca',
    category: 'b',
  },
  {
    name: 'Pacsirta utca',
    category: 'b',
  },
  {
    name: 'Páfrány utca',
    category: 'b',
  },
  {
    name: 'Pajzs utca',
    category: 'b',
  },
  {
    name: 'Pákozdi csata utca',
    category: 'b',
  },
  {
    name: 'Palást utca',
    category: 'b',
  },
  {
    name: 'Paletta utca',
    category: 'b',
  },
  {
    name: 'Palló Imre utca',
    category: 'b',
  },
  {
    name: 'Palota utca',
    category: 'b',
  },
  {
    name: 'Párkány utca',
    category: 'b',
  },
  {
    name: 'Parasztfőiskola',
    category: 'b',
  },
  {
    name: 'Parasztfőiskola Mester út',
    category: 'b',
  },
  {
    name: 'Pártos Gyula utca',
    category: 'b',
  },
  {
    name: 'Pásthy Károly utca',
    category: 'b',
  },
  {
    name: 'Pászthóy utca',
    category: 'b',
  },
  {
    name: 'Pásztor utca',
    category: 'b',
  },
  {
    name: 'Patak utca',
    category: 'b',
  },
  {
    name: 'Paul Lechler utca',
    category: 'b',
  },
  {
    name: 'Pázmány Péter utca',
    category: 'b',
  },
  {
    name: 'Pázsit utca',
    category: 'b',
  },
  {
    name: 'Pelikán utca',
    category: 'b',
  },
  {
    name: 'Perc utca',
    category: 'b',
  },
  {
    name: 'Perczel Mór utca',
    category: 'b',
  },
  {
    name: 'Péter utca',
    category: 'b',
  },
  {
    name: 'Petőfi Sándor utca',
    category: 'b',
  },
  {
    name: 'Petúr bán utca',
    category: 'b',
  },
  {
    name: 'Piaristák tere',
    category: 'b',
  },
  {
    name: 'Pilinszky János utca',
    category: 'b',
  },
  {
    name: 'Pillangó utca',
    category: 'b',
  },
  {
    name: 'Pipacs utca',
    category: 'b',
  },
  {
    name: 'Pipacsmező utca',
    category: 'b',
  },
  {
    name: 'Platter János utca',
    category: 'b',
  },
  {
    name: 'Platthy utca',
    category: 'b',
  },
  {
    name: 'Poprád utca',
    category: 'b',
  },
  {
    name: 'Pósa Lajos utca',
    category: 'b',
  },
  {
    name: 'Pozsonyi utca',
    category: 'b',
  },
  {
    name: 'Présház utca',
    category: 'b',
  },
  {
    name: 'Puskás Tivadar utca',
    category: 'b',
  },
  {
    name: 'Pusztaszeri utca',
    category: 'b',
  },
  {
    name: 'Püspök utca',
    category: 'b',
  },
  {
    name: 'Rába utca',
    category: 'b',
  },
  {
    name: 'Radnóti Miklós utca',
    category: 'b',
  },
  {
    name: 'Rákóczi út',
    category: 'b',
  },
  {
    name: 'Raktár utca',
    category: 'b',
  },
  {
    name: 'Rávágy tér',
    category: 'b',
  },
  {
    name: 'Reile Géza utca',
    category: 'b',
  },
  {
    name: 'Remény utca',
    category: 'b',
  },
  {
    name: 'Repkény utca',
    category: 'b',
  },
  {
    name: 'Rét utca',
    category: 'b',
  },
  {
    name: 'Révész Imre utca',
    category: 'b',
  },
  {
    name: 'Reviczky Gyula utca',
    category: 'b',
  },
  {
    name: 'Rigó utca',
    category: 'b',
  },
  {
    name: 'Róna utca',
    category: 'b',
  },
  {
    name: 'Rosta utca',
    category: 'b',
  },
  {
    name: 'Rózsabarack utca',
    category: 'b',
  },
  {
    name: 'Rózsavölgyi tér',
    category: 'b',
  },
  {
    name: 'Rozsnyói utca',
    category: 'b',
  },
  {
    name: 'Sáfrány utca',
    category: 'b',
  },
  {
    name: 'Sajó utca',
    category: 'b',
  },
  {
    name: 'Sántha György köz',
    category: 'b',
  },
  {
    name: 'Sarkantyú utca',
    category: 'b',
  },
  {
    name: 'Sárkány utca',
    category: 'b',
  },
  {
    name: 'Sarló utca',
    category: 'b',
  },
  {
    name: 'Sarolta út',
    category: 'b',
  },
  {
    name: 'Sarolta utca',
    category: 'b',
  },
  {
    name: 'Sárospatak utca',
    category: 'b',
  },
  {
    name: 'Sárvár utca',
    category: 'b',
  },
  {
    name: 'Sás utca',
    category: 'b',
  },
  {
    name: 'Segesvári utca',
    category: 'b',
  },
  {
    name: 'Selyem utca',
    category: 'b',
  },
  {
    name: 'Semmelweis utca',
    category: 'b',
  },
  {
    name: 'Serfőző utca',
    category: 'b',
  },
  {
    name: 'Serleg utca',
    category: 'b',
  },
  {
    name: 'Sétatér utca',
    category: 'b',
  },
  {
    name: 'Siklós utca',
    category: 'b',
  },
  {
    name: 'Simonyi utca',
    category: 'b',
  },
  {
    name: 'Sinkó Ervin utca',
    category: 'b',
  },
  {
    name: 'Sió utca',
    category: 'b',
  },
  {
    name: 'Smetana utca',
    category: 'b',
  },
  {
    name: 'Sólyom utca',
    category: 'b',
  },
  {
    name: 'Sosztakovics utca',
    category: 'b',
  },
  {
    name: 'Sörház utca',
    category: 'b',
  },
  {
    name: 'Sövény utca',
    category: 'b',
  },
  {
    name: 'Sport utca',
    category: 'b',
  },
  {
    name: 'Stádium utca',
    category: 'b',
  },
  {
    name: 'Strázsa utca',
    category: 'b',
  },
  {
    name: 'Sulyok utca',
    category: 'b',
  },
  {
    name: 'Szabad utca',
    category: 'b',
  },
  {
    name: 'Szabadkai utca',
    category: 'b',
  },
  {
    name: 'Szabó Kálmán utca',
    category: 'b',
  },
  {
    name: 'Szabó Levente utca',
    category: 'b',
  },
  {
    name: 'Szabó Lőrinc utca',
    category: 'b',
  },
  {
    name: 'Szalag utca',
    category: 'b',
  },
  {
    name: 'Számadó utca',
    category: 'b',
  },
  {
    name: 'Szamos utca',
    category: 'b',
  },
  {
    name: 'Szárcsa utca',
    category: 'b',
  },
  {
    name: 'Szárny utca',
    category: 'b',
  },
  {
    name: 'Szarvas utca',
    category: 'b',
  },
  {
    name: 'Száva utca',
    category: 'b',
  },
  {
    name: 'Széchenyi István sétány',
    category: 'b',
  },
  {
    name: 'Széchenyi körút',
    category: 'b',
  },
  {
    name: 'Széchy Mária utca',
    category: 'b',
  },
  {
    name: 'Szeder utca',
    category: 'b',
  },
  {
    name: 'Szegedi út',
    category: 'b',
  },
  {
    name: 'Székely Bertalan utca',
    category: 'b',
  },
  {
    name: 'Székelyfonó utca',
    category: 'b',
  },
  {
    name: 'Székelytelepi utca',
    category: 'b',
  },
  {
    name: 'Széktó utca',
    category: 'b',
  },
  {
    name: 'Szellő utca',
    category: 'b',
  },
  {
    name: 'Szélmalom utca',
    category: 'b',
  },
  {
    name: 'Szemere Bertalan utca',
    category: 'b',
  },
  {
    name: 'Széna utca',
    category: 'b',
  },
  {
    name: 'Szent Gellért utca',
    category: 'b',
  },
  {
    name: 'Szent Imre utca',
    category: 'b',
  },
  {
    name: 'Szent István körút',
    category: 'b',
  },
  {
    name: 'Szent László körút',
    category: 'b',
  },
  {
    name: 'Szent Miklós utca',
    category: 'b',
  },
  {
    name: 'Szent-Györgyi Albert utca',
    category: 'b',
  },
  {
    name: 'Szép Ilonka utca',
    category: 'b',
  },
  {
    name: 'Szép utca',
    category: 'b',
  },
  {
    name: 'Szérűskert utca',
    category: 'b',
  },
  {
    name: 'Sziget utca',
    category: 'b',
  },
  {
    name: 'Szigliget utca',
    category: 'b',
  },
  {
    name: 'Szilády Károly utca',
    category: 'b',
  },
  {
    name: 'Szilágyi Erzsébet utca',
    category: 'b',
  },
  {
    name: 'Szilfa utca',
    category: 'b',
  },
  {
    name: 'Szimferopol tér',
    category: 'b',
  },
  {
    name: 'Szinyei Merse Pál utca',
    category: 'b',
  },
  {
    name: 'Szirom utca',
    category: 'b',
  },
  {
    name: 'Szív utca',
    category: 'b',
  },
  {
    name: 'Szivárvány utca',
    category: 'b',
  },
  {
    name: 'Szívós utca',
    category: 'b',
  },
  {
    name: 'Szlemenics köz',
    category: 'b',
  },
  {
    name: 'Szlemenics utca',
    category: 'b',
  },
  {
    name: 'Szolnoki hegy tanya',
    category: 'b',
  },
  {
    name: 'Szolnoki út',
    category: 'b',
  },
  {
    name: 'Szondi utca',
    category: 'b',
  },
  {
    name: 'Szőlő utca',
    category: 'b',
  },
  {
    name: 'Szövetség tér',
    category: 'b',
  },
  {
    name: 'Szövetség utca',
    category: 'b',
  },
  {
    name: 'Sztravinszkij utca',
    category: 'b',
  },
  {
    name: 'Szultán utca',
    category: 'b',
  },
  {
    name: 'Szüret utca',
    category: 'b',
  },
  {
    name: 'Tabán utca',
    category: 'b',
  },
  {
    name: 'Talfája köz',
    category: 'b',
  },
  {
    name: 'Táltos utca',
    category: 'b',
  },
  {
    name: 'Táncsics Mihály utca',
    category: 'b',
  },
  {
    name: 'Tarsoly utca',
    category: 'b',
  },
  {
    name: 'Tatár sor',
    category: 'b',
  },
  {
    name: 'Tatay András utca',
    category: 'b',
  },
  {
    name: 'Tavasz utca',
    category: 'b',
  },
  {
    name: 'Tavaszmező utca',
    category: 'b',
  },
  {
    name: 'Tavirózsa utca',
    category: 'b',
  },
  {
    name: 'Téglagyár utca',
    category: 'b',
  },
  {
    name: 'Téglás utca',
    category: 'b',
  },
  {
    name: 'Tél utca',
    category: 'b',
  },
  {
    name: 'Teleki László utca',
    category: 'b',
  },
  {
    name: 'Teleki Pál tér',
    category: 'b',
  },
  {
    name: 'Temes tér',
    category: 'b',
  },
  {
    name: 'Temető utca',
    category: 'b',
  },
  {
    name: 'Thököly utca',
    category: 'b',
  },
  {
    name: 'Tiborc utca',
    category: 'b',
  },
  {
    name: 'Tihany utca',
    category: 'b',
  },
  {
    name: 'Tímár utca',
    category: 'b',
  },
  {
    name: 'Tinódi utca',
    category: 'b',
  },
  {
    name: 'Tisza utca',
    category: 'b',
  },
  {
    name: 'Tiszafa utca',
    category: 'b',
  },
  {
    name: 'Tiszavirág utca',
    category: 'b',
  },
  {
    name: 'Tizedes utca',
    category: 'b',
  },
  {
    name: 'Toldi utca',
    category: 'b',
  },
  {
    name: 'Tolnai utca',
    category: 'b',
  },
  {
    name: 'Tomori Pál utca',
    category: 'b',
  },
  {
    name: 'Tompa Mihály utca',
    category: 'b',
  },
  {
    name: 'Tópart utca',
    category: 'b',
  },
  {
    name: 'Tordai utca',
    category: 'b',
  },
  {
    name: 'Torockói utca',
    category: 'b',
  },
  {
    name: 'Toscanini utca',
    category: 'b',
  },
  {
    name: 'Tóth Árpád utca',
    category: 'b',
  },
  {
    name: 'Tóth László sétány',
    category: 'b',
  },
  {
    name: 'Tölgyesi utca',
    category: 'b',
  },
  {
    name: 'Tölgyfa utca',
    category: 'b',
  },
  {
    name: 'Tömörkény István utca',
    category: 'b',
  },
  {
    name: 'Török utca',
    category: 'b',
  },
  {
    name: 'Törvénykönyv utca',
    category: 'b',
  },
  {
    name: 'Trafó utca',
    category: 'b',
  },
  {
    name: 'Tusnád utca',
    category: 'b',
  },
  {
    name: 'Túzok utca',
    category: 'b',
  },
  {
    name: 'Tündérfürt utca',
    category: 'b',
  },
  {
    name: 'Tüzér utca',
    category: 'b',
  },
  {
    name: 'Tűzoltó utca',
    category: 'b',
  },
  {
    name: 'Udvarhelyi utca',
    category: 'b',
  },
  {
    name: 'Újerdő utca',
    category: 'b',
  },
  {
    name: 'Újvidék tér',
    category: 'b',
  },
  {
    name: 'Újvilág utca',
    category: 'b',
  },
  {
    name: 'Ungvári utca',
    category: 'b',
  },
  {
    name: 'Uszály utca',
    category: 'b',
  },
  {
    name: 'Uzsoki utca',
    category: 'b',
  },
  {
    name: 'Váczi János utca',
    category: 'b',
  },
  {
    name: 'Vacsi köz',
    category: 'b',
  },
  {
    name: 'Vacsi út',
    category: 'b',
  },
  {
    name: 'Vacsi utca',
    category: 'b',
  },
  {
    name: 'Vadrózsa utca',
    category: 'b',
  },
  {
    name: 'Vadvíz utca',
    category: 'b',
  },
  {
    name: 'Vágó utca',
    category: 'b',
  },
  {
    name: 'Vajda János utca',
    category: 'b',
  },
  {
    name: 'Vajdahunyad utca',
    category: 'b',
  },
  {
    name: 'Vak Bottyán utca',
    category: 'b',
  },
  {
    name: 'Vályogvető utca',
    category: 'b',
  },
  {
    name: 'Vármegye utca',
    category: 'b',
  },
  {
    name: 'Várpalota utca',
    category: 'b',
  },
  {
    name: 'Vásárhelyi Pál utca',
    category: 'b',
  },
  {
    name: 'Vaspálya utca',
    category: 'b',
  },
  {
    name: 'Vasút utca',
    category: 'b',
  },
  {
    name: 'Vasvári Ferenc utca',
    category: 'b',
  },
  {
    name: 'Végvár utca',
    category: 'b',
  },
  {
    name: 'Venyige utca',
    category: 'b',
  },
  {
    name: 'Vereckei utca',
    category: 'b',
  },
  {
    name: 'Vértes utca',
    category: 'b',
  },
  {
    name: 'Vikár Béla utca',
    category: 'b',
  },
  {
    name: 'Világ utca',
    category: 'b',
  },
  {
    name: 'Visegrádi utca',
    category: 'b',
  },
  {
    name: 'Vitéz János utca',
    category: 'b',
  },
  {
    name: 'Vitorla utca',
    category: 'b',
  },
  {
    name: 'Vízaknai utca',
    category: 'b',
  },
  {
    name: 'Vízmű utca',
    category: 'b',
  },
  {
    name: 'Vízöntő utca',
    category: 'b',
  },
  {
    name: 'Wéber Ede utca',
    category: 'b',
  },
  {
    name: 'Weiner Leó utca',
    category: 'b',
  },
  {
    name: 'Wesselényi utca',
    category: 'b',
  },
  {
    name: 'Ybl Miklós utca',
    category: 'b',
  },
  {
    name: 'Zágoni utca',
    category: 'b',
  },
  {
    name: 'Zalán utca',
    category: 'b',
  },
  {
    name: 'Zápolya János utca',
    category: 'b',
  },
  {
    name: 'Zápor utca',
    category: 'b',
  },
  {
    name: 'Zászló utca',
    category: 'b',
  },
  {
    name: 'Zengő utca',
    category: 'b',
  },
  {
    name: 'Zentai utca',
    category: 'b',
  },
  {
    name: 'Zimay László utca',
    category: 'b',
  },
  {
    name: 'Zöldfa utca',
    category: 'b',
  },
  {
    name: 'Zöldkút utca',
    category: 'b',
  },
  {
    name: 'Zrínyi utca',
    category: 'b',
  },
  {
    name: 'Zsilip utca',
    category: 'b',
  },
  {
    name: 'Zsinór utca',
    category: 'b',
  },
  {
    name: 'Zsoldos utca',
    category: 'b',
  },
  {
    name: 'Akácvirág utca',
    category: 'c',
  },
  {
    name: 'Alsócsalános tanya',
    category: 'c',
  },
  {
    name: 'Barényi Béla út',
    category: 'c',
  },
  {
    name: 'Bazsarózsa utca',
    category: 'c',
  },
  {
    name: 'Begónia utca',
    category: 'c',
  },
  {
    name: 'Ború utca',
    category: 'c',
  },
  {
    name: 'Buborék utca',
    category: 'c',
  },
  {
    name: 'Buckás utca',
    category: 'c',
  },
  {
    name: 'Budaihegy',
    category: 'c',
  },
  {
    name: 'Budaihegy tanya',
    category: 'c',
  },
  {
    name: 'Bükkös utca',
    category: 'c',
  },
  {
    name: 'Cifra köz',
    category: 'c',
  },
  {
    name: 'Cimbora utca',
    category: 'c',
  },
  {
    name: 'Citromfű utca',
    category: 'c',
  },
  {
    name: 'Cserebogár utca',
    category: 'c',
  },
  {
    name: 'Cserjés utca',
    category: 'c',
  },
  {
    name: 'Csipkebogyó utca',
    category: 'c',
  },
  {
    name: 'Daimler út',
    category: 'c',
  },
  {
    name: 'Diófás köz',
    category: 'c',
  },
  {
    name: 'Erdő köz',
    category: 'c',
  },
  {
    name: 'Faragó utca',
    category: 'c',
  },
  {
    name: 'Fátyolka utca',
    category: 'c',
  },
  {
    name: 'Favágó köz',
    category: 'c',
  },
  {
    name: 'Felsőcsalános tanya',
    category: 'c',
  },
  {
    name: 'Felsőszéktó tanya',
    category: 'c',
  },
  {
    name: 'Galambos köz',
    category: 'c',
  },
  {
    name: 'Gallóhegy köz',
    category: 'c',
  },
  {
    name: 'Gerbera utca',
    category: 'c',
  },
  {
    name: 'Gyémánt utca',
    category: 'c',
  },
  {
    name: 'Hajnalcsillag utca',
    category: 'c',
  },
  {
    name: 'Hangulat utca (Felsőszéktó)',
    category: 'c',
  },
  {
    name: 'Háromszögi tér',
    category: 'c',
  },
  {
    name: 'Holdvilág utca',
    category: 'c',
  },
  {
    name: 'Homoktövis utca',
    category: 'c',
  },
  {
    name: 'Hópihe utca',
    category: 'c',
  },
  {
    name: 'Inda utca',
    category: 'c',
  },
  {
    name: 'Írisz utca',
    category: 'c',
  },
  {
    name: 'Kacor utca',
    category: 'c',
  },
  {
    name: 'Kadafalva tanya',
    category: 'c',
  },
  {
    name: 'Kadafalvi út',
    category: 'c',
  },
  {
    name: 'Kékköves utca',
    category: 'c',
  },
  {
    name: 'Kereplő utca',
    category: 'c',
  },
  {
    name: 'Keskeny köz',
    category: 'c',
  },
  {
    name: 'Kis Csillag utca',
    category: 'c',
  },
  {
    name: 'Kis Kullai köz',
    category: 'c',
  },
  {
    name: 'Kis Zápor utca',
    category: 'c',
  },
  {
    name: 'Kőrózsa utca',
    category: 'c',
  },
  {
    name: 'Labdarózsa utca',
    category: 'c',
  },
  {
    name: 'Ladánybenei út',
    category: 'c',
  },
  {
    name: 'Lapály utca',
    category: 'c',
  },
  {
    name: 'Lucfenyő utca',
    category: 'c',
  },
  {
    name: 'Máriahegy tanya',
    category: 'c',
  },
  {
    name: 'Máriahegyi út',
    category: 'c',
  },
  {
    name: 'Marozsi dűlő',
    category: 'c',
  },
  {
    name: 'Marozsi utca',
    category: 'c',
  },
  {
    name: 'Mercedes út',
    category: 'c',
  },
  {
    name: 'Meszes utca',
    category: 'c',
  },
  {
    name: 'Mészöly Gyula utca',
    category: 'c',
  },
  {
    name: 'Mezőfi utca',
    category: 'c',
  },
  {
    name: 'Napkelet utca',
    category: 'c',
  },
  {
    name: 'Nektár utca',
    category: 'c',
  },
  {
    name: 'Nyárfás köz',
    category: 'c',
  },
  {
    name: 'Nyírfás utca',
    category: 'c',
  },
  {
    name: 'Őszirózsa utca',
    category: 'c',
  },
  {
    name: 'Petúnia utca',
    category: 'c',
  },
  {
    name: 'Reptéri lakótelep',
    category: 'c',
  },
  {
    name: 'Reptéri út',
    category: 'c',
  },
  {
    name: 'Rezgő utca',
    category: 'c',
  },
  {
    name: 'Rigó köz',
    category: 'c',
  },
  {
    name: 'Rózsaliget utca',
    category: 'c',
  },
  {
    name: 'Sóvirág utca',
    category: 'c',
  },
  {
    name: 'Sutus sor',
    category: 'c',
  },
  {
    name: 'Szappanos utca',
    category: 'c',
  },
  {
    name: 'Szeben utca',
    category: 'c',
  },
  {
    name: 'Széles köz',
    category: 'c',
  },
  {
    name: 'Szüretelő utca',
    category: 'c',
  },
  {
    name: 'Taliga utca',
    category: 'c',
  },
  {
    name: 'Torony utca',
    category: 'c',
  },
  {
    name: 'Tuja utca',
    category: 'c',
  },
  {
    name: 'Tüzép utca',
    category: 'c',
  },
  {
    name: 'Tószegi út 44-től (páros oldal)',
    category: 'c',
  },
  {
    name: 'Úrihegy VI. utca',
    category: 'c',
  },
  {
    name: 'Vacsihegy',
    category: 'c',
  },
  {
    name: 'Veronika utca (Halasi úti kiskertek)',
    category: 'c',
  },
  {
    name: 'Veronika utca (Felsőszéktó)',
    category: 'c',
  },
  {
    name: 'Vihar utca',
    category: 'c',
  },
  {
    name: 'Virágoskert utca',
    category: 'c',
  },
  {
    name: 'Víziorgona utca',
    category: 'c',
  },
  {
    name: 'Ág utca',
    category: 'd',
  },
  {
    name: 'Akácos utca',
    category: 'd',
  },
  {
    name: 'Alma utca (Szabó S. telep)',
    category: 'd',
  },
  {
    name: 'Arató utca',
    category: 'd',
  },
  {
    name: 'Árnyas utca',
    category: 'd',
  },
  {
    name: 'Árvalányhaj utca',
    category: 'd',
  },
  {
    name: 'Ballószög tanya',
    category: 'd',
  },
  {
    name: 'Barka utca',
    category: 'd',
  },
  {
    name: 'Belsőnyír tanya',
    category: 'd',
  },
  {
    name: 'Beretvás köz',
    category: 'd',
  },
  {
    name: 'Berkenye utca',
    category: 'd',
  },
  {
    name: 'Bernát János utca',
    category: 'd',
  },
  {
    name: 'Bódog sor',
    category: 'd',
  },
  {
    name: 'Bogár utca',
    category: 'd',
  },
  {
    name: 'Borbolya utca',
    category: 'd',
  },
  {
    name: 'Boróka utca',
    category: 'd',
  },
  {
    name: 'Cinege utca',
    category: 'd',
  },
  {
    name: 'Csenkesz utca',
    category: 'd',
  },
  {
    name: 'Cserszegi utca',
    category: 'd',
  },
  {
    name: 'Csillagvirág utca',
    category: 'd',
  },
  {
    name: 'Dália utca',
    category: 'd',
  },
  {
    name: 'Darázs utca',
    category: 'd',
  },
  {
    name: 'Dobokay utca',
    category: 'd',
  },
  {
    name: 'Dongó utca',
    category: 'd',
  },
  {
    name: 'Dr. Ábrahám Béla utca',
    category: 'd',
  },
  {
    name: 'Erdő utca (Szabó S. telep)',
    category: 'd',
  },
  {
    name: 'Ezerjó utca',
    category: 'd',
  },
  {
    name: 'Ezüstfenyő utca',
    category: 'd',
  },
  {
    name: 'Fagyal utca',
    category: 'd',
  },
  {
    name: 'Favorit utca',
    category: 'd',
  },
  {
    name: 'Furmint utca',
    category: 'd',
  },
  {
    name: 'Fürj utca',
    category: 'd',
  },
  {
    name: 'Gáz utca',
    category: 'd',
  },
  {
    name: 'Gomba utca',
    category: 'd',
  },
  {
    name: 'Gyökér utca',
    category: 'd',
  },
  {
    name: 'Gyümölcs utca',
    category: 'd',
  },
  {
    name: 'Hajnalpír utca',
    category: 'd',
  },
  {
    name: 'Halom utca',
    category: 'd',
  },
  {
    name: 'Hangulat utca (Miklóstelep)',
    category: 'd',
  },
  {
    name: 'Harang utca',
    category: 'd',
  },
  {
    name: 'Harangvirág utca',
    category: 'd',
  },
  {
    name: 'Harkály utca',
    category: 'd',
  },
  {
    name: 'Harmónia utca',
    category: 'd',
  },
  {
    name: 'Hársfa sor',
    category: 'd',
  },
  {
    name: 'Határ út',
    category: 'd',
  },
  {
    name: 'Hetényegyházi út',
    category: 'd',
  },
  {
    name: 'Hortenzia utca',
    category: 'd',
  },
  {
    name: 'Hosszúnyelű utca',
    category: 'd',
  },
  {
    name: 'Irsai Olivér utca',
    category: 'd',
  },
  {
    name: 'Jegenyefa utca',
    category: 'd',
  },
  {
    name: 'Juhfark utca',
    category: 'd',
  },
  {
    name: 'Jusztin sor',
    category: 'd',
  },
  {
    name: 'Kakukkfű utca',
    category: 'd',
  },
  {
    name: 'Karát utca',
    category: 'd',
  },
  {
    name: 'Katona Zsigmond utca',
    category: 'd',
  },
  {
    name: 'Kékfrankos utca',
    category: 'd',
  },
  {
    name: 'Kéknyelű utca',
    category: 'd',
  },
  {
    name: 'Kereszt út',
    category: 'd',
  },
  {
    name: 'Kertalja utca',
    category: 'd',
  },
  {
    name: 'Kis köz',
    category: 'd',
  },
  {
    name: 'Kiserdő utca',
    category: 'd',
  },
  {
    name: 'Kocsis Pál utca',
    category: 'd',
  },
  {
    name: 'Kökörcsin utca',
    category: 'd',
  },
  {
    name: 'Kövérszőlő utca',
    category: 'd',
  },
  {
    name: 'Kunleány utca',
    category: 'd',
  },
  {
    name: 'Lepke utca',
    category: 'd',
  },
  {
    name: 'Levél utca',
    category: 'd',
  },
  {
    name: 'Ligetszépe utca',
    category: 'd',
  },
  {
    name: 'Lilaakác utca',
    category: 'd',
  },
  {
    name: 'Lombos utca',
    category: 'd',
  },
  {
    name: 'Lugas sor',
    category: 'd',
  },
  {
    name: 'Magnólia utca',
    category: 'd',
  },
  {
    name: 'Magvető utca',
    category: 'd',
  },
  {
    name: 'Málna utca',
    category: 'd',
  },
  {
    name: 'Mályva utca',
    category: 'd',
  },
  {
    name: 'Mandula utca',
    category: 'd',
  },
  {
    name: 'Miklóstelepi út',
    category: 'd',
  },
  {
    name: 'Mimóza utca',
    category: 'd',
  },
  {
    name: 'Mogyoró utca',
    category: 'd',
  },
  {
    name: 'Mókus utca',
    category: 'd',
  },
  {
    name: 'Napfény utca',
    category: 'd',
  },
  {
    name: 'Nárcisz utca',
    category: 'd',
  },
  {
    name: 'Nebáncsvirág utca',
    category: 'd',
  },
  {
    name: 'Nyírfa utca',
    category: 'd',
  },
  {
    name: 'Oltvány utca',
    category: 'd',
  },
  {
    name: 'Oportó utca',
    category: 'd',
  },
  {
    name: 'Orchidea utca',
    category: 'd',
  },
  {
    name: 'Óvoda utca',
    category: 'd',
  },
  {
    name: 'Őszapó utca',
    category: 'd',
  },
  {
    name: 'Páva utca',
    category: 'd',
  },
  {
    name: 'Pécsvárad utca',
    category: 'd',
  },
  {
    name: 'Pitypang utca',
    category: 'd',
  },
  {
    name: 'Platán utca',
    category: 'd',
  },
  {
    name: 'Rezeda utca',
    category: 'd',
  },
  {
    name: 'Rozmaring utca',
    category: 'd',
  },
  {
    name: 'Rózsa utca',
    category: 'd',
  },
  {
    name: 'Rügy utca',
    category: 'd',
  },
  {
    name: 'Sárgarózsa utca',
    category: 'd',
  },
  {
    name: 'Sirály utca',
    category: 'd',
  },
  {
    name: 'Sóhordó út',
    category: 'd',
  },
  {
    name: 'Sugár utca',
    category: 'd',
  },
  {
    name: 'Szabó Sándor telep',
    category: 'd',
  },
  {
    name: 'Szajkó utca',
    category: 'd',
  },
  {
    name: 'Szarka utca',
    category: 'd',
  },
  {
    name: 'Szarkaláb utca',
    category: 'd',
  },
  {
    name: 'Szitakötő utca',
    category: 'd',
  },
  {
    name: 'Szöcske utca',
    category: 'd',
  },
  {
    name: 'Szőlőskert utca',
    category: 'd',
  },
  {
    name: 'Szőlőszem utca',
    category: 'd',
  },
  {
    name: 'Szürkebarát utca',
    category: 'd',
  },
  {
    name: 'Taál Bálint utca',
    category: 'd',
  },
  {
    name: 'Tábor utca',
    category: 'd',
  },
  {
    name: 'Talfája tanya',
    category: 'd',
  },
  {
    name: 'Tátika utca',
    category: 'd',
  },
  {
    name: 'Törökfái tanya',
    category: 'd',
  },
  {
    name: 'Tövis utca',
    category: 'd',
  },
  {
    name: 'Tücsök utca',
    category: 'd',
  },
  {
    name: 'Tündérrózsa utca',
    category: 'd',
  },
  {
    name: 'Tüske utca',
    category: 'd',
  },
  {
    name: 'Úrihegy',
    category: 'd',
  },
  {
    name: 'Úrihegy tanya',
    category: 'd',
  },
  {
    name: 'Úrrét tanya',
    category: 'd',
  },
  {
    name: 'Vadalma utca',
    category: 'd',
  },
  {
    name: 'Vadász utca',
    category: 'd',
  },
  {
    name: 'Vadkörte utca',
    category: 'd',
  },
  {
    name: 'Vadszőlő utca',
    category: 'd',
  },
  {
    name: 'Vándor utca',
    category: 'd',
  },
  {
    name: 'Veres Péter utca',
    category: 'd',
  },
  {
    name: 'Világos utca',
    category: 'd',
  },
  {
    name: 'Viola utca',
    category: 'd',
  },
  {
    name: 'Virágos utca',
    category: 'd',
  },
  {
    name: 'Virágvölgy utca',
    category: 'd',
  },
  {
    name: 'Víztorony utca',
    category: 'd',
  },
  {
    name: 'Vörösbegy utca',
    category: 'd',
  },
  {
    name: 'Zöldike utca',
    category: 'd',
  },
  {
    name: 'Zsálya utca',
    category: 'd',
  },
  {
    name: 'Zsigmond Ferenc utca',
    category: 'd',
  },
  {
    name: 'Ajtony utca',
    category: 'e',
  },
  {
    name: 'Alkotmány utca',
    category: 'e',
  },
  {
    name: 'Almafa utca',
    category: 'e',
  },
  {
    name: 'Almavirág utca',
    category: 'e',
  },
  {
    name: 'Alsóúrrét út',
    category: 'e',
  },
  {
    name: 'Aranyhomok utca',
    category: 'e',
  },
  {
    name: 'Árvácska utca',
    category: 'e',
  },
  {
    name: 'Barátság utca',
    category: 'e',
  },
  {
    name: 'Bársonyka utca',
    category: 'e',
  },
  {
    name: 'Béke utca',
    category: 'e',
  },
  {
    name: 'Borsányi József utca',
    category: 'e',
  },
  {
    name: 'Bölcsőde utca',
    category: 'e',
  },
  {
    name: 'Csillagfény utca',
    category: 'e',
  },
  {
    name: 'Darányi Ignác utca',
    category: 'e',
  },
  {
    name: 'Darida József utca',
    category: 'e',
  },
  {
    name: 'Erdőföld utca',
    category: 'e',
  },
  {
    name: 'Fácán utca',
    category: 'e',
  },
  {
    name: 'Fehér Akác utca',
    category: 'e',
  },
  {
    name: 'Felber István utca',
    category: 'e',
  },
  {
    name: 'Fény utca',
    category: 'e',
  },
  {
    name: 'Fenyő utca',
    category: 'e',
  },
  {
    name: 'Fő utca',
    category: 'e',
  },
  {
    name: 'Frézia utca',
    category: 'e',
  },
  {
    name: 'Garai Zoltán utca',
    category: 'e',
  },
  {
    name: 'Gyöngy utca',
    category: 'e',
  },
  {
    name: 'Gyöngyház utca',
    category: 'e',
  },
  {
    name: 'Hársfa utca',
    category: 'e',
  },
  {
    name: 'Határ dűlő',
    category: 'e',
  },
  {
    name: 'Héjjas út',
    category: 'e',
  },
  {
    name: 'Helikon utca',
    category: 'e',
  },
  {
    name: 'Hetény vezér utca',
    category: 'e',
  },
  {
    name: 'Honfoglalás tér',
    category: 'e',
  },
  {
    name: 'Honvéd utca',
    category: 'e',
  },
  {
    name: 'Hullám utca',
    category: 'e',
  },
  {
    name: 'Huszka Lajos utca',
    category: 'e',
  },
  {
    name: 'Hűség utca',
    category: 'e',
  },
  {
    name: 'III. Körzet tanya',
    category: 'e',
  },
  {
    name: 'Iskola utca',
    category: 'e',
  },
  {
    name: 'István utca',
    category: 'e',
  },
  {
    name: 'Kadarka utca',
    category: 'e',
  },
  {
    name: 'Kamilla utca',
    category: 'e',
  },
  {
    name: 'Kavics utca',
    category: 'e',
  },
  {
    name: 'Kecskeméti út',
    category: 'e',
  },
  {
    name: 'Kikelet utca',
    category: 'e',
  },
  {
    name: 'Kisfái tanya',
    category: 'e',
  },
  {
    name: 'Kisnyíri utca',
    category: 'e',
  },
  {
    name: 'Korhánközi út',
    category: 'e',
  },
  {
    name: 'Kossuth Lajos utca',
    category: 'e',
  },
  {
    name: 'Kőris utca',
    category: 'e',
  },
  {
    name: 'Körtefa utca',
    category: 'e',
  },
  {
    name: 'Külsőnyír tanya',
    category: 'e',
  },
  {
    name: 'Lerakó út',
    category: 'e',
  },
  {
    name: 'Matkó tanya',
    category: 'e',
  },
  {
    name: 'Meggyfa utca',
    category: 'e',
  },
  {
    name: 'Méhész utca',
    category: 'e',
  },
  {
    name: 'Méntelek tanya',
    category: 'e',
  },
  {
    name: 'Miklós Gyula utca',
    category: 'e',
  },
  {
    name: 'Móricz Zsigmond utca',
    category: 'e',
  },
  {
    name: 'Muskátli utca',
    category: 'e',
  },
  {
    name: 'Napraforgó sétány',
    category: 'e',
  },
  {
    name: 'Németh László utca',
    category: 'e',
  },
  {
    name: 'Népdal utca',
    category: 'e',
  },
  {
    name: 'Nyárfa utca',
    category: 'e',
  },
  {
    name: 'Őszibarack utca',
    category: 'e',
  },
  {
    name: 'Pajtás utca',
    category: 'e',
  },
  {
    name: 'Pálma utca',
    category: 'e',
  },
  {
    name: 'Pápai Vincéné utca',
    category: 'e',
  },
  {
    name: 'Paprika utca',
    category: 'e',
  },
  {
    name: 'Petrovics István utca',
    category: 'e',
  },
  {
    name: 'Piros Arany utca',
    category: 'e',
  },
  {
    name: 'Posta utca',
    category: 'e',
  },
  {
    name: 'Putnoki János utca',
    category: 'e',
  },
  {
    name: 'Rózsabokor utca',
    category: 'e',
  },
  {
    name: 'Rózsafa utca',
    category: 'e',
  },
  {
    name: 'Sellő utca',
    category: 'e',
  },
  {
    name: 'Szamóca utca',
    category: 'e',
  },
  {
    name: 'Százszorszép utca',
    category: 'e',
  },
  {
    name: 'Szegfű utca',
    category: 'e',
  },
  {
    name: 'Szigeti köz',
    category: 'e',
  },
  {
    name: 'Szőlőfürt utca',
    category: 'e',
  },
  {
    name: 'Szülőföld utca',
    category: 'e',
  },
  {
    name: 'Tamási Áron utca',
    category: 'e',
  },
  {
    name: 'Tátra utca',
    category: 'e',
  },
  {
    name: 'Temető sor',
    category: 'e',
  },
  {
    name: 'Tulipán utca',
    category: 'e',
  },
  {
    name: 'Vadaskert utca',
    category: 'e',
  },
  {
    name: 'Vásár utca',
    category: 'e',
  },
  {
    name: 'Verőfény utca',
    category: 'e',
  },
  {
    name: 'Vezér utca',
    category: 'e',
  },
  {
    name: 'Völgy utca',
    category: 'e',
  },
  {
    name: 'Zalagyöngye utca',
    category: 'e',
  },
  {
    name: 'Ady Endre utca (Helvécia)',
    category: 'e',
  },
  {
    name: 'Alma utca (Helvécia)',
    category: 'e',
  },
  {
    name: 'Alsótelepi dűlő (Helvécia)',
    category: 'e',
  },
  {
    name: 'Aranyeső utca (Helvécia)',
    category: 'e',
  },
  {
    name: 'Aranykor utca (Helvécia)',
    category: 'e',
  },
  {
    name: 'Áruház utca (Helvécia)',
    category: 'e',
  },
  {
    name: 'Barackvirág utca (Helvécia)',
    category: 'e',
  },
  {
    name: 'Bem utca (Helvécia)',
    category: 'e',
  },
  {
    name: 'Berek utca (Helvécia)',
    category: 'e',
  },
  {
    name: 'Csajági sor (Helvécia)',
    category: 'e',
  },
  {
    name: 'Csíki utca (Helvécia)',
    category: 'e',
  },
  {
    name: 'Dr. Ábrahám Béla u. (Helvécia)',
    category: 'e',
  },
  {
    name: 'Erdő út (Helvécia)',
    category: 'e',
  },
  {
    name: 'Etel köz (Helvécia)',
    category: 'e',
  },
  {
    name: 'Fehértói dűlő (Helvécia)',
    category: 'e',
  },
  {
    name: 'Garai Zoltán utca (Helvécia)',
    category: 'e',
  },
  {
    name: 'Gazdasági út (Helvécia)',
    category: 'e',
  },
  {
    name: 'Gazdasági dűlő (Helvécia)',
    category: 'e',
  },
  {
    name: 'Gyopár utca  (Helvécia)',
    category: 'e',
  },
  {
    name: 'Hargita utca (Helvécia)',
    category: 'e',
  },
  {
    name: 'Háromszék utca (Helvécia)',
    category: 'e',
  },
  {
    name: 'Határ út (Helvécia)',
    category: 'e',
  },
  {
    name: 'Hetes sor (Helvécia)',
    category: 'e',
  },
  {
    name: 'Iskola sor (Helvécia)',
    category: 'e',
  },
  {
    name: 'Jubileum utca (Helvécia)',
    category: 'e',
  },
  {
    name: 'Jusztin utca (Helvécia)',
    category: 'e',
  },
  {
    name: 'Kadafalvi út (Helvécia)',
    category: 'e',
  },
  {
    name: 'Kecskeméti út (Helvécia)',
    category: 'e',
  },
  {
    name: 'Kiskőrösi út (Helvécia)',
    category: 'e',
  },
  {
    name: 'Korhánközi dűlő (Helvécia)',
    category: 'e',
  },
  {
    name: 'Korona út (Helvécia)',
    category: 'e',
  },
  {
    name: 'Küküllő utca (Helvécia)',
    category: 'e',
  },
  {
    name: 'Lövi dűlő (Helvécia)',
    category: 'e',
  },
  {
    name: 'Magyar sor (Helvécia)',
    category: 'e',
  },
  {
    name: 'Német sor (Helvécia)',
    category: 'e',
  },
  {
    name: 'Óvoda utca (Helvécia)',
    category: 'e',
  },
  {
    name: 'Ősz utca (Helvécia)',
    category: 'e',
  },
  {
    name: 'Papp Sándor tér (Helvécia)',
    category: 'e',
  },
  {
    name: 'Petőfi Sándor utca (Helvécia)',
    category: 'e',
  },
  {
    name: 'Rákóczi utca (Helvécia)',
    category: 'e',
  },
  {
    name: 'Sétatér utca (Helvécia)',
    category: 'e',
  },
  {
    name: 'Sport utca (Helvécia)',
    category: 'e',
  },
  {
    name: 'Szabó Sándor telep (Helvécia)',
    category: 'e',
  },
  {
    name: 'Széles köz (Helvécia)',
    category: 'e',
  },
  {
    name: 'Szent István út (Helvécia)',
    category: 'e',
  },
  {
    name: 'Szőlő utca (Helvécia)',
    category: 'e',
  },
  {
    name: 'Taál Bálint utca (Helvécia)',
    category: 'e',
  },
  {
    name: 'Tábor utca (Helvécia)',
    category: 'e',
  },
  {
    name: 'Tamási Áron utca (Helvécia)',
    category: 'e',
  },
  {
    name: 'Táncsics Mihály utca (Helvécia)',
    category: 'e',
  },
  {
    name: 'Tavasz utca (Helvécia)',
    category: 'e',
  },
  {
    name: 'Temető sor (Helvécia)',
    category: 'e',
  },
  {
    name: 'Templom utca (Helvécia)',
    category: 'e',
  },
  {
    name: 'Víztorony utca (Helvécia)',
    category: 'e',
  },
  {
    name: 'Wéber Ede utca (Helvécia)',
    category: 'e',
  },
  {
    name: 'Zsák utca (Helvécia)',
    category: 'e',
  },
  {
    name: 'Ady Endre utca (Városföld)',
    category: 'e',
  },
  {
    name: 'Arany János utca (Városföld)',
    category: 'e',
  },
  {
    name: 'Bartók Béla utca (Városföld)',
    category: 'e',
  },
  {
    name: 'Béke utca (Városföld)',
    category: 'e',
  },
  {
    name: 'Bem utca (Városföld)',
    category: 'e',
  },
  {
    name: 'Deák Ferenc u. (Városföld)',
    category: 'e',
  },
  {
    name: 'Dózsa György u. (Városföld)',
    category: 'e',
  },
  {
    name: 'Felszabadulás útja (Városföld)',
    category: 'e',
  },
  {
    name: 'Fő utca (Városföld)',
    category: 'e',
  },
  {
    name: 'Győzelem utca (Városföld)',
    category: 'e',
  },
  {
    name: 'Ifjúság utca (Városföld)',
    category: 'e',
  },
  {
    name: 'Jókai utca (Városföld)',
    category: 'e',
  },
  {
    name: 'Katona József u. (Városföld)',
    category: 'e',
  },
  {
    name: 'Kossuth Lajos u. (Városföld)',
    category: 'e',
  },
  {
    name: 'Virág utca (Városföld)',
    category: 'e',
  },
  {
    name: 'Szalvay Mihály utca (Városföld)',
    category: 'e',
  },
  {
    name: 'Petőfi Sándor tér (Városföld)',
    category: 'e',
  },
  {
    name: 'Petőfi Sándor utca (Városföld)',
    category: 'e',
  },
  {
    name: 'Tavasz utca (Városföld)',
    category: 'd',
  },
  {
    name: 'Nyár utca (Városföld)',
    category: 'd',
  },
  {
    name: 'Ősz utca (Városföld)',
    category: 'd',
  },
  {
    name: 'Ady utca',
    category: 'e',
  },
  {
    name: 'Aranykor út',
    category: 'e',
  },
  {
    name: 'Árpád-házi Szent Erzsébet utca',
    category: 'e',
  },
  {
    name: 'Árpád utca',
    category: 'e',
  },
  {
    name: 'Bartók utca',
    category: 'e',
  },
  {
    name: 'Csokonai köz',
    category: 'e',
  },
  {
    name: 'Csontváry utca',
    category: 'e',
  },
  {
    name: 'Deák Ferenc utca',
    category: 'e',
  },
  {
    name: 'Dózsa György utca',
    category: 'e',
  },
  {
    name: 'Ezerjó köz',
    category: 'e',
  },
  {
    name: 'Hunyadi utca',
    category: 'e',
  },
  {
    name: 'Illés Gyula utca',
    category: 'e',
  },
  {
    name: 'Kadarka köz',
    category: 'e',
  },
  {
    name: 'Kiságasi út',
    category: 'e',
  },
  {
    name: 'Kodály Zoltán utca',
    category: 'e',
  },
  {
    name: 'Korhánközi dűlő',
    category: 'e',
  },
  {
    name: 'Kossuth utca (Ballószög)',
    category: 'e',
  },
  {
    name: 'Kölcsey utca (Ballószög)',
    category: 'e',
  },
  {
    name: 'Mikszáth utca (Ballószög)',
    category: 'e',
  },
  {
    name: 'Móra Ferenc utca (Ballószög)',
    category: 'e',
  },
  {
    name: 'Móricz Zsigmond u. (Ballószög)',
    category: 'e',
  },
  {
    name: 'Munkácsy utca (Ballószög)',
    category: 'e',
  },
  {
    name: 'Petőfi utca (Ballószög)',
    category: 'e',
  },
  {
    name: 'Rákóczi út (Ballószög)',
    category: 'e',
  },
  {
    name: 'Széchenyi utca (Ballószög)',
    category: 'e',
  },
  {
    name: 'Szent László utca (Ballószög)',
    category: 'e',
  },
  {
    name: 'Szilvaliget utca (Ballószög)',
    category: 'e',
  },
  {
    name: 'Táncsics utca (Ballószög)',
    category: 'e',
  },
  {
    name: 'Temető utca (Ballószög)',
    category: 'e',
  },
  {
    name: 'Vasút utca (Ballószög)',
    category: 'e',
  },
  {
    name: 'Vörösmarty utca (Ballószög)',
    category: 'e',
  },
  {
    name: 'Wass Albert utca (Ballószög)',
    category: 'e',
  },
  {
    name: 'Wéber Aladár u. (Ballószög)',
    category: 'e',
  },
  {
    name: 'Zrínyi utca (Ballószög)',
    category: 'e',
  },
  {
    name: 'Zsíros dűlő (Ballószög)',
    category: 'e',
  },
];
