// LATEST UPDATE: 2020 June 23, 10:07pm by Andric

import * as React from 'react';

//
// SVG icons with a 16px * 16px viewbox
//

export const customIcons = {
  human: {
    path: (
      <path
        fill='currentColor'
        d='M-10.9,4.9c11.3,0,20.5,9.2,20.5,20.5S0.4,45.9-10.9,45.9s-20.5-9.2-20.5-20.5S-22.2,4.9-10.9,4.9z M14.9,51.2h-51.2
	c-14.2,0-25.6,11.4-25.6,25.6v62.6c0,4.9,3.9,9,9,9s9-3.9,9-9V81.9c0-1.4,1.2-2.6,2.6-2.6s2.6,1.2,2.6,2.6v155.2
	c0,7.7,5.7,14,12.8,14s12.8-6.3,12.8-14v-88.5c0-1.4,1.2-2.6,2.6-2.6c1.4,0,2.6,1.2,2.6,2.6v88.5c0,7.7,5.7,14,12.8,14
	c7.1,0,12.8-6.3,12.8-14V81.9c0-1.4,1.2-2.6,2.6-2.6s2.6,1.2,2.6,2.6v57.6c0,4.9,3.9,9,9,9s9-3.9,9-9V76.8
	C40.5,62.6,28.8,51.2,14.9,51.2z'
      />
    ),
    viewBox: '-69 0 117 256',
  },
  people: {
    path: (
      <g fill='currentColor' id='Man'>
        <g>
          <g>
            <circle cx='63.5' cy='9.1' r='7.6' />
            <path
              d='M73.1,18.7h-19c-5.3,0-9.5,4.2-9.5,9.5v23.2c0,1.8,1.5,3.4,3.4,3.4c1.9,0,3.4-1.5,3.4-3.4V30.1c0-0.5,0.4-0.9,0.9-0.9
				c0.5,0,0.9,0.4,0.9,0.9v57.6c0,2.8,2.1,5.2,4.7,5.2c2.6,0,4.7-2.3,4.7-5.2V54.9c0-0.5,0.4-0.9,0.9-0.9c0.5,0,0.9,0.4,0.9,0.9
				v32.9c0,2.8,2.1,5.2,4.7,5.2c2.6,0,4.7-2.3,4.7-5.2V30.1c0-0.5,0.4-0.9,0.9-0.9c0.5,0,0.9,0.4,0.9,0.9v21.4
				c0,1.8,1.5,3.4,3.4,3.4c1.9,0,3.4-1.5,3.4-3.4V28.2C82.6,22.9,78.3,18.7,73.1,18.7z'
            />
          </g>
        </g>
        <g>
          <circle cx='105.9' cy='9.1' r='7.6' />
          <path
            d='M115.4,18.7h-19c-5.3,0-9.5,4.2-9.5,9.5v23.2c0,1.8,1.5,3.4,3.4,3.4s3.4-1.5,3.4-3.4V30.1c0-0.5,0.4-0.9,0.9-0.9
			s0.9,0.4,0.9,0.9v57.6c0,2.8,2.1,5.2,4.7,5.2c2.6,0,4.7-2.3,4.7-5.2V54.9c0-0.5,0.4-0.9,0.9-0.9s0.9,0.4,0.9,0.9v32.9
			c0,2.8,2.1,5.2,4.7,5.2c2.6,0,4.7-2.3,4.7-5.2V30.1c0-0.5,0.4-0.9,0.9-0.9s0.9,0.4,0.9,0.9v21.4c0,1.8,1.5,3.4,3.4,3.4
			s3.4-1.5,3.4-3.4V28.2C124.9,22.9,120.6,18.7,115.4,18.7z'
          />
        </g>
        <g>
          <circle cx='21.2' cy='9.1' r='7.6' />
          <path
            d='M30.7,18.7h-19c-5.3,0-9.5,4.2-9.5,9.5v23.2c0,1.8,1.5,3.4,3.4,3.4c1.9,0,3.4-1.5,3.4-3.4V30.1c0-0.5,0.4-0.9,0.9-0.9
			c0.5,0,0.9,0.4,0.9,0.9v57.6c0,2.8,2.1,5.2,4.7,5.2c2.6,0,4.7-2.3,4.7-5.2V54.9c0-0.5,0.4-0.9,0.9-0.9c0.5,0,0.9,0.4,0.9,0.9v32.9
			c0,2.8,2.1,5.2,4.7,5.2s4.7-2.3,4.7-5.2V30.1c0-0.5,0.4-0.9,0.9-0.9c0.5,0,0.9,0.4,0.9,0.9v21.4c0,1.8,1.5,3.4,3.4,3.4
			c1.9,0,3.4-1.5,3.4-3.4V28.2C40.2,22.9,35.9,18.7,30.7,18.7z'
          />
        </g>
      </g>
    ),
    viewBox: '0 0 127.5 94.8',
  },
};
