import { IAddress } from '../addresses/AddressTypes';
import { ICartItem } from '../cart/CartTypes';

export type OrderDispatchType = {
  type: 'ORDERS_DOWNLOADED' | 'ORDER_STARTED_PREPARING';
  payloadOrders?: [IOrder];
  payloadStartedPreparing?: IOrder;
};

export type OrderState = {
  orders?: IOrder[];
};
export type IOrder = {
  id: string;
  place?: 'SZOLNOK' | 'KECSKEMET';
  items?: IOrderItem[];
  orderItems?: IOrderItem[];
  comment?: string;
  address?: string;
  telephoneNumber?: string;
  createdAt?: number;
  phase?: 'NEW_ORDER' | 'UNDER_PREPARATION' | 'READY_TO_SHIP' | 'DONE';
};
export type IOrderItem = {
  id: string;
  name?: string;
  item?: any;
  description?: string;
  quantity: number;
  price: number;
};

export enum IOrderShippingMethod {
  Instant = 'INSTANT',
  AtTime = 'AT_TIME',
  Pickup = 'PICKUP',
}

export type IOrderForm = {
  county: string;
  paymentType?: 'CASH' | 'CARD' | 'CARD_LOCATION' | 'OTPDIRECT';
  email: string;
  name: string;
  phone: string;
  comment: string;
  coupon: string;
  bigMoney: boolean;
  requestInvoice: boolean;
  isPrivate: boolean;
  isPrivateName: string;
  billingCompany: string;
  billingTaxNumber: string;
  billingAddress: string;
  shippingDate: moment.Moment;
  shippingMethod: IOrderShippingMethod;
  usedLoyaltyPoints: number;
};

export type OrderActionType = {
  orderStartedPreparing: (order: IOrder) => void;
  validateCoupon: (products: ICartItem[], code: string) => Promise<any>;
  fetchShippingCosts: () => Promise<any>;
  fetchPromotionPrices: () => Promise<any>;
  checkPrice: (cart: ICartItem[]) => Promise<any>;
  orderCart: (
    cartItems: ICartItem[],
    cityId: number,
    address: IAddress,
    orderForm: IOrderForm
  ) => Promise<any>;
};
