/* eslint-disable no-underscore-dangle */
import axios from 'axios';

export default function configureAxios(
  configFile: { serverBaseUrl: string | undefined },
  dispatch: (arg0: { type: string }) => void,
): void {
  axios.defaults.baseURL = configFile.serverBaseUrl;
  axios.defaults.headers.post['Content-Type'] = 'application/json';

  axios.interceptors.request.use(
    (config) => {
      const tkn = sessionStorage.getItem('token');
      if (tkn) {
        config.headers.authorization = `Bearer ${sessionStorage.getItem(
          'token',
        )}`;
      }
      return config;
    },
    (error) => {
      Promise.reject(error);
    },
  );

  axios.interceptors.response.use(
    (resp) => {
      return resp;
    },
    (error) => {
      const originalRequest = error.config;
      if (
        originalRequest.url === '/auth/refreshToken' &&
        error.response.status === 401
      ) {
        dispatch({ type: 'LOGIN_FAILURE' });
        sessionStorage.clear();
        return Promise.reject(error);
      }
      if (
        sessionStorage.getItem('refreshToken') &&
        error.response?.status === 401 &&
        !originalRequest._retry
      ) {
        originalRequest._retry = true;
        console.log('Trying to refresh the token');
        return axios
          .post('/auth/refreshToken', {
            token: sessionStorage.getItem('refreshToken'),
          })
          .then((resp) => {
            console.log('Token refresh was successful! :)');
            sessionStorage.setItem('token', resp.data.token);
            axios.defaults.headers.authorization = `Bearer ${resp.data.token}`;
            return axios(originalRequest);
          });
      }
      return Promise.reject(error);
    },
  );
}
