import { Button } from '@chakra-ui/core';
import 'pure-react-carousel/dist/react-carousel.es.css';
import React from 'react';

type Props = {
  isShown?: boolean;
  isOpen?: boolean;
  onClick: () => void;
  children?: any;
};

export const TabButton = ({ children, isShown, isOpen, onClick }: Props) => {
  return (
    <Button
      display={isShown ? 'initial' : 'none'}
      px={3}
      py={1}
      mr={2}
      size='sm'
      borderRadius='8px 8px 0 0'
      backgroundColor={isOpen ? 'white' : 'black'}
      color={isOpen ? 'black' : 'white'}
      onClick={onClick}
      _hover={{ color: '#000000', backgroundColor: '#ffffff' }}
    >
      {children}
    </Button>
  );
};
