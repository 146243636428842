/** @jsx jsx */

import { Box, Flex, Heading, Image, Text } from '@chakra-ui/core';
import { jsx } from '@emotion/core';

import Card from '../Card';

export default function NewsArticle({
  articleTitle,
  articleImage,
  articleText,
  articleDate,
}: {
  articleTitle: string;
  articleImage: string;
  articleText: string;
  articleDate: Date;
}): JSX.Element {
  return (
    <Card my={3}>
      <Flex flexDirection="row-reverse" justifyContent="flex-end">
        <Box>
          <Heading as="h2" size="xl">
            {articleTitle}
          </Heading>
          <Text ml={1} fontSize="sm" color="gray.500">
            {articleDate.toLocaleDateString()}
          </Text>
          <Text mt={3} textAlign="center">
            {articleText}
          </Text>
        </Box>
        <Box width={400} mr={8}>
          <Image src={articleImage} />
        </Box>
      </Flex>
    </Card>
  );
}
