import { Box, Grid, Heading, Icon, Text } from '@chakra-ui/core';
import React from 'react';
import { useLocation } from 'react-router-dom';

import Card from '../components/Card';
import Navbar from '../components/navbar/Navbar';
import Footer from '../components/footer/Footer';

type statusType = 'success' | 'cancelled' | 'declined';
function ConfirmationScreen(): JSX.Element {
  // Get data from URL and query parameters
  const location = useLocation();
  const status = location.pathname.split('/')[2] as statusType;

  const txId = location.search.startsWith('ref');
  return (
    <>
      <Navbar />
      <Box as='main' mt={4} color='black'>
        <Card my={3}>
          <Grid templateColumns='repeat(3, 1fr)' gap={6}>
            {status === 'success' && (
              <>
                <Icon name='check-circle' color='green.500' size='96px' />
                <div>
                  <Heading as='h1' size='xl'>
                    {txId ? 'Sikeres tranzakció.' : 'Sikeres vásárlás.'}
                  </Heading>
                  <Heading as='h1' size='lg'>
                    {txId
                      ? `Tranzakció azonosító: ${txId}`
                      : 'Megrendelése részleteit a megadott email címre elküldtük.'}
                  </Heading>
                </div>
              </>
            )}
            {status === 'declined' && (
              <>
                <Icon name='warning' color='rgb(218, 21, 21)' size='96px' />
                <div>
                  <Heading as='h1' size='xl'>
                    Sikertelen tranzakció.
                  </Heading>
                  <Heading as='h1' size='lg'>
                    SimplePay tranzakció azonosító: {txId}
                  </Heading>
                  <Text size='md'>
                    Kérjük, ellenőrizze a tranzakció során megadott adatok
                    helyességét. Amennyiben minden adatot helyesen adott meg, a
                    visszautasítás okának kivizsgálása kapcsán kérjük,
                    szíveskedjen kapcsolatba lépni kártyakibocsátó bankjával.
                  </Text>
                </div>
              </>
            )}
            {status === 'cancelled' && (
              <>
                <Icon name='warning' color='rgb(218, 21, 21)' size='96px' />
                <div>
                  <Heading as='h1' size='xl'>
                    Ön megszakította a fizetést
                  </Heading>
                </div>
              </>
            )}
          </Grid>
        </Card>
      </Box>
      <Footer />
    </>
  );
}

export default ConfirmationScreen;
