import { BoxProps } from '@chakra-ui/core';
import { UseDisclosureReturn } from '@chakra-ui/core/dist/useDisclosure';
import { connect, ConnectedProps } from 'react-redux';

import AddressActions from '../../redux/addresses/AddressActions';
import AuthActions from '../../redux/auth/AuthActions';
import CartActions from '../../redux/cart/CartActions';
import NewsActions from '../../redux/news/NewsActions';
import ProductActions from '../../redux/products/ProductActions';
import { RootState } from '../../redux/store';
import { ReduxNavProps } from '../navbar/NavConstants';

export enum AuthWhatsOpen {
  'login',
  'forgot',
  'register',
  'about',
  'modify',
  'address',
  'addressmodify',
  'previousOrders',
}

export interface SetAuthWhatsOpenProps extends BoxProps {
  setwhatsOpen?: React.Dispatch<React.SetStateAction<AuthWhatsOpen>>;
  modification?: boolean;
}

export interface AuthWhatsOpenProps extends SetAuthWhatsOpenProps {
  disclosure: UseDisclosureReturn;
  whatsOpen?: AuthWhatsOpen;
  right?: any;
  left?: any;
}

export interface AuthFormProps extends SetAuthWhatsOpenProps {
  reduxProps: ReduxNavProps;
  showHeading?: boolean;
}

const mapStateToProps = (state: RootState) => {
  return {
    products: state.productReducer.products,
    categories: state.productReducer.categories,
    isLoggedIn: state.authReducer.isLoggedIn,
    userInfo: state.authReducer.userInfo,
    homeBackgrounds: state.newsReducer.homeBackgrounds,
    cartItems: state.cartReducer.cartItems,
    modifyAddress: state.addressReducer.currentlyModifiedAddress,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    ProductActions: ProductActions(dispatch),
    AuthActions: AuthActions(dispatch),
    NewsActions: NewsActions(dispatch),
    CartActions: CartActions(dispatch),
    AddressActions: AddressActions(dispatch),
  };
};

export const connector = connect(mapStateToProps, mapDispatchToProps);
export type ReduxProps = ConnectedProps<typeof connector>;
