import moment from 'moment';

import { configFile } from '../../configFile';

export type IHoursAndMins = {
  hour: number;
  min: number;
};

const getWholeHours = (from: number, to: number) => {
  const hoursMinsObj = [];
  for (let i = from; i < to; i++) {
    hoursMinsObj.push({ hour: i, min: 0 });

    hoursMinsObj.push({ hour: i, min: 15 });

    hoursMinsObj.push({ hour: i, min: 30 });

    hoursMinsObj.push({ hour: i, min: 45 });
  }
  return hoursMinsObj;
};

const getTimeConstraints = (currentWeekDay: number): IHoursAndMins[] => {
  const weekDay = currentWeekDay;
  // 0 vasárnap

  let hoursAndMins: IHoursAndMins[] = [];
  if (weekDay >= 1 && weekDay <= 4) {
    hoursAndMins = hoursAndMins.concat(getWholeHours(11, 22));
  }
  if (weekDay >= 4 && weekDay <= 6) {
    hoursAndMins = hoursAndMins.concat(getWholeHours(11, 23));
  }
  if (weekDay === 0) {
    hoursAndMins = hoursAndMins.concat(getWholeHours(12, 22));
  }

  hoursAndMins = hoursAndMins.filter((actualHm) => {
    const today = moment();

    if (today.get('day') === currentWeekDay) {
      const timeFromNowInMins = moment
        .duration(
          today.diff(
            moment().set({ hour: actualHm.hour, minute: actualHm.min })
          )
        )
        .asMinutes();
      if (timeFromNowInMins > -configFile.MINIMUM_MINS_BETWEEN_SHIPPING) {
        return false;
      }
    }
    return true;
  });

  return hoursAndMins;
};

export default getTimeConstraints;
