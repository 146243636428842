//@ts-nocheck

/** @jsx jsx */

import { Box, Flex, Heading } from '@chakra-ui/core';
import { jsx } from '@emotion/core';
import Axios from 'axios';
import { useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';

import Card from '../components/Card';
import NewsArticle from '../components/news/NewsComponents';
import NewsActions from '../redux/news/NewsActions';
import { RootState } from '../redux/store';
import Navbar from '../components/navbar/Navbar';
import Footer from '../components/footer/Footer';

function NewsScreen(reduxProps: ReduxProps): JSX.Element {
  useEffect(() => {
    reduxProps.NewsActions.fetchNews();
  }, [reduxProps.NewsActions]);

  const renderedArticles = reduxProps.allNews.map((newsElement) => {
    const imageUrl = `${Axios.defaults.baseURL}/files/${newsElement.picture?.filePath}`;
    return (
      <NewsArticle
        key={newsElement.id}
        articleTitle={newsElement.header}
        articleDate={new Date(newsElement.createdAt)}
        articleImage={imageUrl}
        articleText={newsElement.body}
      />
    );
  });

  return (
    <>
      <Navbar />
      <Box as='main' mt={4} color='black'>
        <Flex direction='column'>
          <Card my={3} mt={0}>
            <Heading as='h1' textAlign='center' pb={2}>
              Hírek
            </Heading>
          </Card>
          {renderedArticles}
        </Flex>
      </Box>
      <Footer />
    </>
  );
}

const mapStateToProps = (state: RootState) => {
  return {
    allNews: state.newsReducer.newsData,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    NewsActions: NewsActions(dispatch),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;
export default connector(NewsScreen);
