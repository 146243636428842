import { BoxProps, Heading, IconButton, Image, Stack } from '@chakra-ui/core';
import React, { useEffect } from 'react';

import { configFile } from '../../configFile';
import Card from '../Card';
import { authConnector, ReduxNavProps } from '../navbar/NavConstants';

function ExitPopup({
  reduxProps,
  close,
  ...props
}: BoxProps & {
  reduxProps: ReduxNavProps;
  close: (newStoreState: boolean) => void;
}): JSX.Element {
  useEffect(() => {
    if (!reduxProps.homeBackgrounds)
      reduxProps.NewsActions.fetchHomeBackgrounds();
    return () => {};
  }, [reduxProps.homeBackgrounds, reduxProps.NewsActions]);

  const imgsrc = reduxProps.homeBackgrounds.find((e) =>
    e.key?.toLowerCase().includes('exit')
  )?.picture?.filePath;

  return (
    <Card color='black' maxW='350px' {...props}>
      <IconButton
        onClick={() => {
          close(false);
        }}
        aria-label='Close'
        position='absolute'
        top={2}
        right={4}
        variantColor='red'
        icon='close'
        size='sm'
      />
      <Stack spacing={4}>
        <Heading as='h3' size='sm' pr={4}>
          Biztosan el akarod hagyni az oldalt?
        </Heading>
        <Image
          src={
            imgsrc
              ? `${configFile.serverBaseUrlPicture}${imgsrc}`
              : `https://via.placeholder.com/150`
          }
          fallbackSrc='https://via.placeholder.com/150'
        />
      </Stack>
    </Card>
  );
}

export default authConnector(ExitPopup);
