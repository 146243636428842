import axios from 'axios';

import { IAddress } from '../addresses/AddressTypes';
import {
  AuthActionType,
  AuthDispatchType,
  ForgotFormData,
  LoginFormData,
  RegisterFormData,
} from './AuthTypes';

export default function AuthActions(
  dispatch: (action: AuthDispatchType) => unknown
): AuthActionType {
  return {
    signIn: async (formData: LoginFormData) => {
      return axios
        .post(
          '/auth/login',
          JSON.stringify({
            email: formData.email,
            password: formData.password,
          })
        )
        .then((resp) => {
          const { token, refreshToken, user } = resp.data;
          if (formData.isStayLoggedIn) {
            localStorage.setItem('user', JSON.stringify(user));
            localStorage.setItem('token', token);
            localStorage.setItem('refreshToken', refreshToken);
          }
          sessionStorage.setItem('user', JSON.stringify(user));
          sessionStorage.setItem('token', token);
          sessionStorage.setItem('refreshToken', refreshToken);

          dispatch({
            type: 'LOGIN_SUCCESS',
            payloadToken: token,
            payloadRefreshToken: refreshToken,
            payloadUserSession: user,
          });
        })
        .catch(() => {
          dispatch({ type: 'LOGIN_FAILURE' });
          throw Error('Hibás bejelentkezés');
        });
    },

    signInWithFacebook: async (accessToken: string, refreshToken: string) => {
      return axios
        .get('/auth/facebook/callback', {
          params: {
            access_token: accessToken,
            refresh_token: refreshToken,
          },
        })
        .then((resp) => {
          // eslint-disable-next-line no-shadow
          const { token, refreshToken, user } = resp.data;
          localStorage.setItem('user', JSON.stringify(user));
          localStorage.setItem('token', token);
          localStorage.setItem('refreshToken', refreshToken);
          sessionStorage.setItem('user', JSON.stringify(user));
          sessionStorage.setItem('token', token);
          sessionStorage.setItem('refreshToken', refreshToken);

          dispatch({
            type: 'LOGIN_SUCCESS',
            payloadToken: token,
            payloadRefreshToken: refreshToken,
            payloadUserSession: user,
          });
        })
        .catch(() => {
          dispatch({ type: 'LOGIN_FAILURE' });
          throw Error('Hibás bejelentkezés');
        });
    },
    signInWithGoogle: async (accessToken: string) => {
      return axios
        .get('/auth/google/callback', {
          params: {
            access_token: accessToken,
          },
        })
        .then((resp) => {
          // eslint-disable-next-line no-shadow
          const { token, refreshToken, user } = resp.data;
          localStorage.setItem('user', JSON.stringify(user));
          localStorage.setItem('token', token);
          localStorage.setItem('refreshToken', refreshToken);
          sessionStorage.setItem('user', JSON.stringify(user));
          sessionStorage.setItem('token', token);
          sessionStorage.setItem('refreshToken', refreshToken);

          dispatch({
            type: 'LOGIN_SUCCESS',
            payloadToken: token,
            payloadRefreshToken: refreshToken,
            payloadUserSession: user,
          });
        })
        .catch(() => {
          dispatch({ type: 'LOGIN_FAILURE' });
          throw Error('Hibás bejelentkezés');
        });
    },

    register: async (formData: RegisterFormData) => {
      return axios
        .post(
          '/auth/register',
          JSON.stringify({
            email: formData.email,
            name: formData.fullName,
            phone:
              formData.phone.substring(0, 2) === '06'
                ? formData.phone
                : `06${formData.phone}`,
            password: formData.password,
            addresses: [],
            allergies: formData.allergies,
            birthday: formData.birthday,
            type: 'USER',
          })
        )
        .then((resp) => {
          const { token, refreshToken, user } = resp.data;

          sessionStorage.setItem('user', JSON.stringify(user));
          sessionStorage.setItem('token', token);
          sessionStorage.setItem('refreshToken', refreshToken);

          dispatch({
            type: 'LOGIN_SUCCESS',
            payloadToken: token,
            payloadRefreshToken: refreshToken,
            payloadUserSession: user,
          });
        })
        .catch((error: any) => {
          dispatch({ type: 'REGISTRATION_FAILURE' });
          throw new Error(error.response.data.msg);
        });
    },

    modifyInfo: async (formData: RegisterFormData) => {
      return axios.put('/me', {
        name: formData.fullName,
        password: formData.password,
        phone: formData.phone,
        birthday: formData.birthday,
        allergies: formData.allergies,
      });
    },

    forgot: async (formData: ForgotFormData) => {
      return axios.post('/auth/forgot', { email: formData.email });
    },

    addAddress: async (addresses: IAddress[]) => {
      return axios
        .put('/me', {
          addresses,
        })
        .then((resp) => {
          return resp.status;
        })
        .catch((error: Error) => {
          throw error || Error('Cím hozzáadása sikertelen!');
        });
    },

    removeAddress: async (remainingAddresses: IAddress[]) => {
      return axios
        .put('/me', {
          addresses: remainingAddresses,
        })
        .then((resp) => {
          return resp.status;
        })
        .catch((error: Error) => {
          throw error || Error('Cím törlése sikertelen!');
        });
    },

    modifyAddress: async (formData: IAddress) => {},

    getUserInfo: async () => {
      return axios.get('/me').then((resp) => {
        dispatch({ type: 'INFO_DOWNLOADED', payloadUserInfo: resp.data });
        return resp.data;
      });
    },

    signOut: async () => {
      localStorage.clear();
      sessionStorage.clear();
      dispatch({ type: 'LOGOUT' });
    },
    sessionMaybeAvailable: async () => {
      if (
        localStorage.getItem('refreshToken') &&
        localStorage.getItem('user') &&
        localStorage.getItem('token')
      ) {
        const refreshToken = localStorage.getItem('refreshToken')!;
        const token = localStorage.getItem('token')!;
        const user = JSON.parse(localStorage.getItem('user')!);
        sessionStorage.setItem('user', JSON.stringify(user));
        sessionStorage.setItem('token', token);
        sessionStorage.setItem('refreshToken', refreshToken);
        return axios
          .post('/auth/verifyToken', { token: localStorage.getItem('token') })
          .then(() => {
            dispatch({
              type: 'LOGIN_SUCCESS',
              payloadToken: token,
              payloadRefreshToken: refreshToken,
              payloadUserSession: user,
            });
          })
          .catch((err) => {
            localStorage.removeItem('refreshToken');
            localStorage.removeItem('user');
            localStorage.removeItem('token');
          });
      }
      return null;
    },
  };
}
