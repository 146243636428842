import { Box, Text, Input, Heading, Flex } from '@chakra-ui/core';
import { List } from '@chakra-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import Card from '../components/Card';
import NewsActions from '../redux/news/NewsActions';
import { RootState } from '../redux/store';
import { kecskemetData, szolnokData } from '../components/szolnokAddresses';
import Navbar from '../components/navbar/Navbar';
import Footer from '../components/footer/Footer';

const DeliveryFeeScreen = () => {
  const { city } = useParams<{ city: string }>();

  const [input, setInput] = React.useState('');

  const columns = [
    {
      name: 'Utcanév',
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: 'Kategória',
      selector: (row) => row.category,
      sortable: true,
    },
  ];

  const data = city === 'szolnok' ? szolnokData : kecskemetData;

  return (
    <>
      <Navbar />
      <Box as='main' mt={4} color='black'>
        <Card shadow='2px 2px 2px 0px rgba(0,0,0,0.75)'>
          <Flex alignItems='center'>
            <Heading size={'md'} mr={2}>
              Árak(Ft):
            </Heading>
            <List styleType='disc'>
              {city === 'szolnok' ? (
                <Box>
                  <Text>A: 390, B: 590, C: 790</Text>
                </Box>
              ) : (
                <Box>
                  {' '}
                  <Text>A: 190, B: 390, C: 590, D: 790, E: 1090</Text>
                </Box>
              )}
            </List>
          </Flex>
          <Input
            my={2}
            value={input}
            onChange={(e) => setInput(e.target.value)}
            placeholder='Keresés..'
          />

          <DataTable
            columns={columns}
            data={data.filter((address) =>
              address.name.match(new RegExp(input, 'i'))
            )}
          />
        </Card>
      </Box>
      <Footer />
    </>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    allNews: state.newsReducer.newsData,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    NewsActions: NewsActions(dispatch),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(DeliveryFeeScreen);
