import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web

import AddressReducer from './addresses/AddressReducer';
import AuthReducer from './auth/AuthReducer';
import CartReducer from './cart/CartReducer';
import CouponReducer from './coupons/CouponReducer';
import NewsReducer from './news/NewsReducer';
import OrderReducer from './orders/OrderReducer';
import ProductReducer from './products/ProductReducer';

const logger = (store: any) => (next: any) => (action: any) => {
  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    console.log('dispatching', action);
    console.log('next state', store.getState());
  }
  const result = next(action);
  return result;
};
const persistConfig = {
  key: 'root',
  whitelist: ['cartReducer'],
  storage,
};

const appReducer = combineReducers({
  authReducer: AuthReducer,
  orderReducer: OrderReducer,
  productReducer: ProductReducer,
  couponReducer: CouponReducer,
  cartReducer: CartReducer,
  newsReducer: NewsReducer,
  addressReducer: AddressReducer,
});

export const INTERVAL_STACK: number[] = [];
const rootReducer = (state: any, action: any) => {
  if (action.type === 'LOGOUT') {
    // eslint-disable-next-line no-param-reassign
    state = undefined;
  }
  return appReducer(state, action);
};
// FOR MAKE CHANGES IN LOCAL
const persistedReducer = persistReducer(persistConfig, rootReducer);

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore FOR REDUX DEV TOOLS
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

/* eslint-disable */
export default () => {
  const store = createStore(
    persistedReducer,
    composeEnhancers(applyMiddleware(logger))
  );
  const persistor = persistStore(store);
  return {
    store,
    persistor,
  };
};

export type RootState = ReturnType<typeof rootReducer>;
