import { CouponDispatchType, CouponState } from './CouponTypes';

const initState: CouponState = {
  coupons: [
    {
      id: 'coupon12',
      isAvailable: true,
      name: 'Fix500 kupon',
      type: { value: 'FIX', label: 'Fix' },
      amount: 500,
      description: 'Levesz 500 ft-ot a rendelésből',
    },
  ],
  usedCoupon: '',
};

const CouponReducer = (
  state = initState,
  action: CouponDispatchType
): CouponState => {
  switch (action.type) {
    case 'COUPONS_DOWNLOADED':
      return {
        ...state,
      };
    case 'COUPON_ADDED':
      return {
        ...state,
        // @ts-ignore
        usedCoupon: action.payloadCoupon,
      };
    case 'COUPON_DELETED':
      return {
        ...state,
        coupons: state.coupons.filter((c) => c.id !== action.payloadCoupon?.id),
      };
    case 'COUPON_MODIFIED': {
      const i = state.coupons.findIndex(
        (p) => p.id === action.payloadCoupon?.id
      );
      state.coupons.splice(i, 1, action.payloadCoupon!);
      return {
        ...state,
      };
    }
    default:
      return state;
  }
};
export default CouponReducer;
