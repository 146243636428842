import Axios from 'axios';

import { IAddress } from '../addresses/AddressTypes';
import { ICartItem } from '../cart/CartTypes';
import {
  IOrder,
  IOrderForm,
  IOrderShippingMethod,
  OrderActionType,
  OrderDispatchType,
} from './OrderTypes';

const OrderActions = (
  dispatch: (action: OrderDispatchType) => {}
): OrderActionType => {
  return {
    orderStartedPreparing: async (order: IOrder) => {
      dispatch({
        type: 'ORDER_STARTED_PREPARING',
        payloadStartedPreparing: order,
      });
    },
    validateCoupon: async (products: ICartItem[], code: string) => {
      return Axios.post('/cart/coupon', {
        orderItems: products.map((e) => {
          return {
            itemId: e.product.id,
            quantity: e.quantity,
            extraPrice: e.extraPrice,
            itemContent: e.product.contents.map((c) => {
              return { ...c, itemId: c.item?.id };
            }),
          };
        }),
        couponCode: code,
      }).then(async (resp: any) => {
        const newPrice = await Axios.post('/cart', {
          orderItems: products.map((e) => {
            return {
              itemId: e.product.id,
              quantity: e.quantity,
              extraPrice: e.extraPrice,
              itemContent: e.product.contents.map((c) => {
                return { ...c, itemId: c.item?.id };
              }),
            };
          }),
          couponCode: code,
        });
        return { ...newPrice.data, ...resp.data };
      });
    },
    fetchShippingCosts: async () => {
      return Axios.get('/shipping_costs/')
        .then((resp: any) => {
          return resp;
        })
        .catch((error: any) => {
          if (error.errData) {
            throw new Error(error.errData.msg || error.errData);
          } else {
            throw new Error(error.message);
          }
        });
    },
    fetchPromotionPrices: async () => {
      return Axios.get('/promotion/')
        .then((resp: any) => {
          return resp;
        })
        .catch((error: any) => {
          if (error.errData) {
            throw new Error(error.errData.msg || error.errData);
          } else {
            throw new Error(error.message);
          }
        });
    },

    checkPrice: async (items: ICartItem[]) => {
      return Axios.post('/cart', {
        orderItems: items.map((e) => {
          return {
            itemId: e.product.id,
            quantity: e.quantity,
            extraPrice: e.extraPrice,
            itemContent: e.product.contents.map((c) => {
              return { ...c, itemId: c.item?.id };
            }),
          };
        }),
      });
    },

    orderCart: async (
      cartItems: ICartItem[],
      cityId: number,
      address: IAddress,
      orderForm: IOrderForm
    ) => {
      return Axios.post(
        '/cart/order',
        JSON.stringify({
          zip: address.zip,
          cityId,
          couponCode: orderForm.coupon,
          addressCity: address.city,
          usedLoyaltyPoints: orderForm.usedLoyaltyPoints,
          street: address.street,
          orderItems: cartItems.map((e) => {
            return {
              itemId: e.product.id,
              quantity: e.quantity,
              extraPrice: e.extraPrice,
              isVoucher: e.product.isVoucher,
              isRedemption: e.product.isRedemption,
              itemContent: e.product.contents.map((c) => {
                return { ...c, itemId: c.item?.id };
              }),
            };
          }),
          address: `${address.zip} ${address.city}, ${address.street} ${address.number}, ${address.floorDoor}, ${address.bellComment}`,
          shippingMethod: orderForm.shippingMethod,
          shippingDate:
            orderForm.shippingMethod !== IOrderShippingMethod.AtTime
              ? new Date().toISOString()
              : orderForm.shippingDate.toDate(),
          phone: orderForm.phone,
          payment: orderForm.paymentType!.replace(/[0-9]/g, ''),
          email: orderForm.email,
          name: orderForm.name === '' ? 'default' : orderForm.name,
          billing: {
            name: orderForm.name === '' ? 'default' : orderForm.name,
            country: 'Magyarország',
            state: orderForm.county,
            city: address.city,
            zip: address.zip.toString(),
            address: `${address.zip} ${address.city}, ${address.street} ${address.number}, ${address.floorDoor}`,
          },
          requestInvoice: orderForm.requestInvoice,
          comment:
            orderForm.comment +
            (orderForm.bigMoney ? '\n Nagy címlettel fizetek' : '') +
            (orderForm.requestInvoice && !orderForm.isPrivate
              ? `
              Számlát kér cégre!

              Cégnév:${orderForm.billingCompany}
              Adószám:${orderForm.billingTaxNumber}
              Cím:${orderForm.billingAddress}`
              : '') +
            (orderForm.isPrivate && orderForm.requestInvoice
              ? `
              Számlát kér magánszemélyre!

              Név:${orderForm.isPrivateName}
              Cím:${orderForm.billingAddress}`
              : ''),
        })
      ).then((resp: any) => {
        if (resp.data.paymentUrl) {
          window.location.href = resp.data.paymentUrl;
        }
      });
    },
  };
};

export default OrderActions;
