/** @jsx jsx */

import { BoxProps, Flex, Grid, IconButton, Image, Text } from '@chakra-ui/core';
import { jsx } from '@emotion/core';

import { configFile } from '../../configFile';
import { ICartItem } from '../../redux/cart/CartTypes';
import { ICategory, IProduct } from '../../redux/products/ProductTypes';
import Card from '../Card';

interface CartCardProps extends BoxProps {
  item: ICartItem;
  parentCategory?: ICategory;
  addAction: (product: IProduct) => void;
  subAction: (product: IProduct) => void;
  allCategoryFlatted: ICategory[];
  extraPrices: any;
  promotionItems: IProduct[];
}

export default function CartCard({
  item,
  parentCategory,
  addAction,
  subAction,
  allCategoryFlatted,
  extraPrices,
  promotionItems,
  ...props
}: CartCardProps): JSX.Element {
  // eslint-disable-next-line no-param-reassign
  item.product.contents = item.product.contents.filter(
    (content) => content.item !== null
  );

  const price = item.serverPrice ?? item.product.price ?? -1;

  const getExtraType = (item: any) => {
    const ePrice = item.extraPrice / item.quantity;

    if (ePrice === 0) {
      return '';
    }
    if (
      ePrice === extraPrices.lactoseFree28 ||
      ePrice === extraPrices.lactoseFree52
    ) {
      return '- Laktózmentes sajt';
    }
    if (ePrice === extraPrices.fitness) {
      return '- Teljes kiőrlésű tészta';
    }
    return '- Laktózmentes sajt + Teljes kiőrlésű tészta';
  };

  const getParentCatName = () => {
    let pa = parentCategory?.name ?? '';
    if (pa === 'default') pa = 'Extra';
    return pa;
  };

  const getItemImg = () => {
    let img = `https://via.placeholder.com/150`;
    if (item.product.picture) {
      img = `${configFile.serverBaseUrl}/files/${item.product.picture?.filePath}`;
    } else if (parentCategory?.picture) {
      img = `${configFile.serverBaseUrl}/files/${parentCategory.picture?.filePath}`;
    }
    return img;
  };
  return (
    <Card gridColumn='1 / 6' p='10px 6px' h='fit-content' w='100%' {...props}>
      <Grid
        gridTemplateColumns='1fr 4fr 4fr 2.5fr 2.5fr'
        my='auto'
        justifyItems='center'
        justifyContent='center'
        alignItems='center'
        gap={4}
      >
        <Flex direction='column'>
          {!promotionItems.find((i) => i.id === item.product.id) && (
            <IconButton
              icon='add'
              aria-label='Add another'
              size='xs'
              mb={2}
              onClick={() => {
                addAction(item.product);
              }}
            />
          )}
          <IconButton
            icon='minus'
            aria-label='Remove one'
            size='xs'
            onClick={() => {
              subAction(item.product);
            }}
          />
        </Flex>
        <Image
          maxH={100}
          w='auto'
          borderRadius='md'
          src={getItemImg()}
          fallbackSrc='https://via.placeholder.com/150'
        />
        <Text fontSize='sm' textAlign='center'>
          {`${getParentCatName()} / ${item.product.name} ${getExtraType(
            item
          )} ${
            item.product.contents.length === 0 ? '' : '-'
          }${item.product.contents.map((e) => {
            if (e.quantity > 1) {
              return ` ${e.item?.name} (x${e.quantity})`;
            }
            return ` ${e.item?.name}`;
          })}`}
        </Text>
        <Text fontSize='sm' textAlign='center'>
          {item.quantity} db
        </Text>
        <Text fontSize='sm' textAlign='center'>
          {price} Ft
        </Text>
      </Grid>
    </Card>
  );
}
