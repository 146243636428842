import { AddressDispatchType, AddressesState, IAddress } from './AddressTypes';

const defaultFormData: IAddress = {
  name: '',
  zip: 0,
  city: '',
  street: '',
  number: '',
  floorDoor: '',
  bellComment: '',
};

const initState: AddressesState = {
  addresses: [],
  currentlyModifiedAddress: defaultFormData,
};

const AddressReducer = (
  state = initState,
  action: AddressDispatchType,
): AddressesState => {
  switch (action.type) {
    case 'REMOVE_ADDRESS':
      var newAddresses = [...state.addresses];
      var index = newAddresses.indexOf(action.address);
      if (index !== -1) {
        newAddresses.splice(index, 1);
      }
      return {
        ...state,
        addresses: newAddresses,
      };
    case 'ADD_ADDRESS':
      return {
        ...state,
        addresses: [...state.addresses, action.address],
      };
    case 'ADDRESS_MODIFICATION':
      return {
        ...state,
        currentlyModifiedAddress: action.address,
      };
    default:
      return state;
  }
};
export default AddressReducer;
