import {
  BoxProps,
  Flex,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from '@chakra-ui/core';
import React from 'react';

import AboutForm from '../auth/AboutForm';
import AddressForm from '../auth/AddressForm';
import ForgotForm from '../auth/ForgotForm';
import LoginForm from '../auth/LoginForm';
import ModifyForm from '../auth/ModifyForm';
import PreviousOrderForm from '../auth/PreviousOrder/PreviousOrderForm';
import RegisterForm from '../auth/RegisterForm';
import { AuthWhatsOpen, AuthWhatsOpenProps } from '../home/HomeConstants';
import { openAuthModal, ReduxNavProps } from './NavConstants';
import { NavLink } from './NavLinks';

export function NavbarLogin({
  setwhatsOpen,
  disclosure,
  ...restProps
}: AuthWhatsOpenProps & BoxProps): JSX.Element {
  return (
    <Flex color="link" {...restProps}>
      <NavLink
        onClick={() => {
          if (setwhatsOpen) setwhatsOpen(AuthWhatsOpen.login);
          openAuthModal(disclosure);
        }}
      >
        Bejelentkezés
      </NavLink>
      <NavLink
        onClick={() => {
          if (setwhatsOpen) setwhatsOpen(AuthWhatsOpen.register);
          openAuthModal(disclosure);
        }}
      >
        Regisztráció
      </NavLink>
      <NavLink
        onClick={() => {
          if (setwhatsOpen) setwhatsOpen(AuthWhatsOpen.forgot);
          openAuthModal(disclosure);
        }}
      >
        Elfelejtett jelszó
      </NavLink>
    </Flex>
  );
}

export function NavbarLoggedIn({
  setwhatsOpen,
  disclosure,
  ...restProps
}: AuthWhatsOpenProps & BoxProps): JSX.Element {
  return (
    <Flex color="link" {...restProps}>
      <NavLink
        onClick={() => {
          if (setwhatsOpen) setwhatsOpen(AuthWhatsOpen.about);
          openAuthModal(disclosure);
        }}
      >
        Adataim
      </NavLink>
      <NavLink
        onClick={() => {
          if (setwhatsOpen) setwhatsOpen(AuthWhatsOpen.previousOrders);
          openAuthModal(disclosure);
        }}
      >
        Korábbi rendeléseim
      </NavLink>
    </Flex>
  );
}

export function AuthModal({
  reduxProps,
  whatsOpen,
  setwhatsOpen,
  disclosure,
  ...props
}: AuthWhatsOpenProps & { reduxProps: ReduxNavProps }): JSX.Element {
  return (
    <Modal isOpen={disclosure.isOpen} onClose={disclosure.onClose} {...props}>
      <ModalOverlay />
      <ModalContent w="fit-content" px={2} backgroundColor="transparent">
        <ModalCloseButton />
        <LoginForm
          showHeading
          reduxProps={reduxProps}
          setwhatsOpen={setwhatsOpen}
          display={whatsOpen === AuthWhatsOpen.login ? 'block' : 'none'}
        />
        <ForgotForm
          showHeading
          reduxProps={reduxProps}
          display={whatsOpen === AuthWhatsOpen.forgot ? 'block' : 'none'}
        />
        <RegisterForm
          showHeading
          reduxProps={reduxProps}
          setwhatsOpen={setwhatsOpen}
          display={whatsOpen === AuthWhatsOpen.register ? 'block' : 'none'}
        />
        <ModifyForm
          formToModify={{
            fullName: reduxProps.userInfo?.name ?? '',
            email: reduxProps.userInfo?.email ?? '',
            password: '',
            confirmPassword: '',
            phone: reduxProps.userInfo?.phone ?? '',
            birthday: reduxProps.userInfo?.birthday ?? '',
            allergies: reduxProps.userInfo?.allergies ?? [],
          }}
          reduxProps={reduxProps}
          setwhatsOpen={setwhatsOpen}
          display={whatsOpen === AuthWhatsOpen.modify ? 'block' : 'none'}
        />
        <AboutForm
          reduxProps={reduxProps}
          setwhatsOpen={setwhatsOpen}
          display={whatsOpen === AuthWhatsOpen.about ? 'block' : 'none'}
        />
        <PreviousOrderForm
          reduxProps={reduxProps}
          setwhatsOpen={setwhatsOpen}
          display={whatsOpen === AuthWhatsOpen.previousOrders ? 'block' : 'none'}
        />

        <AddressForm
          reduxProps={reduxProps}
          setwhatsOpen={setwhatsOpen}
          display={whatsOpen === AuthWhatsOpen.address ? 'block' : 'none'}
        />
        <AddressForm
          reduxProps={reduxProps}
          setwhatsOpen={setwhatsOpen}
          modification
          display={whatsOpen === AuthWhatsOpen.addressmodify ? 'block' : 'none'}
        />
      </ModalContent>
    </Modal>
  );
}
