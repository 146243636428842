/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-case-declarations */
import moment from 'moment';
import { CartDispatchType, CartState, ICartItem } from './CartTypes';

const initState: CartState = {
  cartItems: [],
  changeHelper: 0,
  userFormData: {
    paymentType: '',
    shippingMethod: '',
    shippingDate: moment(),
    comment: '',
    bigMoney: false,
    requestInvoice: false,
    isPrivate: false,
    isPrivateName: '',
    billingCompany: '',
    billingTaxNumber: '',
    billingAddress: '',
    usedLoyaltyPoints: 0,
  },
};

const CartReducer = (
  state = initState,
  action: CartDispatchType
): CartState => {
  // temporary fix for wrong persist call from store.ts
  if (
    action.type !== 'ADD_TO_CART' &&
    action.type !== 'REMOVE_ITEM' &&
    action.type !== 'ADD_QUANTITY' &&
    action.type !== 'SUB_QUANTITY' &&
    action.type !== 'EMPTY_CART' &&
    action.type !== 'SAVE_DATA' &&
    action.type !== 'EMPTY_DATA'
  ) {
    return state;
  }

  const addedItem: ICartItem = {
    product: action.product!,
    quantity: 1,
    extraPrice: action.extraPrice || 0,
  };

  if (state.changeHelper > 100) {
    state.changeHelper = -50;
  }
  if (state.changeHelper < -100) {
    state.changeHelper = 50;
  }

  const existedItem = state.cartItems.find(
    (cartItem) =>
      JSON.stringify(cartItem.product) === JSON.stringify(addedItem.product)
  );

  let newCartItems: ICartItem[] = [];
  switch (action.type) {
    case 'ADD_TO_CART':
      if (existedItem?.extraPrice === action.extraPrice && existedItem) {
        existedItem!.extraPrice +=
          existedItem.extraPrice / existedItem!.quantity;
        const cartItems = [...state.cartItems];
        cartItems[
          cartItems.findIndex(
            (cartItem) =>
              JSON.stringify(cartItem.product) ===
              JSON.stringify(addedItem.product)
          )
        ].quantity += 1;

        return {
          ...state,
          cartItems: cartItems,
        };
      }
      addedItem.quantity = 1;

      newCartItems = [...state.cartItems, addedItem];
      return {
        ...state,
        cartItems: newCartItems,
        changeHelper: state.changeHelper + 1,
      };

    case 'REMOVE_ITEM':
      const newItems = state.cartItems.filter(
        (cartItem) =>
          JSON.stringify(cartItem.product) !== JSON.stringify(addedItem.product)
      );
      return {
        ...state,
        cartItems: newItems,
        changeHelper: state.changeHelper + 1,
      };

    case 'ADD_QUANTITY':
      existedItem!.extraPrice += existedItem.extraPrice / existedItem!.quantity;
      existedItem!.quantity += 1;
      return {
        ...state,
        changeHelper: state.changeHelper + 1,
      };

    case 'SUB_QUANTITY':
      if (existedItem!.quantity === 1) {
        const newItems = state.cartItems.filter(
          (cartItem) =>
            JSON.stringify(cartItem.product) !==
            JSON.stringify(addedItem.product)
        );
        return {
          ...state,
          cartItems: newItems,
          changeHelper: state.changeHelper + 1,
        };
      }
      existedItem!.extraPrice -= existedItem.extraPrice / existedItem!.quantity;
      existedItem!.quantity -= 1;
      return { ...state, changeHelper: state.changeHelper - 1 };

    case 'EMPTY_CART':
      return initState;

    case 'SAVE_DATA':
      const newData = action.data;
      return {
        ...state,
        userFormData: newData,
      };

    default:
      return state;
  }
};
export default CartReducer;
