import {
  Flex,
  Heading,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  Text,
} from '@chakra-ui/core';
import React from 'react';
import Card from '../../Card';
import { SetAuthWhatsOpenProps } from '../../home/HomeConstants';
import { useParams } from 'react-router-dom';
import { CITY_ID } from '../../../config/categories';
import { authConnector, ReduxNavProps } from '../../navbar/NavConstants';
import { OrderCard } from './OrderCard';
import placeholder from '../../../assets/prevOrder/prevOrderPlaceholder.png';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { IOrder } from '../../../redux/orders/OrderTypes';

function PreviousOrderForm({
  reduxProps,
  setwhatsOpen,
  ...props
}: SetAuthWhatsOpenProps & { reduxProps: ReduxNavProps }): JSX.Element {
  const { categories } = reduxProps;
  const { userInfo } = useSelector((state: RootState) => state.authReducer);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [order, setOrder] = useState<any>();
  const orderedOrders = userInfo?.orderedOrders;
  const { city: cityParam } = useParams<{
    city: string;
  }>();
  // todo this is ugly af but works
  const cityId = CITY_ID.find((e) => e.name === cityParam);
  const mainCat = categories.find((e) => e.id === cityId?.id);
  const getPizzaCat =
    mainCat?.childCategories?.find((e) => e.name === 'Pizza') ?? null;
  const getItalCat =
    mainCat?.childCategories?.find((e) => e.name === 'Ital') ?? null;
  const getItals =
    getItalCat?.childCategories?.find((e) => e.name === ' Üdítők') ?? null;

  const getCreator =
    mainCat?.childCategories?.find((e) => e.name === 'Kreátor') ?? null;
  const getPizzaCatName = (id: number) => {
    const getCat =
      getPizzaCat?.childCategories.find((e) => e.id === id) ||
      getItals?.childCategories.find((e) => e.id === id);

    const creatorName = getCreator?.childCategories.find((e) => e.id === id)
      ? 'Extra Pizza'
      : null;
    return getCat?.name || creatorName;
  };
  const showPrevOrder = (idx: number) => {
    if (orderedOrders) {
      setOrder(orderedOrders[idx]);
    }
    onOpen();
  };
  const onOrderClicked = (order: IOrder) => {
    order.orderItems.forEach((o) => {
      const cartProduct = {
        ...o.item,
        //@ts-ignore
        contents: o.itemContent,
      } as any;

      for (let i = 0; i < o.quantity; i++) {
        reduxProps.CartActions.addToCart(cartProduct, 0);
      }
    });
    onClose();
  };
  const formatMonth = (monthNumber: number) => {
    if (monthNumber < 10) {
      return '0' + monthNumber.toString();
    } else {
      return monthNumber;
    }
  };
  const formatDate = (date) => {
    const orderDate = new Date(date);
    const orderUTC = `${formatMonth(orderDate.getUTCMonth() + 1)}.${formatMonth(
      orderDate.getUTCDate()
    )}`;
    return orderUTC;
  };

  const DetailsContainer = ({
    onClick,
    idx,
    date,
  }: {
    onClick: (idx: number) => void;
    idx: number;
    date: any;
  }) => {
    return (
      <Flex
        onClick={() => onClick(idx)}
        cursor='pointer'
        flex='1'
        alignItems='center'
        flexDir='column'
      >
        <Image h={80} w={80} src={placeholder} />
        <Text textAlign='center'>{formatDate(date)}</Text>
      </Flex>
    );
  };

  return (
    <Card color='black' maxW='350px' {...props}>
      <Heading fontSize='lg' mb='4'>
        Az utolsó 3 rendelést itt tudod megtekinteni és újra rendelni!
      </Heading>
      <Flex flexDir='row' justifyContent='space-between'>
        {orderedOrders?.map((o, i) => (
          <DetailsContainer
            key={i}
            idx={i}
            date={o.createdAt}
            onClick={showPrevOrder}
          />
        ))}
      </Flex>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Rendelés</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <OrderCard
              onOrderClicked={onOrderClicked}
              order={order}
              getPizzaCatName={getPizzaCatName}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Card>
  );
}

export default authConnector(PreviousOrderForm);
