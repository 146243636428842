import { Divider, Flex, Image, Text } from '@chakra-ui/core';
import React from 'react';
import { generatePath } from 'react-router-dom';

import Card from '../Card';
import { AuthWhatsOpen, AuthWhatsOpenProps } from '../home/HomeConstants';
import { NavBarIconsProps, openAuthModal } from './NavConstants';
import { InfoLink } from './NavLinks';
import { NavRouterLink } from './NavLinks';

export default function NavDropDown({
  setwhatsOpen,
  disclosure,
  isLoggedIn,
  city,
  close,
  left,
  right,
}: AuthWhatsOpenProps & {
  isLoggedIn: boolean;
  city: string;
  close: any;
  right: any;
  left: any;
}): JSX.Element {
  return (
    <Card p={0} backgroundColor='#2e2e2e' overflowX='hidden'>
      <Flex direction='column' textAlign='center' alignItems='center'>
        <NavBarIcons
          menuItems={left}
          city={city}
          borderTopRightRadius={7}
          borderTopLeftRadius={7}
          mt={8}
        />

        <NavBarIcons menuItems={right} city={city} />
        <Divider borderColor='gray.400' />
        <Flex w='110%' ml={-4} height='45px'>
          <InfoLink
            transform='skewX(-30deg)'
            transformOrigin='bottom right'
            w='100%'
            p={2}
            mt='2px'
            boxShadow='0 0 0 0 rgba(255, 10, 10,0.5)'
            backgroundColor='rgb(56, 161, 105)'
            display={isLoggedIn ? 'none' : 'initial'}
            onClick={() => {
              if (setwhatsOpen) setwhatsOpen(AuthWhatsOpen.login);
              openAuthModal(disclosure);
              close();
            }}
          >
            <Text transform='skewX(30deg)' color='white'>
              Bejelentkezés
            </Text>
          </InfoLink>
          <InfoLink
            transform='skewX(-30deg)'
            transformOrigin='bottom right'
            display={isLoggedIn ? 'none' : 'initial'}
            w='100%'
            p={2}
            mt='2px'
            boxShadow='0 0 0 0 rgba(255, 10, 10,0.5)'
            backgroundColor='rgb(197, 48, 48)'
            onClick={() => {
              if (setwhatsOpen) setwhatsOpen(AuthWhatsOpen.register);
              openAuthModal(disclosure);
              close();
            }}
          >
            <Text transform='skewX(30deg)' color='white'>
              Regisztráció
            </Text>
          </InfoLink>
        </Flex>
        <InfoLink
          w='100%'
          p={2}
          mt='2px'
          boxShadow='0 0 0 0 rgba(255, 10, 10,0.5)'
          backgroundColor='rgb(197, 48, 48)'
          display={isLoggedIn ? 'initial' : 'none'}
          onClick={() => {
            if (setwhatsOpen) setwhatsOpen(AuthWhatsOpen.about);
            openAuthModal(disclosure);
            close();
          }}
        >
          <Text color='white'>Adataim</Text>
        </InfoLink>
      </Flex>
    </Card>
  );
}

function NavBarIcons({ menuItems, city, ...props }: NavBarIconsProps) {
  return (
    <Flex
      direction='row'
      height='100%'
      w='100%'
      justifyContent='space-around'
      display={['flex']}
      py={5}
      {...props}
    >
      {menuItems.map((item) => {
        const path = generatePath(item.path ? item.path : '/:city/:category', {
          city,
          category: item.pathName.toLowerCase(),
        });
        return (
          <NavRouterLink key={item.pathName} p={0} href={path}>
            <Flex
              direction='column'
              align='center'
              height='100%'
              justifyContent='space-between'
              px={1}
            >
              <Image
                height='40px'
                color='black'
                src={item.icon}
                alt={item.name}
              />
              <Text
                pt={4}
                color='white'
                fontSize={11}
                fontStyle='bold'
                textAlign='center'
                w='100%'
              >
                {item.name.toUpperCase()}
              </Text>
            </Flex>
          </NavRouterLink>
        );
      })}
    </Flex>
  );
}
