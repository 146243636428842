import { Box, Button, Flex, Grid, Skeleton } from '@chakra-ui/core';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import Card from '../components/Card';
import {
  creatorConnector,
  ReduxCreatorProps,
} from '../components/creator/CreatorConstants';
import PizzaSelector from '../components/creator/PizzaSelector';
import ToppingsSelector from '../components/creator/ToppingsSelector';
import { CITY_ID } from '../config/categories';
import { ICategory } from '../redux/products/ProductTypes';
import Navbar from '../components/navbar/Navbar';
import Footer from '../components/footer/Footer';

function CreatorScreen(reduxProps: ReduxCreatorProps): JSX.Element {
  const [toppingsOpen, setToppingsOpen] = useState(false);
  // category is not working here
  const { city, category, creatorName } = useParams<{
    city: string;
    category: string;
    creatorName: string;
  }>();

  const cityId = CITY_ID.find((e) => e.name === city);

  let creator: ICategory | undefined;

  useEffect(() => {
    if (reduxProps.categories.length === 0) {
      reduxProps.ProductActions.fetchAllCategory();
      reduxProps.ProductActions.fetchAllProduct();
    }
    if (cityId && creatorName)
      reduxProps.ProductActions.fetchCreator(cityId?.id, creatorName);
  }, [
    cityId,
    city,
    category,
    creatorName,
    reduxProps.ProductActions,
    reduxProps.categories.length,
  ]);

  if (reduxProps.creator) {
    creator = reduxProps.creator.childCategories.find(
      (c) => c.name === creatorName
    );
  }
  const router = useNavigate();

  if (city !== 'kecskemet' && city !== 'szolnok') {
    router('/', { replace: true });
    return <></>;
  }
  if (!creator || !reduxProps.creator)
    return (
      <>
        <Navbar />
        <Card w='100%' minH='700px'>
          <Grid
            color='black'
            gridTemplateColumns={['1fr', '1fr', '1fr', '7fr 3fr']}
            gridGap={4}
          >
            <Flex align='center' direction='column'>
              <Skeleton h='64px' w='80%' mb={8} />
              <Flex
                justifyContent='center'
                minW='100%'
                alignItems='center'
                borderRadius='32px 64px 0 32px'
                mb={4}
              >
                <Skeleton
                  width='100%'
                  height='32px'
                  borderRadius='32px 64px 0 32px'
                />
                <Skeleton
                  width='100%'
                  height='32px'
                  borderRadius='32px 64px 0 32px'
                />
                <Skeleton
                  width='100%'
                  height='32px'
                  borderRadius='32px 64px 0 32px'
                />
                <Skeleton
                  width='100%'
                  height='32px'
                  borderRadius='32px 64px 0 32px'
                />
              </Flex>
              <Skeleton h='400px' w='100%' mb={8} />
            </Flex>
            <Flex>
              <Skeleton h='250px' w='100%' />
            </Flex>
          </Grid>
        </Card>
        <Footer />
      </>
    );

  const pizzas = creator.items
    .filter((p) => p.isSoldSeparately)
    .sort((a, b) => a.name.localeCompare(b.name));

  return (
    <>
      <Navbar />
      <Box as='main'>
        <Flex position='relative' left='2rem' w='fit-content'>
          <Button
            minW='108px'
            p={1}
            mr={2}
            size='sm'
            borderRadius='8px 8px 0 0'
            backgroundColor={toppingsOpen ? 'black' : 'white'}
            color={toppingsOpen ? 'white' : 'black'}
            onClick={() => setToppingsOpen(false)}
            _hover={{ color: '#000000', backgroundColor: '#ffffff' }}
          >
            Tészták/alapok
          </Button>

          <Button
            minW='108px'
            p={1}
            size='sm'
            borderRadius='8px 8px 0 0'
            backgroundColor={toppingsOpen ? 'white' : 'black'}
            color={toppingsOpen ? 'black' : 'white'}
            onClick={() => setToppingsOpen(true)}
            _hover={{ color: '#000000', backgroundColor: '#ffffff' }}
          >
            Feltétek
          </Button>
        </Flex>
        <Card>
          <Box>
            <PizzaSelector
              reduxProps={reduxProps}
              pizzas={pizzas}
              creatorName={
                creatorName === 'default'
                  ? 'Extra pizza'
                  : `${creatorName} pizza`
              }
              display={toppingsOpen ? 'none' : 'grid'}
            />
            <ToppingsSelector
              reduxProps={reduxProps}
              creator={reduxProps.creator}
              creatorName={
                creatorName === 'default'
                  ? 'Extra pizza'
                  : `${creatorName} pizza`
              }
              pizzas={pizzas}
              display={toppingsOpen ? 'grid' : 'none'}
            />
          </Box>
        </Card>
      </Box>
      <Footer />
    </>
  );
}

export default creatorConnector(CreatorScreen);
