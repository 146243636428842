import Axios from 'axios';

import { INews, NewsActionType, NewsDispatchType } from './NewsTypes';

const NewsActions = (
  dispatch: (action: NewsDispatchType) => Record<string, unknown>,
): NewsActionType => {
  return {
    fetchHomeBackgrounds: async () => {
      return Axios.get(`/config`).then((res) => {
        dispatch({
          type: 'BACKGROUNDS_DOWNLOADED',
          payloadHomeBackgrounds: res.data,
        });
        return res.data;
      });
    },
    fetchNews: async () => {
      return Axios.get(`/news`).then((res) => {
        res.data.sort((a: INews, b: INews) => {
          return Date.parse(b.createdAt) - Date.parse(a.createdAt);
        });
        dispatch({
          type: 'NEWS_DOWNLOADED',
          payloadNews: res.data,
        });
        return res.data;
      });
    },
  };
};

export default NewsActions;
