import Axios from 'axios';

import { CATEGORY_CITY_ID } from '../../config/categories';
import {
  ICategory,
  IProduct,
  ProductActionType,
  ProductDispatchType,
} from './ProductTypes';

const ProductActions = (
  dispatch: (action: ProductDispatchType) => Record<string, unknown>
): ProductActionType => {
  return {
    fetchAllCategory: async (cityId?: number) => {
      return Axios.get(`/categories`).then((res) => {
        const categories = res.data as ICategory[];
        dispatch({
          type: 'CATEGORIES_DOWNLOADED',
          payloadCategories: categories,
        });

        if (cityId) {
          const creatorData = CATEGORY_CITY_ID.find(
            (e) => e.name === 'creator' && e.city.id === cityId
          )!;
          const creator = categories
            .find((c) => c.id === creatorData.city.id)
            ?.childCategories.find((cc) => cc.id === creatorData.id);

          dispatch({ type: 'CREATOR_DOWNLOADED', payloadCategory: creator });
        }
        return categories;
      });
    },
    fetchCategoryById: async (id: number) => {
      return id === -1
        ? dispatch({ type: 'PRODUCTS_DOWNLOADED', payloadProducts: [] })
        : Axios.get(`/categories/`, {
            params: { id },
          })
            .then((res) => {
              dispatch({
                type: 'CATEGORIES_DOWNLOADED',
                payloadCategories: res.data,
              });
              return res.data;
            })
            .then((cities: ICategory[]) => {
              const city = cities[0];
              let allItems: IProduct[] = [];
              allItems = allItems.concat(city.items);
              allItems = allItems.concat(
                city.childCategories.flatMap((c) => c.items)
              );
              allItems = allItems.concat(
                city.childCategories.flatMap((c) => {
                  return c.childCategories.flatMap((d) => d.items);
                })
              );
              dispatch({
                type: 'PRODUCTS_DOWNLOADED',
                payloadProducts: allItems,
              });
            });
    },
    fetchProductsByCategoryId: async (id: number) => {
      return id === -1
        ? dispatch({ type: 'PRODUCTS_DOWNLOADED', payloadProducts: [] })
        : Axios.get(`/categories/${id}/items`, {
            params: { parentCategoryId: id },
          }).then((res) =>
            dispatch({
              type: 'PRODUCTS_DOWNLOADED',
              payloadProducts: res.data,
            })
          );
    },
    fetchAllProduct: async () => {
      return Axios.get('/items').then((res) =>
        dispatch({ type: 'PRODUCTS_DOWNLOADED', payloadProducts: res.data })
      );
    },
    fetchCreator: async (city_id: number, name = 'default') => {
      const cat = CATEGORY_CITY_ID.find(
        (e) => e.name === 'creator' && e.city.id === city_id
      );
      const { data } = await Axios.get(`/categories/${cat!.id}`);

      return dispatch({ type: 'CREATOR_DOWNLOADED', payloadCategory: data });
    },
    modifyCreator: async (cityId: number, creation: IProduct) => {
      dispatch({ type: 'MODIFY_CREATOR', payloadProduct: creation });
      return Axios.post(
        '/cart',
        JSON.stringify({
          cityId,
          orderItems: [
            {
              itemId: creation.id,
              quantity: 1,
              itemContent: creation.contents.map((c) => ({
                itemId: c.item?.id,
                quantity: c.quantity,
              })),
            },
          ],
        })
      )
        .then((resp) => {
          dispatch({ type: 'PRICE_DOWNLOADED', priceData: resp.data });
        })
        .catch((error: any) => {
          if (error.errData) {
            throw new Error(error.errData.msg || error.errData);
          } else {
            throw new Error(error.message);
          }
        });
    },
    getItemPrice: async (
      cityId: number,
      product: IProduct,
      extraPrice: number
    ) => {
      return Axios.post(
        '/cart',
        JSON.stringify({
          cityId,
          orderItems: [
            {
              itemId: product.id,
              quantity: 1,
              extraPrice: extraPrice,
              itemContent: product.contents
                .filter((c) => c.item)
                .map((c) => ({
                  itemId: c.item?.id,
                  quantity: c.quantity,
                })),
            },
          ],
        })
      );
    },
  };
};

export default ProductActions;
