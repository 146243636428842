import {
  Box,
  BoxProps,
  Button,
  Checkbox,
  Flex,
  Heading,
  Image,
  Text,
  useToast,
} from '@chakra-ui/core';
import { createStore } from 'hooksy';
import React, { useState } from 'react';
import { FaShoppingCart } from 'react-icons/fa';
import { connect, ConnectedProps } from 'react-redux';
import { generatePath, useNavigate, useParams } from 'react-router-dom';

import { configFile } from '../../configFile';
import CartActions from '../../redux/cart/CartActions';
import { ICategory, IFile, IProduct } from '../../redux/products/ProductTypes';
import { RootState } from '../../redux/store';
import Card from '../Card';
import ItemAddedToCartToast from './ItemAddedToCartToast';

const defaultProduct: IProduct = {
  name: 'default',
  isModifiable: true,
  isSoldSeparately: true,
  isAvailable: true,
  contents: [],
  additionalItems: [],
  defaultContents: [],
  isVoucher: false,
  isRedemption: false,
};
export const [useModifyProductStore] = createStore(defaultProduct);

type ExtraPriceType = {
  lactoseFree28: number;
  lactoseFree52: number;
  fitness: number;
};

function Item({
  reduxProps,
  product,
  category,
  modalOpener,
  products,
  extraPrices,
  isMenu,
  ...restProps
}: {
  product?: IProduct;
  category?: ICategory;
  isPizza?: boolean;
  isDrink?: boolean;
  isMenu?: boolean;
  products?: any;
  extraPrices?: ExtraPriceType;
  reduxProps: ReduxProps;
  modalOpener: () => void;
} & BoxProps): JSX.Element {
  const { city } = useParams<{ city: string }>();
  const history = useNavigate();

  const toast = useToast();
  const [, setModifyProduct] = useModifyProductStore();

  const [type, setType] = useState('classic');
  const [size, setSize] = useState('28');
  const [lactoseFree, setLactoseFree] = useState(false);

  let name = '';
  let isPizza = false;
  let isDrink = false;
  let isVoucher = false;
  let isModifiable = false;
  let description = '';
  let picture: IFile = { id: -1, filePath: '', productId: -1 };
  let price = 0;
  if (product) {
    name = product.name;
    isPizza = false;
    isModifiable = product.isModifiable;
    description = product.description ?? '';
    picture = product.picture ?? { id: -1, filePath: '', productId: -1 };
    price = product.price ?? -1;
    // eslint-disable-next-line
    isVoucher = product.isVoucher;
  } else if (category) {
    name = category.name;
    isPizza = !!restProps.isPizza;
    isModifiable = category.items.some((i) => i.isModifiable);
    isDrink = !!restProps.isDrink;
    description = category.comment ?? '';
    picture = category.picture ?? { id: -1, filePath: '', productId: -1 };
  }
  const comparePizzaSize = (first: IProduct, second: IProduct) =>
    first.name.localeCompare(second.name);

  const calcExtraPrice = () => {
    let extra = 0;

    if (type === 'fitness') {
      extra += extraPrices.fitness;
    }
    if (lactoseFree && size === '28') {
      extra += extraPrices.lactoseFree28;
    }
    if (lactoseFree && size === '52') {
      extra += extraPrices.lactoseFree52;
    }

    return extra;
  };

  const date = new Date();
  const hour = date.getHours();
  const isAvailableForToday = isMenu && hour >= 15;

  if (isPizza) {
    category?.items.sort(comparePizzaSize);
  }

  return (
    <Card position='relative' p={3} {...restProps}>
      <Flex direction='column' justifyContent='space-between' h='100%'>
        <Flex direction='row' justifyContent='space-between'>
          <Heading as='h3' size='md' pb={4} mr={2}>
            {name}
          </Heading>
          {isPizza && isModifiable && (
            <Button
              variantColor='red'
              minW='90px'
              backgroundColor='#da1515'
              size='sm'
              onClick={() => {
                history(
                  generatePath('/:city/creator/:creatorName', {
                    city,
                    creatorName: name,
                  })
                );
              }}
            >
              Áttervezem
            </Button>
          )}
        </Flex>
        <Text fontSize='xs' pb={4}>
          {description}
        </Text>
        <Image
          maxH={['250px', '476px', '292px', '250px', '310px']}
          src={
            picture
              ? `${configFile.serverBaseUrlPicture}${picture?.filePath}`
              : `https://via.placeholder.com/150`
          }
          fallbackSrc='https://via.placeholder.com/150'
          borderRadius='md'
          mx='auto'
          mb={1}
          // Lazy loading is not typed in chakra 0.8 but this works
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          loading='lazy'
        />

        {(isPizza &&
          category?.items &&
          category?.parentCategoryId !== 593 &&
          category?.parentCategoryId !== 595 &&
          category?.parentCategoryId !== 725 &&
          category?.items.every((item) => item.additionalItems.length === 0) &&
          !category?.items.some((prod) => !!prod.isVoucher) && (
            <>
              {' '}
              <Box mt={6} gridColumn='colStart / colEnd'>
                <Checkbox
                  isChecked={lactoseFree}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setLactoseFree(e.target.checked);
                  }}
                  size='sm'
                  variantColor='green'
                >
                  <Text fontWeight='bold' color='#38a169'>
                    LAKTÓZMENTES SAJT
                  </Text>
                </Checkbox>
              </Box>
              <Flex justify='space-between' w='100%'>
                <Flex justify='space-between' flexDir='column' w='65%'>
                  <Flex w='100%' my={1}>
                    <Button
                      borderBottomRightRadius={0}
                      borderTopRightRadius={0}
                      border='1px solid lightgray'
                      bg={type === 'classic' ? '#da1515' : 'white'}
                      color={type === 'classic' ? 'white' : '#da1515'}
                      onClick={() => setType('classic')}
                      _hover={{ bg: type === 'classic' ? '#da1515' : 'white' }}
                      size='sm'
                      w='100%'
                    >
                      KLASSZIKUS
                    </Button>
                    <Button
                      borderBottomLeftRadius={0}
                      borderTopLeftRadius={0}
                      border='1px solid lightgray'
                      bg={type === 'fitness' ? '#38A169' : 'white'}
                      color={type === 'fitness' ? 'white' : '#38A169'}
                      _hover={{ bg: type === 'fitness' ? '#38A169' : 'white' }}
                      onClick={() => {
                        setType('fitness');
                        setSize('28');
                      }}
                      size='sm'
                      w='100%'
                    >
                      FITT
                    </Button>
                  </Flex>
                  <Flex w='100%'>
                    <Button
                      border='1px solid lightgray'
                      borderBottomRightRadius={0}
                      borderTopRightRadius={0}
                      size='sm'
                      fontSize='xd'
                      w='100%'
                      //@ts-ignore
                      leftIcon='human'
                      bg={size === '28' ? '#2E2E2E' : 'white'}
                      color={size === '28' ? 'white' : '#2E2E2E'}
                      _hover={{ bg: size === '28' ? '#2E2E2E' : 'white' }}
                      onClick={() => setSize('28')}
                    >
                      28 cm
                    </Button>
                    <Button
                      border='1px solid lightgray'
                      borderBottomLeftRadius={0}
                      borderTopLeftRadius={0}
                      size='sm'
                      fontSize='xd'
                      w='100%'
                      //@ts-ignore
                      leftIcon='people'
                      isDisabled={
                        type === 'fitness' ||
                        category?.items.length === 1 ||
                        category?.items[1].isAvailable === false
                      }
                      bg={size === '52' ? '#2E2E2E' : 'white'}
                      color={size === '52' ? 'white' : '#2E2E2E'}
                      _hover={{ bg: size === '52' ? '#2E2E2E' : 'white' }}
                      onClick={() => setSize('52')}
                    >
                      52 cm
                    </Button>
                  </Flex>
                </Flex>
                <Flex
                  align='center'
                  justify='space-between'
                  flexDir='column'
                  w='30%'
                >
                  <Text fontSize='xl'>
                    <b>
                      {size === '28'
                        ? calcExtraPrice() + category?.items[0].price
                        : calcExtraPrice() + category?.items[1].price}
                      Ft
                    </b>
                  </Text>
                  <Button
                    variantColor='green'
                    size='sm'
                    w='100%'
                    onClick={() => {
                      reduxProps.CartActions.addToCart(
                        category?.items[size === '28' ? 0 : 1],
                        calcExtraPrice()
                      );
                      toast({
                        duration: 3000,
                        position: 'top',
                        render: (onCloseToast) => (
                          <ItemAddedToCartToast
                            onCloseToast={onCloseToast}
                            history={history}
                            href={generatePath('/:city/cart', {
                              city,
                            })}
                          />
                        ),
                      });
                    }}
                  >
                    KOSÁRBA
                  </Button>
                </Flex>
              </Flex>
            </>
          )) ||
          (isPizza && category?.items && category?.items.length === 1 && (
            <Flex justify='end' w='100%'>
              <Button
                variantColor='green'
                size='sm'
                py={3}
                onClick={() => {
                  // no idea whats this
                  if (
                    category?.items[0].additionalItems.length !== 0 ||
                    category?.items[0].contents.length !== 0
                  ) {
                    setModifyProduct(category?.items[0]);
                    modalOpener();
                  } else {
                    reduxProps.CartActions.addToCart(category?.items[0], 0);
                    toast({
                      duration: 3000,
                      position: 'top',
                      render: (onCloseToast) => (
                        <ItemAddedToCartToast
                          onCloseToast={onCloseToast}
                          history={history}
                          href={generatePath('/:city/cart', {
                            city,
                          })}
                        />
                      ),
                    });
                  }
                }}
              >
                {category?.items[0].name} {category?.items[0].price} Ft
              </Button>
            </Flex>
          )) || (
            <Flex justify='flex-end' w='100%'>
              {(isDrink &&
                category?.items
                  .filter((e) => e.isAvailable)
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((item) => (
                    <Button
                      key={item.id}
                      variantColor='green'
                      size='sm'
                      mx={1}
                      leftIcon={FaShoppingCart}
                      onClick={() => {
                        // Drink buy button
                        if (item) {
                          if (item.additionalItems.length !== 0) {
                            setModifyProduct(item);
                            modalOpener();
                          } else {
                            reduxProps.CartActions.addToCart(item, 0);
                            toast({
                              duration: 3000,
                              position: 'top',
                              render: (onCloseToast) => (
                                <ItemAddedToCartToast
                                  onCloseToast={onCloseToast}
                                  history={history}
                                  href={generatePath('/:city/cart', {
                                    city,
                                  })}
                                />
                              ),
                            });
                          }
                        }
                      }}
                    >
                      {item.name} <br /> {item.price} Ft
                    </Button>
                  ))) || (
                <Button
                  variantColor='green'
                  size='sm'
                  leftIcon={FaShoppingCart}
                  isDisabled={isAvailableForToday}
                  onClick={() => {
                    // anything else buy button
                    if (product) {
                      if (
                        product.additionalItems.length !== 0 ||
                        product.contents.length !== 0
                      ) {
                        setModifyProduct(product);
                        modalOpener();
                      } else {
                        reduxProps.CartActions.addToCart(product, 0);
                        toast({
                          duration: 3000,
                          position: 'top',
                          render: (onCloseToast) => (
                            <ItemAddedToCartToast
                              onCloseToast={onCloseToast}
                              history={history}
                              href={generatePath('/:city/cart', {
                                city,
                              })}
                            />
                          ),
                        });
                      }
                    }
                  }}
                >
                  {price} Ft
                </Button>
              )}
            </Flex>
          )}
      </Flex>
    </Card>
  );
}

const mapStateToProps = (state: RootState) => {
  return {
    cartItems: state.cartReducer.cartItems,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    CartActions: CartActions(dispatch),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;
export default connector(Item);
