import { Box, Flex } from '@chakra-ui/core';
import React, { useEffect } from 'react';
import { authConnector, ReduxNavProps } from '../navbar/NavConstants';

import { FooterImages, FooterInfo, FooterLegal } from './FooterComponents';

function Footer(reduxProps: ReduxNavProps): JSX.Element {
  useEffect(() => {
    if (!reduxProps.homeBackgrounds[0]) {
      reduxProps.NewsActions.fetchHomeBackgrounds();
    }
    return () => {};
  }, [reduxProps.homeBackgrounds, reduxProps.NewsActions]);

  const imgsrcSzolnok = reduxProps.homeBackgrounds.find((e) =>
    e.key?.toLowerCase().includes('szolnokflyer')
  )?.picture?.filePath;

  const imgsrcKecskemet = reduxProps.homeBackgrounds.find((e) =>
    e.key?.toLowerCase().includes('kecskemetflyer')
  )?.picture?.filePath;

  return (
    <Box
      as='footer'
      maxW={['100%', '540px', '720px', '960px', '1140px']}
      flexShrink={0}
      zIndex={100}
      w='100%'
    >
      <Flex
        flexDir='column'
        justifyContent='space-between'
        alignItems='center'
        width='100%'
        py={8}
        mt={4}
      >
        <FooterImages
          images={{ szolnok: imgsrcSzolnok, kecskemet: imgsrcKecskemet }}
        />
        <FooterInfo />
        <FooterLegal />
      </Flex>
    </Box>
  );
}

export default authConnector(Footer);
