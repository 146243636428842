import { Box, Text, Input, Heading, Flex } from '@chakra-ui/core';
import { List } from '@chakra-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

import NewsActions from '../redux/news/NewsActions';
import { RootState } from '../redux/store';
import Navbar from '../components/navbar/Navbar';
import Footer from '../components/footer/Footer';

const PrivacyScreen = () => {
  const { city } = useParams<{ city: string }>();
  return (
    <>
      <Navbar />
      <Box as='main' mt={4} color='white'>
        <h1>Adatkezelési Tájékoztató</h1>
        <h2>1. A jelen adatkezelési tájékoztató célja:</h2>
        <p>
          1.1. A <a href='https://pizzasprint.hu/'>https://pizzasprint.hu/</a>{' '}
          URL alatt elérhető honlapot (a továbbiakban: „Honlap”) üzemeltető
          FORNO Szolgáltató és Kereskedelmi Kft. (székhely: 5008 Szolnok,
          Vörösmező út 170. a továbbiakban FORNO Szolgáltató és Kereskedelmi
          Kft., szolgáltató, vagy adatkezelő) mint adatkezelő, magára nézve
          kötelezőnek ismeri el a jelen tájékoztató tartalmát, és
          kötelezettséget vállal arra, hogy a tevékenységével kapcsolatos
          adatkezelés mindenkor megfeleljen a jelen tájékoztatóban és a hatályos
          nemzeti jogszabályokban, valamint az Európai Unió jogi aktusaiban
          meghatározott elvárásoknak.
        </p>
        <p>
          1.2. A FORNO Szolgáltató és Kereskedelmi Kft. adatkezelésével
          kapcsolatos adatvédelmi irányelvek folyamatosan elérhetők a{' '}
          <a href='https://pizzasprint.hu'>https://pizzasprint.hu</a> címen. A
          FORNO Szolgáltató és Kereskedelmi Kft. fenntartja magának a jogot a
          jelen tájékoztató tartalmának megváltoztatására, amelyről megfelelő
          időben értesíti a felhasználóit.
        </p>
        <p>
          1.3. A FORNO Szolgáltató és Kereskedelmi Kft. a személyes adatokat
          bizalmasan kezeli, és megtesz minden olyan biztonsági, technikai és
          szervezési intézkedést, mely az adatok biztonságát garantálja. A FORNO
          Szolgáltató és Kereskedelmi Kft. a jelen tájékoztatóban ismerteti
          adatkezelési gyakorlatát.
        </p>

        <h2>2. Az adatkezelő adatai:</h2>
        <p>
          Adatkezeléssel kapcsolatban a FORNO Szolgáltató és Kereskedelmi Kft
          -vel az alábbi elérhetőségen léphetnek kapcsolatba a felhasználók:
        </p>
        <ul>
          <li>Név: FORNO Szolgáltató és Kereskedelmi Kft</li>
          <li>Székhely: 5008 Szolnok, Vörösmező út 170.</li>
          <li>Cégjegyzékszám: 16-09-005638</li>
          <li>
            A bejegyző bíróság megnevezése: Szolnoki Törvényszék Cégbírósága
          </li>
          <li>Adószám: 11834984-2-16</li>
          <li>
            E-mail: <a href='mailto:info@pizzasprint.hu'>info@pizzasprint.hu</a>
          </li>
        </ul>

        <h2>3. A kezelt személyes adatok köre:</h2>
        <p>
          3.1. A FORNO Szolgáltató és Kereskedelmi Kft. tevékenysége során az
          érintetteknek a szolgáltatások igénybevételéhez szükséges személyes
          adatait kezeli, amelyről szóló tájékoztatást a szolgáltatás
          igénybevételének megkezdésekor ad meg teljes körűen.
        </p>
        <p>
          3.2. Technikai adatok: A FORNO Szolgáltató és Kereskedelmi Kft. a
          személyes adatok kezeléséhez a szolgáltatás nyújtása során alkalmazott
          informatikai eszközöket úgy választja meg és üzemelteti, hogy az
          adatkezelés alábbi elvei megvalósuljanak:
        </p>
        <ul>
          <li>
            a) kezelését jogszerűen és tisztességesen, valamint az érintett
            számára átlátható módon kell végezni („jogszerűség, tisztességes
            eljárás és átláthatóság”);
          </li>
          <li>
            b) gyűjtése csak meghatározott, egyértelmű és jogszerű célból
            történjen, és azokat ne kezeljék ezekkel a célokkal össze nem
            egyeztethető módon („célhoz kötöttség”);
          </li>
          <li>
            c) az adatkezelés céljai szempontjából megfelelőek és relevánsak
            kell, hogy legyenek, és a szükségesre kell korlátozódniuk
            („adattakarékosság”);
          </li>
          <li>
            d) pontosnak és szükség esetén naprakésznek kell lenniük; minden
            ésszerű intézkedést meg kell tenni annak érdekében, hogy az
            adatkezelés céljai szempontjából pontatlan személyes adatokat
            haladéktalanul töröljék vagy helyesbítsék („pontosság”);
          </li>
          <li>
            e) tárolásának olyan formában kell történnie, amely az érintettek
            azonosítását csak a személyes adatok kezelése céljainak eléréséhez
            szükséges ideig teszi lehetővé („korlátozott tárolhatóság”);
          </li>
          <li>
            f) kezelését oly módon kell végezni, hogy megfelelő technikai vagy
            szervezési intézkedések alkalmazásával biztosítva legyen a személyes
            adatok megfelelő biztonsága, az adatok jogosulatlan vagy jogellenes
            kezelésével, véletlen elvesztésével, megsemmisítésével vagy
            károsodásával szembeni védelmet is ideértve („integritás és bizalmas
            jelleg”).
          </li>
        </ul>
        <p>
          3.3. A FORNO Szolgáltató és Kereskedelmi Kft. az adatokat megfelelő
          intézkedésekkel védi a jogosulatlan hozzáférés, megváltoztatás,
          továbbítás, nyilvánosságra hozatal, törlés vagy megsemmisítés,
          valamint a véletlen megsemmisülés ellen. A FORNO Szolgáltató és
          Kereskedelmi Kft. olyan műszaki, szervezési és szervezeti
          intézkedésekkel gondoskodik az adatkezelés biztonságának védelméről,
          amely az adatkezeléssel kapcsolatban jelentkező kockázatoknak
          megfelelő védelmi szintet nyújt. A FORNO Szolgáltató és Kereskedelmi
          Kft. az adatkezelés során megőrzi a titkosságot: megvédi az
          információt, hogy csak az erre jogosult férhessen hozzá; megőrzi az
          adatok sértetlenséget: megvédi az információnak és a feldolgozás
          módszerének a pontosságát és teljességét; biztosítja a rendelkezésre
          állást: gondoskodik arról, hogy amikor a jogosult használónak szüksége
          van rá, valóban hozzá tudjon férni a kívánt információhoz, és
          rendelkezésre álljanak az ezzel kapcsolatos eszközök.
        </p>

        <h2>3.4. Cookie-k (Sütik):</h2>
        <p>
          3.4.1. A sütik információkat gyűjtenek a felhasználókról és
          eszközeikről; megjegyzik a felhasználók egyéni beállításait, amelyek
          felhasználásra kerül(het)nek pl. online tranzakciók igénybevételekor,
          így nem kell újra begépelni őket; megkönnyítik a szolgáltatás
          használatát; minőségi felhasználói élményt biztosítanak.
        </p>
        <p>
          3.4.2. A működéshez szigorúan szükséges sütik: Ezek a sütik a weboldal
          használatához szükségesek, például az oldalon történő navigáláshoz és
          a weboldal biztonságos területeihez való hozzáféréshez. Ezek nélkül a
          weboldal nem tud megfelelően működni. Ezek a sütik nem gyűjtenek a
          látogatóról olyan információkat, amelyek marketing célokra
          felhasználhatók, és nem jegyzik meg, hogy az internetező merre járt az
          interneten.
        </p>
        <p>
          3.4.3. A harmadik féltől származó sütik (analitika): A szolgáltató a
          weboldalán alkalmazza a Google Analytics mint harmadik fél sütijeit
          is. A Google Analytics statisztikai célú szolgáltatás használatával a
          FORNO Szolgáltató és Kereskedelmi Kft. információkat gyűjt azzal
          kapcsolatban, hogy a látogatók hogyan használják a weboldalakat. Az
          adatot a weboldal fejlesztése és a felhasználói élmény javítása
          érdekében használják fel. Ezek a sütik szintén nem tudják azonosítani
          személy szerint a látogatót, például azt is megmondják, hogy az adott
          látogató melyik oldalt nézte meg, mennyi időt töltött az oldalon,
          melyek voltak az egyes oldalak felkeresésének sorrendje.
        </p>

        <h2>4. Az adatkezelés célja, módja és jogalapja:</h2>
        <p>
          4.1. Általános adatkezelési irányelvek: A FORNO Szolgáltató és
          Kereskedelmi Kft. adatkezelései jogszerűen és tisztességesen, valamint
          átlátható módon történnek. A FORNO Szolgáltató és Kereskedelmi Kft.
          tevékenysége során az érintettek személyes adatainak kezelésére csak
          meghatározott célból kerül sor, az adatkezelés minden szakaszában
          annak a célnak megfelelően kell történnie.
        </p>

        <h2>5. Az adatkezelés jogalapja:</h2>
        <p>
          5.1. Az Európai Parlament és a Tanács (EU) 2016/679 rendelete (2016.
          április 27.) (általános adatvédelmi rendelet, GDPR) 6. cikk (1)
          bekezdés a) pontja szerint az érintett hozzájárulását adta személyes
          adatainak egy vagy több konkrét célból történő kezeléséhez;
        </p>
        <p>
          5.2. Az adatkezelés jogalapja lehet továbbá a GDPR 6. cikk (1)
          bekezdés b) pontja szerint az adatkezelés olyan szerződés
          teljesítéséhez szükséges, amelyben az érintett az egyik fél, vagy az a
          szerződés megkötését megelőzően az érintett kérésére történő lépések
          megtételéhez szükséges.
        </p>

        <h2>6. Az adatkezelés időtartama:</h2>
        <p>
          6.1. Az érintett által megadott személyes adatok kezelése az érintett
          hozzájárulásának visszavonásáig történik.
        </p>
        <p>
          6.2. Egyéb esetekben a FORNO Szolgáltató és Kereskedelmi Kft. az
          érintett személyes adatait az adatkezelési cél megvalósulásáig kezeli,
          amelyről az adatkezelés megkezdésekor az érintettet tájékoztatja.
        </p>

        <h2>7. Az érintett adatkezeléssel kapcsolatos jogai:</h2>
        <p>
          7.1. Az érintett tájékoztatást kérhet személyes adatai kezeléséről,
          valamint kérheti személyes adatainak helyesbítését, törlését vagy
          kezelésének korlátozását. Az érintett jogosult továbbá az
          adathordozhatósághoz való jogra és a hozzájárulás bármely időpontban
          történő visszavonására.
        </p>

        <h2>8. Adatbiztonság:</h2>
        <p>
          8.1. A FORNO Szolgáltató és Kereskedelmi Kft. kötelezi magát arra,
          hogy gondoskodik az adatok biztonságáról, továbbá megteszi azokat a
          technikai és szervezési intézkedéseket, valamint kialakítja azokat az
          eljárási szabályokat, amelyek az Info tv., valamint a GDPR
          rendelkezéseinek érvényre juttatásához szükségesek.
        </p>

        <h2>9. Jogorvoslat:</h2>
        <p>
          9.1. Az érintett jogorvoslattal élhet a felügyeleti hatóságnál:
          Nemzeti Adatvédelmi és Információszabadság Hatóság, 1125 Budapest,
          Szilágyi Erzsébet fasor 22/C., Postacím: 1530 Budapest, Pf.: 5.,
          Telefon: +36-1-391-1400, Fax: +36-1-391-1410, Email:
          ugyfelszolgalat@naih.hu
        </p>
      </Box>
      <Footer />
    </>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    allNews: state.newsReducer.newsData,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    NewsActions: NewsActions(dispatch),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(PrivacyScreen);
