import {
  Box,
  BoxProps,
  Flex,
  Image,
  Stack,
  Text,
  Button,
  useToast,
} from '@chakra-ui/core';
import React, { useEffect, useState } from 'react';
import { generatePath, useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import { configFile } from '../../configFile';
import Card from '../Card';
import ItemAddedToCartToast from '../item/ItemAddedToCartToast';
import { authConnector, ReduxNavProps } from '../navbar/NavConstants';
import { InfoRouterLink } from '../navbar/NavLinks';

function PayDeskPopup({
  reduxProps,
  close,
  ...props
}: BoxProps & {
  reduxProps: ReduxNavProps;
  close: (newStoreState: boolean) => void;
}): JSX.Element {
  const { city } = useParams<{ city: string }>();
  const [isCloseable, setIsCloseable] = useState(false);
  const toast = useToast();
  const history = useNavigate();
  useEffect(() => {
    if (!reduxProps.homeBackgrounds[0]) {
      reduxProps.NewsActions.fetchHomeBackgrounds();
    }
    return () => {};
  }, [reduxProps.homeBackgrounds, reduxProps.NewsActions]);

  const cityCat =
    city === 'kecskemet' ? reduxProps.categories[0] : reduxProps.categories[1];
  const dessertParentCat = cityCat.childCategories.find(
    (e) => e.name === 'Desszert'
  );

  const isDessertOrdered = reduxProps.cartItems.some(
    (item) => item.product.parentCategoryId === dessertParentCat?.id
  );
  const popupCat = cityCat.childCategories.find((e) => e.name === 'Ajánlat');
  const product = popupCat?.items[isDessertOrdered ? 1 : 0];

  const thankYouMsg = `Köszönöm,\n nem kérek többet`;
  return (
    <Card color='black' maxW='1200px' {...props}>
      <InfoRouterLink href={generatePath('/:city/dessert', { city })}>
        <Stack spacing={4} mt={2}>
          <Image
            src={`${configFile.serverBaseUrl}/files/${product.picture?.filePath}`}
            fallbackSrc='https://via.placeholder.com/700x389'
          />
        </Stack>
      </InfoRouterLink>
      <Flex w='100%' mt={3} justifyContent='space-between' height='70px'>
        <Button
          variantColor='red'
          whiteSpace='normal'
          w='100%'
          p={4}
          height='100%'
          onClick={() => {
            close(false);
            setIsCloseable(false);
          }}
        >
          <Text fontSize={20} fontWeight='bold'>
            {isCloseable ? thankYouMsg : 'Bezárás'}
          </Text>
        </Button>
        <Box w='10px'></Box>
        <Button
          variantColor='green'
          w='100%'
          p={4}
          height='100%'
          onClick={() => {
            reduxProps.CartActions.addToCart(product, 0);
            setIsCloseable(true);
            toast({
              duration: 3000,
              position: 'top',
              render: (onCloseToast) => (
                <ItemAddedToCartToast
                  onCloseToast={onCloseToast}
                  history={history}
                  href={generatePath('/:city/cart', {
                    city,
                  })}
                />
              ),
            });
          }}
        >
          <Text fontSize={20} fontWeight='bold'>
            Kosárba
          </Text>
        </Button>
      </Flex>
    </Card>
  );
}

export default authConnector(PayDeskPopup);
