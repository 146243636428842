import { Box, BoxProps } from '@chakra-ui/core';
import React from 'react';

export default function Card({ ...props }: BoxProps): JSX.Element {
  return (
    <Box
      shadow="0 8px 10px 0 rgba(10, 31, 68, 0.15), 0 0 1px 0 rgba(10, 31, 68, 0.1)"
      bg="white"
      borderRadius="8px"
      p={[3, 8]}
      {...props}
    />
  );
}
