import { OrderDispatchType, OrderState } from './OrderTypes';

const initState: OrderState = {
  orders: [],
};

const OrderReducer = (
  state = initState,
  action: OrderDispatchType,
): OrderState => {
  switch (action.type) {
    case 'ORDERS_DOWNLOADED':
      return {
        ...state,
        orders:
          action.payloadOrders && state.orders?.concat(action.payloadOrders),
      };
    case 'ORDER_STARTED_PREPARING':
      const oldItem = state.orders?.splice(
        state.orders?.indexOf(action.payloadStartedPreparing!),
        1,
      );
      oldItem![0].phase = 'UNDER_PREPARATION';
      const newOrders = state.orders?.concat(oldItem!);
      return {
        ...state,
        orders: newOrders,
      };
    default:
      return state;
  }
};
export default OrderReducer;
