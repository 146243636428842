import {
  AddressActionTypes,
  AddressDispatchType,
  IAddress,
} from './AddressTypes';

const AddressActions = (
  dispatch: (action: AddressDispatchType) => Record<string, unknown>,
): AddressActionTypes => {
  return {
    addAddress: (address: IAddress) => {
      dispatch({ type: 'ADD_ADDRESS', address });
      return { type: 'ADD_ADDRESS', address };
    },
    removeAddress: (address: IAddress) => {
      dispatch({ type: 'REMOVE_ADDRESS', address });
      return { type: 'REMOVE_ADDRESS', address };
    },
    modifyAddress: (address: IAddress) => {
      dispatch({ type: 'ADDRESS_MODIFICATION', address });
      return { type: 'ADDRESS_MODIFICATION', address };
    },
  };
};

export default AddressActions;
