import { connect, ConnectedProps } from 'react-redux';

import CartActions from '../../redux/cart/CartActions';
import OrderActions from '../../redux/orders/OrderActions';
import ProductActions from '../../redux/products/ProductActions';
import { RootState } from '../../redux/store';

export const toppings_meat = [
  { name: 'Bacon' },
  { name: 'Kolbász' },
  { name: 'Sonka' },
  { name: 'Tarja' },
  { name: 'Tenger gyümölcsei' },
  { name: 'Virsli' },
  { name: 'Csirkemell' },
  { name: 'Szalámi' },
  { name: 'Tonhal' },
  { name: 'Marhahús' },
];

export const sizes = [{ name: 'Normál 28cm' }, { name: 'Óriás 52cm' }];

export const sauces = [
  { name: 'Paradicsom' },
  { name: 'Tejföl' },
  { name: 'Barbecue' },
  { name: 'Sajtkrém' },
  { name: 'Gyros' },
];

const mapStateToProps = (state: RootState) => {
  return {
    products: state.productReducer.products,
    categories: state.productReducer.categories,
    creator: state.productReducer.creator,
    creation: state.productReducer.creation,
    cartItems: state.cartReducer.cartItems,
    creatorItemPrice: state.productReducer.priceData,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    ProductActions: ProductActions(dispatch),
    CartActions: CartActions(dispatch),
    OrderActions: OrderActions(dispatch),
  };
};

export const creatorConnector = connect(mapStateToProps, mapDispatchToProps);
export type ReduxCreatorProps = ConnectedProps<typeof creatorConnector>;
