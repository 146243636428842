import { CouponActionType, CouponDispatchType, ICoupon } from './CouponTypes';

export const CouponActions = (
  dispatch: (action: CouponDispatchType | any) => {}
): CouponActionType => {
  return {
    fetchCoupons: async () => {
      dispatch({ type: 'COUPONS_DOWNLOADED' });
    },
    addCoupon: async (coupon: string) => {
      dispatch({ type: 'COUPON_ADDED', payloadCoupon: coupon });
    },
    deleteCoupon: async (coupon: ICoupon) => {
      dispatch({ type: 'COUPON_DELETED', payloadCoupon: coupon });
    },
    modifyCoupon: async (coupon: ICoupon) => {
      dispatch({ type: 'COUPON_MODIFIED', payloadCoupon: coupon });
    },
  };
};
