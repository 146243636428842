import "pure-react-carousel/dist/react-carousel.es.css";

import {
  Box,
  BoxProps,
  Text,
  Flex,
  Image,
  Button,
  Icon,
} from "@chakra-ui/core";
/** @jsx jsx */
import { jsx, css, keyframes } from "@emotion/core";
import {
  ButtonBack,
  ButtonNext,
  CarouselProvider,
  Slide,
  Slider,
} from "pure-react-carousel";
import React, { useEffect } from "react";
import { generatePath, useLocation } from "react-router-dom";

import { configFile } from "../../configFile";
import AboutForm from "../auth/AboutForm";
import AddressForm from "../auth/AddressForm";
import ForgotForm from "../auth/ForgotForm";
import LoginForm from "../auth/LoginForm";
import ModifyForm from "../auth/ModifyForm";
import RegisterForm from "../auth/RegisterForm";
import PreviousOrderForm from "../auth/PreviousOrder/PreviousOrderForm";

import {
  AuthWhatsOpen,
  ReduxProps,
  SetAuthWhatsOpenProps,
} from "./HomeConstants";
import { TabButton } from "./TabButton";
import { NavRouterLink } from "../navbar/NavLinks";

const MOBILE_W = 600;

export function HomeForms({
  whatsOpen,
  setwhatsOpen,
  reduxProps,
  ...restProps
}: SetAuthWhatsOpenProps & { whatsOpen: AuthWhatsOpen } & {
  reduxProps: ReduxProps;
}): JSX.Element {
  const location = useLocation();
  const city = location.pathname.split("/")[1];

  const pulse = keyframes`
    0% {
      transform: scale(1.05);
    }
    70% {
      transform: scale(1);
      box-shadow: 0 0 0 15px rgba(255, 10, 10,0);
    }
      100% {
        transform: scale(1.05);
      box-shadow: 0 0 0 0 rgba(255, 10, 10,0);
    }
`;

  return (
    <Box
      zIndex={400}
      position={["absolute"]}
      right={["50%", "50%", "50%", "0"]}
      transform={[
        "translate(50%, 0)",
        "translate(50%, 0)",
        "translate(50%, 0)",
        "translate(0, 0)",
      ]}
      w={["100%", "350px"]}
      maxW={["350px"]}
      {...restProps}
    >
      <Flex position="relative" left="2rem" w="fit-content">
        <TabButton
          isShown={reduxProps.isLoggedIn}
          isOpen={[AuthWhatsOpen.about, AuthWhatsOpen.modify].includes(
            whatsOpen
          )}
          onClick={() => {
            if (setwhatsOpen) setwhatsOpen(AuthWhatsOpen.about);
          }}
        >
          Adataim
        </TabButton>
        <TabButton
          isShown={reduxProps.isLoggedIn}
          isOpen={whatsOpen === AuthWhatsOpen.previousOrders}
          onClick={() => {
            if (setwhatsOpen) setwhatsOpen(AuthWhatsOpen.previousOrders);
          }}
        >
          Korábbi rendeléseim
        </TabButton>
        <TabButton
          isShown={!reduxProps.isLoggedIn}
          isOpen={[AuthWhatsOpen.login, AuthWhatsOpen.forgot].includes(
            whatsOpen
          )}
          onClick={() => {
            if (setwhatsOpen) setwhatsOpen(AuthWhatsOpen.login);
          }}
        >
          Bejelentkezés
        </TabButton>
        <TabButton
          isShown={!reduxProps.isLoggedIn}
          isOpen={[AuthWhatsOpen.register].includes(whatsOpen)}
          onClick={() => {
            if (setwhatsOpen) setwhatsOpen(AuthWhatsOpen.register);
          }}
        >
          Regisztráció
        </TabButton>
      </Flex>
      <Box>
        <LoginForm
          reduxProps={reduxProps}
          setwhatsOpen={setwhatsOpen}
          display={whatsOpen === AuthWhatsOpen.login ? "block" : "none"}
        />
        <ForgotForm
          reduxProps={reduxProps}
          display={whatsOpen === AuthWhatsOpen.forgot ? "block" : "none"}
        />
        <RegisterForm
          reduxProps={reduxProps}
          setwhatsOpen={setwhatsOpen}
          display={whatsOpen === AuthWhatsOpen.register ? "block" : "none"}
        />
        <ModifyForm
          formToModify={{
            fullName: reduxProps.userInfo?.name ?? "",
            email: reduxProps.userInfo?.email ?? "",
            password: "",
            confirmPassword: "",
            phone: reduxProps.userInfo?.phone ?? "",
            birthday: reduxProps.userInfo?.birthday ?? "01/01",
            allergies: reduxProps.userInfo?.allergies ?? [],
          }}
          reduxProps={reduxProps}
          setwhatsOpen={setwhatsOpen}
          display={whatsOpen === AuthWhatsOpen.modify ? "block" : "none"}
        />
        <AboutForm
          reduxProps={reduxProps}
          setwhatsOpen={setwhatsOpen}
          display={whatsOpen === AuthWhatsOpen.about ? "block" : "none"}
        />
        <AddressForm
          reduxProps={reduxProps}
          setwhatsOpen={setwhatsOpen}
          display={whatsOpen === AuthWhatsOpen.address ? "block" : "none"}
        />
        <AddressForm
          reduxProps={reduxProps}
          setwhatsOpen={setwhatsOpen}
          modification
          display={whatsOpen === AuthWhatsOpen.addressmodify ? "block" : "none"}
        />
        <PreviousOrderForm
          reduxProps={reduxProps}
          setwhatsOpen={setwhatsOpen}
          modification
          display={
            whatsOpen === AuthWhatsOpen.previousOrders ? "block" : "none"
          }
        />
      </Box>
      {/*
      <NavRouterLink
        padding={0}
        href={generatePath('/:city/coupons', {
          city,
        })}
      >

        <Button
          justifyContent='center'
          alignItems='center'
          w='100%'
          borderRadius='6px'
          backgroundColor='rgb(218, 21, 21)'
          p={6}
          mt='10px'
          boxShadow='0 0 0 0 rgba(255, 10, 10,0.5)'
          css={css`
            animation: ${pulse} 1.5s infinite;
          `}
          _hover={{ animation: 'none' }}
        >
          <Text fontSize={26} fontWeight='bold'>
            Kuponok
          </Text>
        </Button>

      </NavRouterLink>        */}
    </Box>
  );
}
export function HomeBackgrounds({
  reduxProps,
  height,
  setHeight,
  ...restProps
}: BoxProps & {
  reduxProps: ReduxProps;
  setHeight: React.Dispatch<React.SetStateAction<number>>;
}): JSX.Element {
  const ref = React.useRef<HTMLDivElement>(null);
  const [vw, setVw] = React.useState(window.innerWidth);
  function HandleResize() {
    setVw(window.innerWidth);
  }

  const isMobile: boolean = vw <= MOBILE_W;
  useEffect(() => {
    window.addEventListener("resize", HandleResize);
    return () => {
      window.removeEventListener("resize", HandleResize);
    };
  }, []);

  useEffect(() => {
    if (ref && ref.current) {
      setHeight(ref.current.clientHeight);
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, ref.current?.clientHeight, vw]);

  return (
    <Box position="absolute" left={0} right={0} {...restProps} ref={ref}>
      <CarouselProvider
        naturalSlideWidth={16}
        naturalSlideHeight={vw <= 600 ? 30 : 9}
        touchEnabled
        dragEnabled
        totalSlides={
          reduxProps.homeBackgrounds?.filter((value) =>
            value.key?.includes(
              isMobile ? "mobile-homebg-kecskemet" : "desktop-homebg-kecskemet"
            )
          ).length
        }
        isPlaying
        interval={3500}
        infinite
      >
        {isMobile && (
          <Box
            px={4}
            position="absolute"
            zIndex={100}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            w="full"
            h="full"
          >
            <ButtonBack>
              <Icon name="arrow-left" color="white" size="25px" />
            </ButtonBack>
            <ButtonNext>
              <Icon name="arrow-right" color="white" size="25px" />
            </ButtonNext>
          </Box>
        )}
        <Slider>
          {reduxProps.homeBackgrounds
            ?.filter((value) =>
              value.key?.includes(
                isMobile
                  ? "mobile-homebg-kecskemet"
                  : "desktop-homebg-kecskemet"
              )
            )
            // @ts-ignore
            .sort((a, b) => a.key.slice(-1) - b.key.slice(-1))
            .map((element, i) => {
              return (
                <Slide
                  key={element.key}
                  index={i}
                  css={css`
                    * {
                      transition: all 1s;
                      opacity: 1;
                    }
                  `}
                >
                  <Image
                    key={element.key}
                    zIndex={90}
                    src={
                      element.picture
                        ? `${configFile.serverBaseUrlPicture}${element.picture?.filePath}`
                        : ``
                    }
                    fallbackSrc="https://via.placeholder.com/150"
                    w="100%"
                    h="auto"
                  />
                </Slide>
              );
            })}
        </Slider>
      </CarouselProvider>
    </Box>
  );
}

export function HomeBackgroundsSzolnok({
  reduxProps,
  height,
  setHeight,
  ...restProps
}: BoxProps & {
  reduxProps: ReduxProps;
  setHeight: React.Dispatch<React.SetStateAction<number>>;
}): JSX.Element {
  const ref = React.useRef<HTMLDivElement>(null);
  const [vw, setVw] = React.useState(window.innerWidth);
  function HandleResize() {
    setVw(window.innerWidth);
  }
  const isMobile: boolean = vw <= MOBILE_W;
  useEffect(() => {
    window.addEventListener("resize", HandleResize);
    return () => {
      window.removeEventListener("resize", HandleResize);
    };
  }, []);

  useEffect(() => {
    if (ref && ref.current) {
      setHeight(ref.current.clientHeight);
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, ref.current?.clientHeight, vw]);

  return (
    <Box position="absolute" left={0} right={0} {...restProps} ref={ref}>
      <CarouselProvider
        naturalSlideWidth={16}
        naturalSlideHeight={vw <= 600 ? 30 : 9}
        touchEnabled
        dragEnabled
        totalSlides={
          reduxProps.homeBackgrounds?.filter((value) =>
            value.key?.includes(
              isMobile ? "mobile-homebg-szolnok" : "desktop-homebg-szolnok"
            )
          ).length
        }
        isPlaying
        interval={3500}
        infinite
      >
        {isMobile && (
          <Box
            position="absolute"
            zIndex={100}
            display="flex"
            px={4}
            justifyContent="space-between"
            alignItems="center"
            w="full"
            h="full"
          >
            <ButtonBack>
              <Icon name="arrow-left" color="white" size="25px" />
            </ButtonBack>
            <ButtonNext>
              <Icon name="arrow-right" color="white" size="25px" />
            </ButtonNext>
          </Box>
        )}
        <Slider>
          {reduxProps.homeBackgrounds
            ?.filter((value) =>
              value.key?.includes(
                isMobile ? "mobile-homebg-szolnok" : "desktop-homebg-szolnok"
              )
            )
            // @ts-ignore
            .sort((a, b) => a.key.slice(-1) - b.key.slice(-1))
            .map((element, i) => {
              return (
                <Slide
                  key={element.key}
                  index={i}
                  css={css`
                    * {
                      transition: all 1s;
                      opacity: 1;
                    }
                  `}
                >
                  <Image
                    key={element.key}
                    objectFit="fill"
                    zIndex={90}
                    src={
                      element.picture
                        ? `${configFile.serverBaseUrlPicture}${element.picture?.filePath}`
                        : `https://via.placeholder.com/150`
                    }
                    fallbackSrc="https://via.placeholder.com/150"
                    w="100%"
                    h="auto"
                  />
                </Slide>
              );
            })}
        </Slider>
      </CarouselProvider>
    </Box>
  );
}
