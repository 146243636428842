import placeholder from '../../assets/creator/Ananász-min.png';
import bbq_alap from '../../assets/creator/bbq alap.png';
import paprika from '../../assets/creator/Paprika.png';
import paradicsom_alap from '../../assets/creator/Parialap.png';
import tejfol_alap from '../../assets/creator/Tejföl alap.png';
import teszta from '../../assets/creator/Tészta-min.png';
import cheese from '../../assets/creatorIcons/cheese.svg';
import meat from '../../assets/creatorIcons/meat.svg';
import other from '../../assets/creatorIcons/other.svg';
import sauces from '../../assets/creatorIcons/sauces.svg';
import size from '../../assets/creatorIcons/size.svg';
import vegetable from '../../assets/creatorIcons/vegetable.svg';
import ananasz from '../../assets/toppings/ananasz.png';
import bab from '../../assets/toppings/bab.png';
import bacon from '../../assets/toppings/bacon.png';
import borso from '../../assets/toppings/borso.png';
import brokkoli from '../../assets/toppings/brokkoli.png';
import citrom from '../../assets/toppings/citrom.png';
import csirke from '../../assets/toppings/csirke.png';
import feta from '../../assets/toppings/feta.png';
import gomba from '../../assets/toppings/gomba.png';
import gyros from '../../assets/toppings/gyros.png';
import hal from '../../assets/toppings/hal.png';
import jalapeno from '../../assets/toppings/jalapeno.png';
import kapribogyo from '../../assets/toppings/kapribogyo.png';
import kolbasz from '../../assets/toppings/kolbasz.png';
import kukorica from '../../assets/toppings/kukorica.png';
import lilahagyma from '../../assets/toppings/lilahagyma.png';
import mozzerella_sajt from '../../assets/toppings/mozzerella_sajt.png';
import oliva_bogyo from '../../assets/toppings/oliva_bogyo.png';
import oregano from '../../assets/toppings/oregano.png';
import paprika_chili from '../../assets/toppings/paprika_chili.png';
import paprika_pritamin from '../../assets/toppings/paprika_pritamin.png';
import paprika_zold from '../../assets/toppings/paprika_zold.png';
import paradicsom from '../../assets/toppings/paradicsom.png';
import sonka from '../../assets/toppings/sonka.png';
import szalami from '../../assets/toppings/szalami.png';
import tojas from '../../assets/toppings/tojas.png';
import uborka from '../../assets/toppings/uborka.png';
import virsli from '../../assets/toppings/virsli.png';
import voroshagyma from '../../assets/toppings/voroshagyma.png';

export const CREATOR_ICONS = {
  cheese,
  meat,
  other,
  sauces,
  size,
  vegetable,
};

export const CREATOR_IMAGES = {
  tészta: teszta,
  'fokhagymás tefölös alap': '',
  'barbeque alap': bbq_alap,
  'erős alap': paradicsom_alap,
  'tejfölös alap': tejfol_alap,
  'paradicsomos alap': paradicsom_alap,

  ananász: ananasz,
  bab,
  'bacon szalonna': bacon,
  'bolognai szósz': placeholder,
  borsó: borso,
  brokkoli,
  'chili paprika': paprika_chili,
  'chilis bab': bab,
  csirkemáj: csirke,
  citrom,
  csülök: csirke,
  'dupla sajt': mozzerella_sajt, // sdajt 2x
  'feta sajt': feta,
  fokhagyma: '', // nemlátszik
  'főtt tarja': sonka,
  'főtt tojás': tojas,
  'füstölt mozzarella': mozzerella_sajt,
  gomba,
  'gyros hús': gyros,
  hagyma: voroshagyma,
  jalapeno,
  juhtúró: feta,
  kapor: oregano,
  kapribogyó: kapribogyo,
  kolbász: kolbasz,
  kukorica,
  lilahagyma,
  'mozzarella sajt': mozzerella_sajt,
  olivabogyó: oliva_bogyo,
  oregánó: oregano,
  pizzasajt: mozzerella_sajt,
  'paradicsom szelet': paradicsom,
  'pfefferóni paprika': paprika,
  'pritamin paprika': paprika_pritamin,
  pulykamell: placeholder,
  sonka,
  szalámi: szalami,
  szardínia: hal,
  tejföl: placeholder,
  'tejfölös húsragu': placeholder,
  tonhal: hal,
  tojás: tojas,
  'tv paprika': paprika_zold,
  uborka,
  virsli,
  zöldpaprika: paprika_zold,
};
export const CREATOR_ORDER = {
  tészta: 1,
  'fokhagymás tefölös alap': 1,
  'barbeque alap': 1,
  'erős alap': 1,
  'tejfölös alap': 1,
  'paradicsomos alap': 1,

  ananász: 3,
  bab: 4,
  'bacon szalonna': 6,
  'bolognai szósz': 5,
  borsó: 3,
  brokkoli: 5,
  'chili paprika': 5,
  'chilis bab': 5,
  csirkemáj: 3,
  citrom: 3,
  csülök: 3,
  'dupla sajt': 7,
  'feta sajt': 6,
  fokhagyma: 1,
  'főtt tarja': 2,
  'főtt tojás': 5,
  'füstölt mozzarella': 6,
  gomba: 3,
  'gyros hús': 3,
  hagyma: 3,
  jalapeno: 5,
  juhtúró: 5,
  kapor: 5,
  kapribogyó: 5,
  kolbász: 6,
  kukorica: 3,
  lilahagyma: 3,
  'mozzarella sajt': 6,
  olivabogyó: 5,
  oregánó: 1,
  pizzasajt: 4,
  'paradicsom szelet': 5,
  'pfefferóni paprika': 5,
  'pritamin paprika': 5,
  pulykamell: 3,
  sonka: 2,
  szalámi: 6,
  szardínia: 5,
  tejföl: 6,
  'tejfölös húsragu': 5,
  tonhal: 3,
  tojás: 6,
  'tv paprika': 5,
  uborka: 3,
  virsli: 3,
  zöldpaprika: 5,
};
