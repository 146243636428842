import {
  Box,
  Button,
  Flex,
  FormControl,
  Stack,
  Heading,
  IconButton,
  Text,
  Image,
  useToast,
} from '@chakra-ui/core';
import React from 'react';

import Card from '../Card';
import { AuthWhatsOpen, SetAuthWhatsOpenProps } from '../home/HomeConstants';
import { authConnector, ReduxNavProps } from '../navbar/NavConstants';
import profileLogo from '../../assets/bi_person-circle.svg';
import phoneLogo from '../../assets/el_phone.svg';
import emailLogo from '../../assets/carbon_email.svg';

function AboutForm({
  reduxProps,
  setwhatsOpen,
  ...props
}: SetAuthWhatsOpenProps & { reduxProps: ReduxNavProps }): JSX.Element {
  const toast = useToast();

  function signOut() {
    reduxProps.AuthActions.signOut();
    if (setwhatsOpen) setwhatsOpen(AuthWhatsOpen.login);
    toast({
      title: 'Siker.',
      description: 'Sikeresen kilépett!',
      status: 'success',
      position: 'top',
      duration: 9000,
      isClosable: true,
    });
  }

  return (
    <Card color='black' maxW='350px' minW='0' {...props}>
      <FormControl>
        <Box w='100%'>
          <Heading as='h2' size='lg' textAlign='center'>
            Üdvözöljük
          </Heading>
          <Stack spacing={4} mt={3}>
            <Flex alignItems='center'>
              <Image src={profileLogo} h='30px' />
              <Text fontSize='lg' height='initial' ml={5} fontWeight='bold'>
                {reduxProps.userInfo?.name}
              </Text>
            </Flex>
            <Flex alignItems='center'>
              <Image src={emailLogo} h='30px' />
              <Text fontSize='lg' height='initial' ml={5} fontWeight='bold'>
                {reduxProps.userInfo?.email}
              </Text>
            </Flex>
            <Flex alignItems='center'>
              <Image src={phoneLogo} h='30px' />
              <Text fontSize='lg' height='initial' ml={5} fontWeight='bold'>
                {reduxProps.userInfo?.phone}
              </Text>
            </Flex>
            <Flex justify='space-between' alignItems='center'>
              <Text fontSize='lg' height='initial' fontWeight='bold'>
                Hűségpontjaim: {reduxProps.userInfo?.loyaltyPoint}
              </Text>
              <Button
                gridColumn='col2 / colEnd'
                size='sm'
                variantColor='red'
                onClick={signOut}
              >
                Kilépés
              </Button>
            </Flex>
          </Stack>

          <Heading gridColumn='colStart / colEnd' as='h4' size='sm' mt={4}>
            Szállítási cím:
          </Heading>

          {reduxProps.userInfo?.addresses?.map((e, i) => {
            return (
              <React.Fragment key={e.zip + e.city + e.street + i.toString()}>
                <Flex flexDirection='row' justifyContent='space-between' mb={3}>
                  <Text
                    gridColumn='colStart / col2'
                    alignSelf='center'
                    maxW='70%'
                  >
                    {e.zip} {e.city}, {e.street} {e.number}, {e.floorDoor}
                  </Text>
                  <Flex gridColumn='col2 / colEnd' justifyContent='flex-end'>
                    <IconButton
                      mr={2}
                      icon='edit'
                      size='sm'
                      variant='ghost'
                      aria-label='Szerkesztés'
                      onClick={() => {
                        reduxProps.AddressActions.modifyAddress(e);
                        if (setwhatsOpen)
                          setwhatsOpen(AuthWhatsOpen.addressmodify);
                      }}
                    />
                    <IconButton
                      icon='delete'
                      size='sm'
                      variant='ghost'
                      variantColor='red'
                      aria-label='Törlés'
                      onClick={() => {
                        const remainingAddresses = reduxProps.userInfo?.addresses?.filter(
                          (x) => {
                            return x !== e;
                          }
                        );
                        reduxProps.AuthActions.removeAddress(
                          remainingAddresses || []
                        ).then(() => {
                          reduxProps.AuthActions.getUserInfo();
                        });
                      }}
                    />
                  </Flex>
                </Flex>
              </React.Fragment>
            );
          })}

          <Flex />
          <Flex justifyContent='flex-end'>
            <Button
              size='sm'
              variantColor='red'
              mr={3}
              onClick={() => {
                if (setwhatsOpen) setwhatsOpen(AuthWhatsOpen.modify);
              }}
            >
              Adatmódosítás
            </Button>
            <Button
              size='sm'
              variantColor='green'
              onClick={() => {
                if (setwhatsOpen) setwhatsOpen(AuthWhatsOpen.address);
              }}
            >
              Új cím
            </Button>
          </Flex>
        </Box>
      </FormControl>
    </Card>
  );
}

export default authConnector(AboutForm);
