import {
  Box,
  Button,
  Flex,
  Heading,
  Icon,
  IconButton,
  Text,
} from '@chakra-ui/core';
import React from 'react';

export default function ItemAddedToCartToast({
  onCloseToast,
  history,
  href,
}: {
  onCloseToast: {
    onClose: () => void;
    id: string;
  };
  href: string;
  history: any;
}): JSX.Element {
  return (
    <Flex
      m='0.5rem'
      pl='1rem'
      pr='2rem'
      py='0.75rem'
      color='white'
      bg='green.500'
      borderRadius='0.25rem'
      textAlign='left'
      position='relative'
      alignItems='start'
      overflow='hidden'
      boxShadow='0 10px 15px -3px rgba(0,0,0,0.1), 0 4px 6px -2px rgba(0,0,0,0.05)'
    >
      <Icon size='1.5em' mr={4} name='check-circle' />

      <Button
        variant='link'
        textAlign='left'
        variantColor='white.500'
        onClick={() => history(href)}
      >
        <Box flex='1'>
          <Heading size='sm'>Siker</Heading>
          <Text>Termék a kosárba helyezve.</Text>
        </Box>
      </Button>
      <IconButton
        aria-label='Close'
        position='absolute'
        top='4px'
        right='4px'
        size='xs'
        variant='ghost'
        icon='close'
        onClick={onCloseToast.onClose}
      />
    </Flex>
  );
}
