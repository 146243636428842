import { Box, Text } from '@chakra-ui/core';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useParams } from 'react-router-dom';

import aboutUsTexts from '../assets/aboutUsTexts';
import Card from '../components/Card';
import NewsActions from '../redux/news/NewsActions';
import { RootState } from '../redux/store';
import Navbar from '../components/navbar/Navbar';
import Footer from '../components/footer/Footer';

const AboutUsScreen = (reduxProps: ReduxProps) => {
  const { city } = useParams<{ city: string }>();

  return (
    <>
      <Navbar />
      <Box as='main' mt={4} color='black'>
        <Card shadow='2px 2px 2px 0px rgba(0,0,0,0.75)'>
          <Text style={{ whiteSpace: 'pre-line' }}>
            {city === 'szolnok'
              ? aboutUsTexts.szolnokMain
              : aboutUsTexts.kecskemetMain}
          </Text>
          <Text style={{ whiteSpace: 'pre-line' }}>
            {'\n\nRólunk írták...\n\n'}
          </Text>
          <Text style={{ whiteSpace: 'pre-line' }}>
            {city === 'szolnok'
              ? aboutUsTexts.szolnokComments
              : aboutUsTexts.kecskemetComment}
          </Text>
        </Card>
      </Box>
      <Footer />
    </>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    allNews: state.newsReducer.newsData,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    NewsActions: NewsActions(dispatch),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof connector>;
export default connector(AboutUsScreen);
