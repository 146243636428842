import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  Grid,
  Heading,
  Input,
  Text,
  useToast,
} from '@chakra-ui/core';
import React, { useState } from 'react';
import ReactFacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { GoogleLogin } from 'react-google-login';
import { FaFacebook, FaGoogle } from 'react-icons/fa';

import { configFile } from '../../configFile';
import { LoginFormData } from '../../redux/auth/AuthTypes';
import Card from '../Card';
import { AuthFormProps, AuthWhatsOpen } from '../home/HomeConstants';
import { authConnector } from '../navbar/NavConstants';

const defaultFormData: LoginFormData = {
  email: '',
  password: '',
  isStayLoggedIn: false,
};

function LoginForm({
  reduxProps,
  setwhatsOpen,
  showHeading,
  ...props
}: AuthFormProps): JSX.Element {
  const toast = useToast();
  const [formData, setFormData] = useState<LoginFormData>(defaultFormData);
  let wasError = false;

  async function signIn() {
    const promise = reduxProps.AuthActions.signIn(formData).catch(
      (error: Error) => {
        wasError = true;
        toast({
          title: 'Error',
          description: error.message,
          status: 'error',
          position: 'top',
          duration: 9000,
          isClosable: true,
        });
      }
    );

    await promise.then(() => {
      if (setwhatsOpen && !wasError) setwhatsOpen(AuthWhatsOpen.about);
      reduxProps.AuthActions.getUserInfo();
    });
  }
  async function signInWithFacebook(response: any) {
    const promise = reduxProps.AuthActions.signInWithFacebook(
      response.accessToken,
      response.refreshToken
    ).catch((error: Error) => {
      wasError = true;
      toast({
        title: 'Error',
        description: error.message,
        status: 'error',
        position: 'top',
        duration: 9000,
        isClosable: true,
      });
    });

    await promise.then(() => {
      if (setwhatsOpen && !wasError) setwhatsOpen(AuthWhatsOpen.about);
      reduxProps.AuthActions.getUserInfo();
    });
  }
  async function signInWithGoogle(response: any) {
    const promise = reduxProps.AuthActions.signInWithGoogle(
      response.tokenId
    ).catch((error: Error) => {
      wasError = true;
      toast({
        title: 'Error',
        description: error.message,
        status: 'error',
        position: 'top',
        duration: 9000,
        isClosable: true,
      });
    });

    await promise.then(() => {
      if (setwhatsOpen && !wasError) setwhatsOpen(AuthWhatsOpen.about);
      reduxProps.AuthActions.getUserInfo();
    });
  }

  return (
    <Card color='black' maxW='350px' {...props}>
      {showHeading && (
        <Heading size='md' mb={4}>
          Belépés
        </Heading>
      )}
      <FormControl>
        <Grid
          as='form'
          onSubmit={(e) => {
            e.preventDefault();
            signIn();
          }}
          gridTemplateColumns='[colStart] 1fr [col2] 1fr [colEnd]'
          gridGap={2}
        >
          <Input
            gridColumn='colStart / colEnd'
            color='black'
            type='email'
            name='email'
            autoComplete='email'
            placeholder='E-mail cím'
            size='sm'
            isRequired
            backgroundColor='gray.300'
            _placeholder={{ color: 'black' }}
            value={formData.email}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFormData({ ...formData, email: e.target.value });
            }}
          />
          <Input
            gridColumn='colStart / colEnd'
            type='password'
            name='password'
            autoComplete='current-password'
            placeholder='Jelszó'
            size='sm'
            isRequired
            backgroundColor='gray.300'
            _placeholder={{ color: 'black' }}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFormData({ ...formData, password: e.target.value });
            }}
          />
          <Checkbox
            gridColumn='colStart / colEnd'
            variantColor='darkbutton'
            isChecked={formData.isStayLoggedIn}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setFormData({ ...formData, isStayLoggedIn: e.target.checked })
            }
          >
            Maradj belépve!
          </Checkbox>
          <Button
            gridColumn='colStart / colEnd'
            variantColor='green'
            type='submit'
          >
            Belépés
          </Button>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <Text
            gridColumn='colStart / col2'
            fontSize='xs'
            mr={3}
            cursor='pointer'
            onClick={() => {
              if (setwhatsOpen) setwhatsOpen(AuthWhatsOpen.forgot);
            }}
          >
            Elfelejtetted a jelszavad?
          </Text>

          <Button
            size='sm'
            gridColumn='col2 / colEnd'
            variantColor='darkbutton'
            onClick={() => {
              if (setwhatsOpen) setwhatsOpen(AuthWhatsOpen.register);
            }}
          >
            Regisztráció
          </Button>

          <Divider gridColumn='colStart / colEnd' />

          <Box gridColumn='colStart / colEnd' mt={2}>
            <ReactFacebookLogin
              appId={configFile.facebookAppId}
              autoLoad={false}
              fields='name,email'
              callback={signInWithFacebook}
              isMobile={false}
              render={(renderProps: any) => (
                <Button
                  w='100%'
                  variantColor='blue'
                  onClick={renderProps.onClick}
                  leftIcon={FaFacebook}
                >
                  Facebook belépés
                </Button>
              )}
            />
          </Box>
          <Box gridColumn='colStart / colEnd' mt={2}>
            <GoogleLogin
              clientId='1052827844210-2hlk2hs1lugbgc53i059b4piabvm60a8.apps.googleusercontent.com'
              render={(renderProps) => (
                <Button
                  w='100%'
                  variantColor='red'
                  onClick={renderProps.onClick}
                  leftIcon={FaGoogle}
                >
                  Google belépés
                </Button>
              )}
              buttonText='Login'
              onSuccess={signInWithGoogle}
              cookiePolicy='single_host_origin'
            />
          </Box>
        </Grid>
      </FormControl>
    </Card>
  );
}

export default authConnector(LoginForm);
