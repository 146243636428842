import { BoxProps } from '@chakra-ui/core';
import { UseDisclosureReturn } from '@chakra-ui/core/dist/useDisclosure';
import { createStore } from 'hooksy';
import { connect, ConnectedProps } from 'react-redux';

import creatorIcon_red from '../../assets/navBarIcons/creator_red.png';
import creatorIcon from '../../assets/navBarIcons/creator.png';
import dessertIcon_red from '../../assets/navBarIcons/dessert_red.png';
import dessertIcon from '../../assets/navBarIcons/dessert.png';
import drinkIcon_red from '../../assets/navBarIcons/drinks_red.png';
import drinkIcon from '../../assets/navBarIcons/drinks.png';
import gyrosIcon_red from '../../assets/navBarIcons/gyros_red.png';
import gyrosIcon from '../../assets/navBarIcons/gyros.png';
import meatIcon_red from '../../assets/navBarIcons/meat_red.png';
import meatIcon from '../../assets/navBarIcons/meat.png';
import pastaIcon_red from '../../assets/navBarIcons/pasta_red.png';
import pastaIcon from '../../assets/navBarIcons/pasta.png';
import pizzaIcon_red from '../../assets/navBarIcons/pizza_red.png';
import pizzaIcon from '../../assets/navBarIcons/pizza.png';
import Burger from '../../assets/navBarIcons/burger.png';
import BergerRed from '../../assets/navBarIcons/burger-red.png';
import Menu from '../../assets/navBarIcons/menu.png';
import MenuRed from '../../assets/navBarIcons/menu-red.png';
import saladIcon_red from '../../assets/navBarIcons/salad_red.png';
import saladIcon from '../../assets/navBarIcons/salad.png';
import AddressActions from '../../redux/addresses/AddressActions';
import AuthActions from '../../redux/auth/AuthActions';
import CartActions from '../../redux/cart/CartActions';
import NewsActions from '../../redux/news/NewsActions';
import { RootState } from '../../redux/store';

export const burger = [
  {
    name: 'Burger',
    pathName: 'hamburger',
    icon: Burger,
    icon_red: BergerRed,
  },
];

export const menu_left = [
  {
    name: 'Pizza',
    pathName: 'pizza',
    icon: pizzaIcon,
    icon_red: pizzaIcon_red,
  },
  {
    name: 'Gyros',
    pathName: 'gyros',
    icon: gyrosIcon,
    icon_red: gyrosIcon_red,
  },
  {
    name: 'Frissensült',
    pathName: 'freshlybaked',
    icon: meatIcon,
    icon_red: meatIcon_red,
  },
  {
    name: 'Tészta',
    pathName: 'pasta',
    icon: pastaIcon,
    icon_red: pastaIcon_red,
  },
];
export const menu_right = [
  {
    name: 'Saláta',
    pathName: 'salad',
    icon: saladIcon,
    icon_red: saladIcon_red,
  },
  {
    name: 'Desszert',
    pathName: 'dessert',
    icon: dessertIcon,
    icon_red: dessertIcon_red,
  },
  {
    name: 'Italok',
    pathName: 'drink',
    icon: drinkIcon,
    icon_red: drinkIcon_red,
  },
  {
    name: 'Extra',
    pathName: 'creator',
    icon: creatorIcon,
    icon_red: creatorIcon_red,
    path: '/:city/:category/default',
  },
];

export const mobile_menu1 = [
  {
    name: 'Pizza',
    pathName: 'pizza',
    icon: pizzaIcon,
    icon_red: pizzaIcon_red,
  },
  {
    name: 'Gyros',
    pathName: 'gyros',
    icon: gyrosIcon,
    icon_red: gyrosIcon_red,
  },
  {
    name: 'Frissensült',
    pathName: 'freshlybaked',
    icon: meatIcon,
    icon_red: meatIcon_red,
  },
];
export const mobile_menu2 = [
  {
    name: 'Tészta',
    pathName: 'pasta',
    icon: pastaIcon,
    icon_red: pastaIcon_red,
  },
  {
    name: 'Desszert',
    pathName: 'dessert',
    icon: dessertIcon,
    icon_red: dessertIcon_red,
  },
  {
    name: 'Italok',
    pathName: 'drink',
    icon: drinkIcon,
    icon_red: drinkIcon_red,
  },
];
export const mobile_menu3 = [
  {
    name: 'Italok',
    pathName: 'drink',
    icon: drinkIcon,
    icon_red: drinkIcon_red,
  },
  {
    name: 'Extra Pizza',
    pathName: 'creator',
    icon: creatorIcon,
    icon_red: creatorIcon_red,
    path: '/:city/:category/default',
  },
];

export interface NavBarIconsProps extends BoxProps {
  menuItems: {
    name: string;
    pathName: string;
    icon: string;
    path?: string;
    icon_red?: string;
  }[];
  city: string;
}

export function openAuthModal(disclosure: UseDisclosureReturn): void {
  disclosure.onOpen();
}

type PopupType = string;
const defaultPopup: PopupType = 'exit';
export const [usePopupStore] = createStore(defaultPopup);

type PopupOpenType = boolean;
const defaultOpenPopup: PopupOpenType = false;
export const [useOpenPopupStore] = createStore(defaultOpenPopup);

const mapStateToProps = (state: RootState) => {
  return {
    isLoggedIn: state.authReducer.isLoggedIn,
    categories: state.productReducer.categories,
    userInfo: state.authReducer.userInfo,
    cartItems: state.cartReducer.cartItems,
    homeBackgrounds: state.newsReducer.homeBackgrounds,
    modifyAddress: state.addressReducer.currentlyModifiedAddress,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    AuthActions: AuthActions(dispatch),
    CartActions: CartActions(dispatch),
    NewsActions: NewsActions(dispatch),
    AddressActions: AddressActions(dispatch),
  };
};

export const authConnector = connect(mapStateToProps, mapDispatchToProps);
export type ReduxNavProps = ConnectedProps<typeof authConnector>;
