const aboutUsTexts = {
  szolnokMain: `Már lassan negyed évszázada élvezhetik szolnoki és kecskeméti megrendelőink a Pizza Sprinttől a megszokott ízeket. Ha ezt a nevet bárki olvassa, akkor szeretnénk, ha tudná, hogy ez a mi brandünk, ami védjegy oltalom alatt áll.

Fő profilunk a kövön sült pizzák, olasz tészták elkészítése. Mindenki megtalálja a kedvencét kínált ételeink között, legyen az paradicsomos, fokhagymás, sajtos vagy éppen bazsalikomos étel.

Legyen a vendégünk egy pizzára, kóstoljon bele a mi ízvilágunkba! Ha Szolnokon vagy Kecskeméten jár, akkor jusson eszébe a Pizza Sprint! Igyekszünk meggyőzni Önt arról, hogy megérte minket választania!
`,

  szolnokComments: `M. Bernadett:
Máté gyermekem rajong a dupla sajtos sonkás-kukoricás pizzátokért. Hogy csináljátok? 😊


D. Zita:
Kollégáimmal heti rendszerességgel rendelünk tőletek ebédet és desszertet a munkahelyünkre. Az olasz csokoládétorta az egyik kedvencünk, főételként pedig a sprint pizza sosem marad ki a választásból.


V. Imre:
Szolnok legjobb pizzáját tőletek rendelem!
`,

  kecskemetMain: `Már lassan negyed évszázada élvezhetik szolnoki és kecskeméti megrendelőink a Pizza Sprinttől a megszokott ízeket. Ha ezt a nevet bárki olvassa, akkor szeretnénk, ha tudná, hogy ez a mi brandünk, ami védjegy oltalom alatt áll.


Fő profilunk a kövön sült pizzák, olasz tészták elkészítése. Mindenki megtalálja a kedvencét kínált ételeink között, legyen az paradicsomos, fokhagymás, sajtos vagy éppen bazsalikomos étel.


Legyen a vendégünk egy pizzára, kóstoljon bele a mi ízvilágunkba! Ha Szolnokon vagy Kecskeméten jár, akkor jusson eszébe a Pizza Sprint! Igyekszünk meggyőzni Önt arról, hogy megérte minket választania!
`,
  kecskemetComment: `
L. László:
Én pizza fan vagyok, és ezek a pizzák brutálisan jók!

István:
A füstölt pizza verhetetlen. Tartsátok meg jó szokásotokat, és mindig maradjon a választék között. A haverokkal mindig ezt rendeljük…

D. Márta:
Sokszor látogattunk már el a gyerekekkel az étterembe, és sűrűn igénybe vesszük a házhozszállítást is. Kedves kiszolgálás, finom étel vár ránk minden alkalommal. Csak ajánlani tudom a Pizza Sprintet!
`,
};

export default aboutUsTexts;
