import { IProduct } from '../products/ProductTypes';
import { CartActionType, CartDispatchType, UserFormData } from './CartTypes';

const CartActions = (
  dispatch: (action: CartDispatchType) => Record<string, unknown>
): CartActionType => {
  return {
    addToCart: (product: IProduct, extraPrice) => {
      dispatch({ type: 'ADD_TO_CART', product, extraPrice });
      return { type: 'ADD_TO_CART', product, extraPrice };
    },
    removeItem: (product: IProduct) => {
      dispatch({ type: 'REMOVE_ITEM', product });
      return { type: 'REMOVE_ITEM', product };
    },
    addQuantity: (product: IProduct) => {
      dispatch({ type: 'ADD_QUANTITY', product });
      return { type: 'ADD_QUANTITY', product };
    },
    subtractQuantity: (product: IProduct) => {
      dispatch({ type: 'SUB_QUANTITY', product });
      return { type: 'SUB_QUANTITY', product };
    },
    emptyCart: () => {
      dispatch({ type: 'EMPTY_CART', product: undefined });
      return { type: 'EMPTY_CART', product: undefined };
    },
    saveFormData: (data: UserFormData) => {
      dispatch({ type: 'SAVE_DATA', data });
      return { type: 'SAVE_DATA', data };
    },
    emptyData: () => {
      dispatch({ type: 'EMPTY_DATA', data: undefined });
      return { type: 'EMPTY_DATA', data: undefined };
    },
  };
};

export default CartActions;
