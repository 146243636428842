/** @jsx jsx */

import { Button, Flex, Heading, Image } from '@chakra-ui/core';
import { jsx, css, keyframes } from '@emotion/core';

import { configFile } from '../../configFile';
import Card from '../Card';

const pulse = keyframes`
0% {
  transform: scale(1.02);
}
25% {
  transform: scale(1);
  box-shadow: 0 0 0 15px rgba(255, 10, 10,0);
}
50% {
  transform: scale(1);
  box-shadow: 0 0 0 10px rgba(255, 10, 10,0);
}
75% {
  transform: scale(1);
  box-shadow: 0 0 0 5px rgba(255, 10, 10,0);
}
  100% {
    transform: scale(1.02);
  box-shadow: 0 0 0 0 rgba(255, 10, 10,0);
}
`;
export default function PromotionCard({
  item,
  cartActions,
  ...props
}: any): JSX.Element {
  const getItemImg = () => {
    let img = `https://via.placeholder.com/150`;
    if (item?.picture) {
      img = `${configFile.serverBaseUrl}/files/${item?.picture?.filePath}`;
    }
    return img;
  };

  if (!item) {
    return <div></div>;
  }

  return (
    <Card
      gridColumn='1 / 6'
      p='10px 6px'
      h='fit-content'
      w='100%'
      boxShadow='0px 4px 6px 0 #c53730, 0 0 1px 0 #c53730'
      css={css`
        animation: ${pulse} 4s infinite;
      `}
      _hover={{ animation: 'none' }}
      {...props}
    >
      <Heading fontSize='xl' textAlign='center'>
        Promóciós ajánlat
      </Heading>
      <Flex w='100%' justify='space-between' alignItems='center' px={[4, 12]}>
        <Image
          maxH={100}
          w='auto'
          borderRadius='md'
          src={getItemImg()}
          fallbackSrc='https://via.placeholder.com/150'
        />
        <Flex
          direction={['column', 'column', 'column', 'row']}
          justify={['center', 'space-between']}
          align={['center', 'space-between']}
        >
          <Heading
            fontSize={['sm', 'md']}
            textAlign='center'
            mb={2}
            color=''
            verticalAlign='middle'
          >
            {`${item?.name}`}
          </Heading>
          <Heading
            verticalAlign='middle'
            fontSize={['sm', 'md']}
            textAlign='center'
            mb={2}
            color='red.500'
            marginX={[0, 6, 10]}
          >
            {`${item?.price} Ft`}
          </Heading>
          <Button
            variantColor='red'
            maxW='150px'
            onClick={() => cartActions.addToCart(item, 0)}
          >
            Kosárba
          </Button>
        </Flex>
      </Flex>
    </Card>
  );
}
