import {
  Box,
  Button,
  Checkbox,
  Flex,
  Heading,
  IconButton,
  Image,
  Text,
  useToast,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
} from '@chakra-ui/core';
import React, { useEffect, useState } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';

import { CITY_ID } from '../../config/categories';
import { configFile } from '../../configFile';
import { IProduct } from '../../redux/products/ProductTypes';
import ItemAddedToCartToast from '../item/ItemAddedToCartToast';
import { CREATOR_IMAGES, CREATOR_ORDER } from './CreatorConfig';
import { ReduxCreatorProps } from './CreatorConstants';

type ExtraPriceType = {
  lactoseFree28: number;
  lactoseFree52: number;
};

export default function SelectorSummary({
  creation,
  reduxProps,
  creatorName,
  handler,
}: {
  creation: IProduct | null;
  reduxProps: ReduxCreatorProps;
  creatorName: string;
  handler: () => void;
}): JSX.Element {
  const history = useNavigate();
  const { city } = useParams<{ city: string }>();
  const toast = useToast();
  const [lactoseFree, setLactoseFree] = useState(false);
  const [extraPrices, setExtraPrices] = useState<ExtraPriceType>();
  const showSuccess = () => {
    toast({
      duration: 3000,
      position: 'top',
      render: (onCloseToast) => (
        <ItemAddedToCartToast
          onCloseToast={onCloseToast}
          history={history}
          href={generatePath('/:city/cart', {
            city,
          })}
        />
      ),
    });
  };

  useEffect(() => {
    reduxProps.OrderActions.fetchPromotionPrices()
      .then((resp) => {
        setExtraPrices({
          lactoseFree28: resp.data.lactoseFree28,
          lactoseFree52: resp.data.lactoseFree52,
        });
      })
      .catch((error: Error) => {});
  }, [reduxProps.OrderActions]);

  const calcExtra = () => {
    let extra = 0;

    if (lactoseFree && creation.name === '52 cm') {
      extra += extraPrices.lactoseFree52;
    }
    if (lactoseFree && creation.name !== '52 cm') {
      extra += extraPrices.lactoseFree28;
    }

    return extra;
  };

  const errorToastHandler = (error: Error) => {
    toast({
      title: 'Hiba',
      description: error.message,
      status: 'error',
      position: 'top',
      duration: 5000,
      isClosable: true,
    });
  };

  const cityId = CITY_ID.find((e) => e.name === city);

  const [currentSauce, setSauce] = useState('');

  useEffect(() => {
    setSauce(
      creation?.contents.find((content) =>
        [55, 153].includes(content.item?.parentCategoryId ?? -1)
      )?.item?.name ?? 'Válassz szószt'
    );
  }, [creation]);

  if (!creation) return <></>;

  const allCategoryFlatted = [...reduxProps.categories] // Kecskemét
    .flatMap((i) => [i, ...i.childCategories]) // Pizza,desszert,
    .flatMap((i) => [i, ...i.childCategories]); // Hawai pizza, sonkás pizza

  const creatorPizzaCat = allCategoryFlatted.find(
    (c) => c.id === creation.parentCategoryId
  );
  const realProductCat = allCategoryFlatted.find(
    (c) =>
      c.name === creatorPizzaCat?.name &&
      c.id !== creatorPizzaCat.id &&
      c?.picture
  );

  return (
    <Flex direction='column' backgroundColor='gray.300' p={4}>
      <Heading as='h3' size='lg' textAlign='center' pb={8}>
        {creatorName}
      </Heading>
      <Box pos='relative' h={250}>
        {creatorName !== 'Extra pizza' && realProductCat ? (
          <Image
            key={realProductCat?.id}
            w='100%'
            maxW={286}
            mx='auto'
            left={0}
            right={0}
            pos='absolute'
            top={0}
            src={
              creation.picture
                ? configFile.serverBaseUrlPicture + creation.picture?.filePath
                : 'https://via.placeholder.com/150?text=Nem+érhető+el+kép'
            }
            p={8}
          />
        ) : (
          <></>
        )}
      </Box>

      <Box mt={6} gridColumn='colStart / colEnd'>
        <Checkbox
          isChecked={lactoseFree}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setLactoseFree(e.target.checked);
          }}
        >
          <Text fontWeight='bold'>LAKTÓZMENTES SAJT</Text>{' '}
        </Checkbox>{' '}
        <Popover placement='bottom-end'>
          <PopoverTrigger>
            <IconButton
              aria-label='Delete'
              size='sm'
              background='none'
              icon='question-outline'
              mr={4}
              pb={2}
              paddingRight={2}
            />
          </PopoverTrigger>
          <PopoverContent>
            <PopoverArrow />
            <PopoverCloseButton />
            <PopoverHeader>Figyelem!</PopoverHeader>
            <PopoverBody>
              Ha ezt a négyzetet bejelöli akkor csak egyfajta laktózmentes
              sajttal készítjük el a pizzáját!
            </PopoverBody>
          </PopoverContent>
        </Popover>
      </Box>
      <Flex justifyContent='space-between'>
        <Text>Méret:</Text>
        <Text>{creation?.name}</Text>
      </Flex>
      <Flex mb={2} justifyContent='space-between'>
        <Text>Szósz:</Text>
        <Text>{currentSauce || 'Válassz szószt'}</Text>
      </Flex>
      <Text mb={4}>Feltétek:</Text>
      <Flex mb={4} direction='column'>
        {creation?.contents.map((content, i) => {
          if (content.item?.name === currentSauce)
            return <React.Fragment key={i} />;
          return (
            <Flex key={content.item?.id} ml={4} alignItems='center' my={1}>
              <Text>{content.item?.name}</Text>
              <Text ml='auto' mr={4}>
                {content.quantity === 1 ? '' : '2x'}
              </Text>
              <IconButton
                aria-label='Delete'
                size='sm'
                icon='delete'
                mr={4}
                onClick={() => {
                  const tempPizza = reduxProps.creation;
                  const tempContents = tempPizza?.contents.filter(
                    (element) => element.item?.name !== content.item?.name
                  );
                  if (tempPizza && tempContents)
                    reduxProps.ProductActions.modifyCreator(cityId?.id ?? -1, {
                      ...tempPizza,
                      contents: tempContents,
                      additionalItems: [],
                    }).catch(errorToastHandler);
                }}
              />
            </Flex>
          );
        })}
      </Flex>
      <Flex mb={4} justifyContent='space-between'>
        <Text>Ár:</Text>
        <Text>{reduxProps.creatorItemPrice?.finalPrice + calcExtra()} Ft</Text>
      </Flex>
      <Flex>
        <IconButton
          size='sm'
          aria-label='reset'
          icon='repeat'
          variantColor='gray'
          mr={2}
          onClick={handler}
        />
        <Button
          size='sm'
          fontSize='xs'
          variantColor='red'
          mx={2}
          onClick={() => {
            reduxProps.CartActions.addToCart(creation, calcExtra());
            showSuccess();
          }}
        >
          Kosárba
        </Button>
        <Button
          size='sm'
          fontSize='xs'
          variantColor='green'
          ml={2}
          onClick={() => {
            reduxProps.CartActions.addToCart(creation, calcExtra());
            history(
              generatePath('/:city/cart', {
                city,
              })
            );
          }}
        >
          Megrendelem
        </Button>
      </Flex>
    </Flex>
  );
}
