import {
  Box,
  Button,
  FormControl,
  Grid,
  Heading,
  Input,
  Text,
  useToast,
} from '@chakra-ui/core';
import React, { useState } from 'react';

import { ForgotFormData } from '../../redux/auth/AuthTypes';
import Card from '../Card';
import { AuthFormProps, AuthWhatsOpen } from '../home/HomeConstants';
import { authConnector } from '../navbar/NavConstants';

const defaultFormData: ForgotFormData = {
  email: '',
};

const isValidEmail = (email: string) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email.toLowerCase());
};
function ForgotForm({
  reduxProps,
  setwhatsOpen,
  showHeading,
  ...props
}: AuthFormProps): JSX.Element {
  const toast = useToast();
  const [formData, setFormData] = useState<ForgotFormData>(defaultFormData);
  let wasError = false;
  async function forgot() {
    if (!isValidEmail(formData.email))
      return toast({
        title: 'Error',
        description: 'A megadott email cím nem email cím!',
        status: 'error',
        position: 'top',
        duration: 9000,
        isClosable: true,
      });

    reduxProps.AuthActions.forgot(formData)
      .then(() => {
        if (setwhatsOpen && !wasError) setwhatsOpen(AuthWhatsOpen.login);
        toast({
          title: 'Email elküldve',
          description:
            'A megadott email címre elküldtük a jelszó visszaállításhoz szükgséges utasításokat, amennyiben az regisztrálva volt a rendeszerünkben.',
          status: 'success',
          position: 'top',
          duration: 9000,
          isClosable: true,
        });
      })
      .catch((error: any) => {
        wasError = true;
        toast({
          title: 'Hiba',
          description: error.response.data.msg,
          status: 'error',
          position: 'top',
          duration: 9000,
          isClosable: true,
        });
      });
  }
  return (
    <Card color="black" maxW="350px" {...props}>
      <FormControl>
        <Heading size="md" mb={4}>
          Elfelejtett jelszó
        </Heading>
        <Grid
          gridTemplateColumns="[colStart] 1fr [col2] 1fr [colEnd]"
          gridGap={2}
        >
          <Text gridColumn="colStart / colEnd">
            Kérjük add meg azt az Email címet, amivel korábban nálunk
            regisztráltál, erre küldjük hamarosan a visszaállításhoz szükséges
            utasításokat.
          </Text>
          <Input
            gridColumn="colStart / colEnd"
            type="email"
            placeholder="E-mail cím"
            size="sm"
            backgroundColor="gray.300"
            _placeholder={{ color: 'black' }}
            value={formData.email}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFormData({ ...formData, email: e.target.value });
            }}
          />
          <Box gridColumn="colStart / colEnd" w={[289.22, 286]} />
          <Button
            gridColumn="col2 / colEnd"
            variantColor="green"
            onClick={forgot}
          >
            Küldés
          </Button>
        </Grid>
      </FormControl>
    </Card>
  );
}

export default authConnector(ForgotForm);
