import { Box, Heading, Text, Button, Flex, Stack } from '@chakra-ui/core';
import moment from 'moment';
import React from 'react';
import { IOrder } from '../../../redux/orders/OrderTypes';
type Props = {
  order: IOrder;
  onOrderClicked: (order: any) => void;
  getPizzaCatName: any;
};
export const OrderCard = ({
  order,
  onOrderClicked = () => {},
  getPizzaCatName,
}: Props) => {
  const { orderItems } = order;

  return (
    <Box display='flex' flexDir='column' mb='2'>
      <Heading mb='4' alignSelf='center' as='h5' size='sm'>
        Rendelés ({moment(order.createdAt!).fromNow()})
      </Heading>
      {orderItems?.map((oItem) => (
        <Flex key={oItem.id} mb='4' w='100%' justifyContent='space-between'>
          <Text>{oItem.quantity} db</Text>
          <Stack direction='row-reverse'>
            <Text>{getPizzaCatName(oItem?.item?.parentCategoryId)}</Text>
            <Text>{oItem?.item?.name}</Text>
          </Stack>
        </Flex>
      ))}
      <Button onClick={() => onOrderClicked(order)} variantColor='green'>
        Újrarendelés
      </Button>
    </Box>
  );
};
