import { IProduct, ProductDispatchType, ProductState } from './ProductTypes';

const initState: ProductState = {
  products: [],
  categories: [],
  creator: null,
  creation: null,
  priceData: null,
};

const ProductReducer = (
  state = initState,
  action: ProductDispatchType,
): ProductState => {
  switch (action.type) {
    case 'PRODUCTS_DOWNLOADED':
      return {
        ...state,
        products: action.payloadProducts ?? [],
      };
    case 'CATEGORIES_DOWNLOADED': {
      return {
        ...state,
        categories: action.payloadCategories ?? [],
      };
    }
    case 'CREATOR_DOWNLOADED': {
      return {
        ...state,
        creator: action.payloadCategory ?? null,
      };
    }
    case 'MODIFY_CREATOR': {
      if (action.payloadProduct) {
        const tmp_payload: IProduct = { ...action.payloadProduct };
        tmp_payload?.contents.sort((a, b) =>
          a.item?.name.includes('alap') ? -1 : 1,
        );
        return {
          ...state,
          creation: tmp_payload,
        };
      }
      return {
        ...state,
        creation: null,
      };
    }
    case 'PRICE_DOWNLOADED': {
      return {
        ...state,
        priceData: action.priceData ?? null,
        creation: {
          ...state.creation!,
          price: action.priceData.finalPrice ?? 0,
        },
      };
    }
    default:
      return state;
  }
};
export default ProductReducer;
