import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  Input,
  useToast,
} from '@chakra-ui/core';
import React, { useEffect, useState } from 'react';

import { IAddress } from '../../redux/addresses/AddressTypes';
import Autocomplete from '../autocomplete';
import { useParams } from 'react-router-dom';
import Card from '../Card';
import { AuthWhatsOpen, SetAuthWhatsOpenProps } from '../home/HomeConstants';
import { authConnector, ReduxNavProps } from '../navbar/NavConstants';
import { szolnokData, kecskemetData } from '../szolnokAddresses';

const defaultFormData: IAddress = {
  name: '',
  zip: 0,
  city: '',
  street: '',
  number: '',
  floorDoor: '',
  bellComment: '',
};

function AddressForm({
  reduxProps,
  setwhatsOpen,
  modification,
  ...props
}: SetAuthWhatsOpenProps & {
  reduxProps: ReduxNavProps;
  formToModify?: IAddress;
}): JSX.Element {
  const { city } = useParams<{ city: string }>();
  const toast = useToast();
  const [formData, setFormData] = useState<IAddress>(
    reduxProps.modifyAddress || defaultFormData
  );

  useEffect(() => {
    if (reduxProps.modifyAddress) {
      setFormData(reduxProps.modifyAddress);
    }
    return () => {};
  }, [reduxProps.modifyAddress]);

  const [isModification] = useState<boolean>(modification || false);
  const [isAddressSelected, setIsAddressSelected] = useState(false);

  let wasError = false;

  function hasNumbers(t: string) {
    const regex = /\d/g;
    return regex.test(t);
  }

  function validateData() {
    if (
      formData.city === '' ||
      formData.name === '' ||
      formData.number === '' ||
      formData.street === '' ||
      formData.zip < 1000 ||
      formData.zip > 9999
    ) {
      toast({
        title: 'Hiba',
        description: 'Kérjük töltse ki helyesen a szükséges mezőket!',
        status: 'error',
        position: 'top',
        duration: 5000,
        isClosable: true,
      });
      return false;
    }

    if (formData.zip > 9999 || formData.zip < 999) {
      toast({
        title: 'Hiba',
        description: 'Kérjük létező irányítószámot adjon meg!',
        status: 'error',
        position: 'top',
        duration: 5000,
        isClosable: true,
      });
      return false;
    }

    if (hasNumbers(formData.city)) {
      toast({
        title: 'Hiba',
        description: 'Kérjük létező várost adjon meg!',
        status: 'error',
        position: 'top',
        duration: 5000,
        isClosable: true,
      });
      return false;
    }

    return true;
  }

  async function modifyAddress() {
    const isCorrectData = validateData();

    if (isCorrectData) {
      let currentAddresses: IAddress[] = [];
      currentAddresses =
        reduxProps.userInfo?.addresses?.filter((x) => {
          return x !== reduxProps.modifyAddress;
        }) ?? [];
      const addedAddresses = [formData, ...currentAddresses];
      const promise = reduxProps.AuthActions.addAddress(addedAddresses).catch(
        (error: Error) => {
          wasError = true;
          toast({
            title: 'Hiba',
            description: error.message,
            status: 'error',
            position: 'top',
            duration: 9000,
            isClosable: true,
          });
        }
      );

      await promise.then(() => {
        if (setwhatsOpen && !wasError) {
          setwhatsOpen(AuthWhatsOpen.about);
          reduxProps.AuthActions.getUserInfo();
        }
      });
    }
  }

  async function addAddress() {
    const isCorrectData = validateData();

    if (isCorrectData) {
      let currentAddresses: IAddress[] = [];
      currentAddresses = reduxProps.userInfo?.addresses ?? [];
      const addedAddresses = [formData, ...currentAddresses];
      const promise = reduxProps.AuthActions.addAddress(addedAddresses).catch(
        (error: Error) => {
          wasError = true;
          toast({
            title: 'Hiba',
            description: error.message,
            status: 'error',
            position: 'top',
            duration: 9000,
            isClosable: true,
          });
        }
      );

      await promise.then(() => {
        if (setwhatsOpen && !wasError) {
          setwhatsOpen(AuthWhatsOpen.about);
          reduxProps.AuthActions.getUserInfo();
        }
      });
    }
  }

  return (
    <Card color='black' maxW='350px' textAlign='right' {...props}>
      <IconButton
        mb={2}
        icon='close'
        size='sm'
        variant='outline'
        aria-label='Mégse'
        color='red.300'
        isRound
        onClick={() => {
          if (setwhatsOpen) setwhatsOpen(AuthWhatsOpen.about);
        }}
      />
      <FormControl>
        <Grid
          gridTemplateColumns='[colStart] 1fr 1fr [colEnd]'
          w='100%'
          gridGap={2}
        >
          <Input
            gridColumn='colStart / colEnd'
            type='text'
            placeholder='Név*'
            size='sm'
            backgroundColor='gray.300'
            _placeholder={{ color: 'black' }}
            value={formData.name}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFormData({ ...formData, name: e.target.value });
            }}
          />
          <Input
            gridColumn='colStart / colEnd'
            type='number'
            placeholder='Irányítószám*'
            size='sm'
            backgroundColor='gray.300'
            _placeholder={{ color: 'black' }}
            value={formData.zip === 0 ? '' : formData.zip}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFormData({ ...formData, zip: Number(e.target.value) });
            }}
          />
          <Input
            gridColumn='colStart / colEnd'
            type='text'
            placeholder='Város*'
            size='sm'
            backgroundColor='gray.300'
            _placeholder={{ color: 'black' }}
            value={formData.city}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFormData({ ...formData, city: e.target.value });
            }}
          />
          <Autocomplete
            setIsAddressSelected={setIsAddressSelected}
            suggestions={city === 'szolnok' ? szolnokData : kecskemetData}
            setData={setFormData}
            data={formData}
            isDisabled={formData.zip.toString().length !== 4}
          />

          <Input
            gridColumn='colStart / colEnd'
            type='text'
            placeholder='Házszám*'
            size='sm'
            backgroundColor='gray.300'
            _placeholder={{ color: 'black' }}
            value={formData.number}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFormData({ ...formData, number: e.target.value });
            }}
          />
          <Input
            gridColumn='colStart / colEnd'
            type='text'
            placeholder='Emelet/ajtó'
            size='sm'
            backgroundColor='gray.300'
            _placeholder={{ color: 'black' }}
            value={formData.floorDoor}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFormData({ ...formData, floorDoor: e.target.value });
            }}
          />
          <Input
            gridColumn='colStart / colEnd'
            placeholder='Csengő/megjegyzés'
            size='sm'
            backgroundColor='gray.300'
            _placeholder={{ color: 'black' }}
            value={formData.bellComment}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFormData({ ...formData, bellComment: e.target.value });
            }}
          />

          <Box gridColumn='colStart / colEnd' w={[289.22, 286]} />

          {isModification ? (
            <Button
              gridColumn='colStart / colEnd'
              variantColor='blue'
              onClick={modifyAddress}
              isDisabled={
                !isAddressSelected &&
                (formData.zip === 5000 ||
                  formData.zip === 5008 ||
                  formData.zip === 6000)
              }
            >
              Cím módosítása
            </Button>
          ) : (
            <Button
              gridColumn='colStart / colEnd'
              variantColor='green'
              onClick={addAddress}
              isDisabled={
                !isAddressSelected &&
                (formData.zip === 5000 ||
                  formData.zip === 5008 ||
                  formData.zip === 6000)
              }
            >
              Új cím hozzáadása
            </Button>
          )}
        </Grid>
      </FormControl>
    </Card>
  );
}

export default authConnector(AddressForm);
