import { NewsDispatchType, NewsState } from './NewsTypes';

const initState: NewsState = {
  homeBackgrounds: [],
  newsData: [],
};

const NewsReducer = (
  state = initState,
  action: NewsDispatchType,
): NewsState => {
  switch (action.type) {
    case 'BACKGROUNDS_DOWNLOADED':
      return {
        ...state,
        homeBackgrounds: action.payloadHomeBackgrounds!,
      };
    case 'NEWS_DOWNLOADED':
      return {
        ...state,
        newsData: action.payloadNews!,
      };
    default:
      return state;
  }
};
export default NewsReducer;
