import {
  BoxProps,
  Checkbox,
  Flex,
  Grid,
  Heading,
  Image,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useToast,
} from '@chakra-ui/core';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { CITY_ID } from '../../config/categories';
import { ICategory, IProduct } from '../../redux/products/ProductTypes';
import { CREATOR_ICONS } from './CreatorConfig';
import { ReduxCreatorProps } from './CreatorConstants';
import SelectorSummary from './SelectorSummary';

export default function ToppingsSelector({
  reduxProps,
  creator,
  pizzas,
  creatorName,
  ...restProps
}: BoxProps & {
  creator: ICategory;
  pizzas: IProduct[];
  creatorName: string;
  reduxProps: ReduxCreatorProps;
}): JSX.Element {
  const toast = useToast();

  const errorToastHandler = (error: Error) => {
    toast({
      title: 'Hiba',
      description: error.message,
      status: 'error',
      position: 'top',
      duration: 5000,
      isClosable: true,
    });
  };
  const { city } = useParams<{ city: string }>();
  if (!reduxProps.creation) return <></>;
  const cityId = CITY_ID.find((e) => e.name === city);

  const currentPizza = creator.childCategories
    .find((c) => c.id === reduxProps.creation?.parentCategoryId)!
    .items.find((i) => i.id === reduxProps.creation!.id);
  const defaultToppings =
    currentPizza?.additionalItems.flatMap(
      (items) => items.category!.childCategories
    ) ?? [];

  const sauces =
    (currentPizza ?? pizzas[0])?.contents
      .find((c) => c.category && c.category.name === 'Alapszószok')
      ?.category?.items.sort((a: IProduct, b: IProduct) =>
        a.name.localeCompare(b.name)
      ) ?? [];

  const resetHandler = () => {
    reduxProps.ProductActions.modifyCreator(cityId?.id ?? -1, {
      ...pizzas[0],
      contents: pizzas[0].defaultContents ?? [{ item: sauces[0], quantity: 1 }],
      additionalItems: [],
    }).catch(errorToastHandler);
  };

  const toppingColors = {
    meat: 'red.300',
    vegetable: 'green.200',
    cheese: 'yellow.200',
    other: 'gray.400',
  };

  const defaultMeats = defaultToppings.find((e) => e.name === 'Húsok');
  const defaultVegetables = defaultToppings.find((e) => e.name === 'Zöldségek');
  const defaultCheeses = defaultToppings.find((e) => e.name === 'Sajtok');
  const defaultOthers = defaultToppings.find((e) => e.name === 'Egyebek');
  return (
    <Grid
      color='black'
      gridTemplateColumns={['1fr', '1fr', '1fr', '7fr 3fr']}
      gridGap={4}
      {...restProps}
    >
      <Flex direction='column'>
        <Heading as='h3' size='lg' textAlign='center' pb={8}>
          Válaszd ki a kedvenc feltéteid!
          <br />
          Maximum 7-et, mert többet sajnos nem tudunk átsütni!
        </Heading>
        <Tabs isFitted variant='unstyled'>
          <TabList>
            <Tab
              borderRadius='32px 64px 0px 32px'
              fontWeight='bold'
              fontSize='lg'
              p={0}
              backgroundColor={toppingColors.meat}
            >
              <Image src={CREATOR_ICONS.meat} mr={2} my={2} w={10} />
              <Text mr={4} display={['none', 'none', 'initial']}>
                Húsok
              </Text>
            </Tab>
            <Tab
              borderRadius='32px 64px 0px 32px'
              fontWeight='bold'
              fontSize='lg'
              p={0}
              backgroundColor={toppingColors.vegetable}
            >
              <Image src={CREATOR_ICONS.vegetable} mr={2} my={2} w={10} />
              <Text mr={4} display={['none', 'none', 'initial']}>
                Zöldségek
              </Text>
            </Tab>
            <Tab
              borderRadius='32px 64px 0px 32px'
              fontWeight='bold'
              fontSize='lg'
              p={0}
              backgroundColor={toppingColors.cheese}
            >
              <Image src={CREATOR_ICONS.cheese} mr={2} my={2} w={10} />
              <Text mr={4} display={['none', 'none', 'initial']}>
                Sajtok
              </Text>
            </Tab>
            <Tab
              borderRadius='32px 64px 0px 32px'
              fontWeight='bold'
              fontSize='lg'
              p={0}
              backgroundColor={toppingColors.other}
            >
              <Image src={CREATOR_ICONS.other} mr={2} my={2} w={10} />
              <Text mr={4} display={['none', 'none', 'initial']}>
                Prémium
              </Text>
            </Tab>
          </TabList>

          <TabPanels>
            <TabPanel>
              <ToppingsTab
                creation={reduxProps.creation}
                reduxProps={reduxProps}
                toppingColor={toppingColors.meat}
                toppings={defaultMeats?.items.sort((a, b) =>
                  a.name.localeCompare(b.name)
                )}
              />
            </TabPanel>
            <TabPanel>
              <ToppingsTab
                creation={reduxProps.creation}
                reduxProps={reduxProps}
                toppingColor={toppingColors.vegetable}
                toppings={defaultVegetables?.items.sort((a, b) =>
                  a.name.localeCompare(b.name)
                )}
              />
            </TabPanel>
            <TabPanel>
              <ToppingsTab
                creation={reduxProps.creation}
                reduxProps={reduxProps}
                toppingColor={toppingColors.cheese}
                toppings={defaultCheeses?.items.sort((a, b) =>
                  a.name.localeCompare(b.name)
                )}
              />
            </TabPanel>
            <TabPanel>
              <ToppingsTab
                creation={reduxProps.creation}
                reduxProps={reduxProps}
                toppingColor={toppingColors.other}
                toppings={defaultOthers?.items.sort((a, b) =>
                  a.name.localeCompare(b.name)
                )}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Flex>
      <SelectorSummary
        handler={resetHandler}
        creation={reduxProps.creation}
        reduxProps={reduxProps}
        creatorName={creatorName}
      />
    </Grid>
  );
}

function ToppingsTab({
  creation,
  toppings,
  toppingColor,
  reduxProps,
  ...restProps
}: BoxProps & {
  creation?: IProduct;
  toppings?: IProduct[];
  toppingColor?: string;
  reduxProps: ReduxCreatorProps;
}) {
  const compareProducts = (f: IProduct, s: IProduct) =>
    f.name.localeCompare(s.name);

  return (
    <Grid
      gridTemplateColumns={['1fr', '1fr 1fr', '1fr 1fr 1fr', '1fr 1fr 1fr']}
      gap={4}
      pt={4}
      {...restProps}
    >
      {toppings?.sort(compareProducts).map((item) => {
        return (
          <Topping
            toppingColor={toppingColor}
            key={item.id}
            item={item}
            reduxProps={reduxProps}
          />
        );
      })}
    </Grid>
  );
}

function Topping({
  item,
  reduxProps,
  toppingColor,
}: {
  item: IProduct;
  reduxProps: ReduxCreatorProps;
  toppingColor?: string;
}) {
  const { city } = useParams<{ city: string }>();
  const cityId = CITY_ID.find((e) => e.name === city);
  const [canDouble, setCanDouble] = useState<boolean>(false);
  const [isDouble, setIsDouble] = useState<boolean>(false);
  const [isSimple, setIsSimple] = useState<boolean>(false);

  const toast = useToast();
  const showErrorToast = () => {
    toast({
      title: 'Hiba',
      description: 'Sajnos ezt a feltétet már nem teheted bele a pizzádba',
      status: 'error',
      position: 'top',
      duration: 5000,
      isClosable: true,
    });
  };
  useEffect(() => {
    const isSimpleContent = reduxProps.creation?.contents.some(
      (element) => element.item?.name === item.name
    );

    if (isSimpleContent) {
      setIsSimple(true);
      setCanDouble(true);
    } else {
      setIsSimple(false);
    }
    return () => {
      setIsSimple(false);
      setCanDouble(false);
    };
  }, [item, reduxProps]);

  return (
    <Flex
      key={item.name}
      position='relative'
      px={2}
      justifyContent='space-between'
      alignItems='center'
      borderX='1px solid gray'
      borderColor='gray.300'
      borderRadius='5px'
      boxShadow='2px 2px 5px gray'
      backgroundColor={toppingColor}
    >
      <Checkbox
        my={2}
        key={item.name}
        isChecked={isSimple}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          const tempPizza = reduxProps.creation;
          if (e.target.checked) {
            if (tempPizza)
              reduxProps.ProductActions.modifyCreator(cityId?.id ?? -1, {
                ...tempPizza,
                contents: [...tempPizza.contents, { item, quantity: 1 }],
                additionalItems: [],
              })
                .then(() => {
                  setCanDouble(true);
                })
                .catch(() => {
                  setCanDouble(false);
                  setIsDouble(false);
                  showErrorToast();
                  reduxProps.ProductActions.modifyCreator(
                    cityId?.id ?? -1,
                    tempPizza
                  );
                });
          } else {
            setCanDouble(false);
            setIsDouble(false);
            const tempContents = tempPizza?.contents.filter(
              (element) => element.item?.name !== item.name
            );
            if (tempPizza && tempContents)
              reduxProps.ProductActions.modifyCreator(cityId?.id ?? -1, {
                ...tempPizza,
                contents: tempContents,
                additionalItems: [],
              }).catch(() => {
                showErrorToast();
                reduxProps.ProductActions.modifyCreator(
                  cityId?.id ?? -1,
                  tempPizza
                );
              });
          }
        }}
      >
        {item.name}
      </Checkbox>
      {canDouble && (
        <Checkbox
          flexDirection='row-reverse'
          isChecked={
            isDouble &&
            reduxProps.creation?.contents.filter(
              (element) => element.item?.name === item.name
            ).length !== 0
          }
          isDisabled={
            !canDouble ||
            reduxProps.creation?.contents.filter(
              (element) => element.item?.name === item.name
            ).length === 0
          }
          my={2}
          key={`${item.name} dupla`}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setIsDouble(e.target.checked);
            const tempPizza = reduxProps.creation;
            const tempContents = tempPizza?.contents.filter(
              (element) => element.item?.name !== item.name
            );
            if (tempPizza && tempContents) {
              if (e.target.checked) {
                reduxProps.ProductActions.modifyCreator(cityId?.id ?? -1, {
                  ...tempPizza,
                  contents: [...tempContents, { item, quantity: 2 }],
                  additionalItems: [],
                }).catch(() => {
                  setIsDouble(false);
                  showErrorToast();
                  reduxProps.ProductActions.modifyCreator(
                    cityId?.id ?? -1,
                    tempPizza
                  );
                });
              } else
                reduxProps.ProductActions.modifyCreator(cityId?.id ?? -1, {
                  ...tempPizza,
                  contents: [...tempContents, { item, quantity: 1 }],
                  additionalItems: [],
                }).catch(() => {
                  reduxProps.ProductActions.modifyCreator(
                    cityId?.id ?? -1,
                    tempPizza
                  );
                });
            }
          }}
        >
          <Text pr={2}>2x</Text>
        </Checkbox>
      )}
    </Flex>
  );
}
