import {
  Box,
  BoxProps,
  Flex,
  FormControl,
  Grid,
  Heading,
  Image,
  Radio,
  RadioGroup,
  Text,
  useToast,
} from '@chakra-ui/core';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { CITY_ID } from '../../config/categories';
import { IProduct, IProductContent } from '../../redux/products/ProductTypes';
import { CREATOR_ICONS } from './CreatorConfig';
import { ReduxCreatorProps } from './CreatorConstants';
import SelectorSummary from './SelectorSummary';

export default function PizzaSelector({
  reduxProps,
  pizzas,
  creatorName,
  ...restProps
}: BoxProps & {
  pizzas: IProduct[];
  creatorName: string;
  reduxProps: ReduxCreatorProps;
}): JSX.Element {
  const toast = useToast();

  const errorToastHandler = (error: Error) => {
    toast({
      title: 'Hiba',
      description: error.message,
      status: 'error',
      position: 'top',
      duration: 5000,
      isClosable: true,
    });
  };
  const routeParams = useParams<{
    city: string;
    category: string;
    creatorName: string;
  }>();

  const cityId = CITY_ID.find((e) => e.name === routeParams.city);
  const currentPizza = pizzas.find(
    (pizza) => !reduxProps.creation || reduxProps.creation.id === pizza.id
  );
  const otherPizza = pizzas.find(
    (pizza) => !reduxProps.creation || reduxProps.creation.id !== pizza.id
  );

  const sauces =
    (currentPizza ?? pizzas[0]).contents
      .find((c) => c.category?.name === 'Alapszószok')
      ?.category?.items.sort((a, b) => a.name.localeCompare(b.name)) ?? [];

  const otherSauces =
    (otherPizza ?? pizzas[0]).contents
      .find((c) => c.category?.name === 'Alapszószok')
      ?.category?.items.sort((a, b) => a.name.localeCompare(b.name)) ?? [];
  const [isLoading, setLoading] = useState<boolean>(true);
  useEffect(() => {
    setLoading(true);
    reduxProps.ProductActions.modifyCreator(cityId?.id ?? -1, {
      ...pizzas[0],
      contents: pizzas[0].defaultContents ?? [{ item: sauces[0], quantity: 1 }],
      additionalItems: [],
    })
      .catch()
      .finally(() => setLoading(false));
    // eslint-disable-next-line
  }, [routeParams.city, routeParams.creatorName]);

  const resetHandler = () => {
    reduxProps.ProductActions.modifyCreator(cityId?.id ?? -1, {
      ...pizzas[0],
      contents: pizzas[0].defaultContents ?? [{ item: sauces[0], quantity: 1 }],
      additionalItems: [],
    }).catch(errorToastHandler);
  };
  const setSizeHandler = (val: string | (string & string[])) => {
    const tempPizza = pizzas.find((e) => e.id === parseInt(val, 10));

    const temp: IProductContent[] = [];

    const toppings =
      tempPizza?.additionalItems.flatMap(
        (items) => items.category?.childCategories
      ) ?? [];
    reduxProps.creation?.contents.forEach((topping) => {
      toppings.forEach((category) => {
        const others =
          category?.items.filter(
            (otherSizeTopping) => topping.item?.name === otherSizeTopping.name
          ) || [];

        if (others.length > 1) {
          console.warn('More than one match!', others);
        }

        if (others.length === 1) {
          const [other] = others;

          temp.push({ item: other, quantity: topping.quantity });
        }
      });

      const other = otherSauces.find(
        (otherSauce) => topping.item?.name === otherSauce.name
      );
      if (other != null && other !== undefined) {
        temp.push({ item: other, quantity: topping.quantity });
      }
    });

    if (tempPizza)
      reduxProps.ProductActions.modifyCreator(cityId?.id ?? -1, {
        ...tempPizza,
        contents: temp ?? [],
        additionalItems: [],
      }).catch((error: Error) => {
        console.error(error);
        // TODO FIXME Kijavítani mert újratervezés esetén mindig hibát dob!
      });
  };
  const setSauceHandler = (val: string | (string & string[])) => {
    const tempPizza = reduxProps.creation;
    const tempSauce = sauces.find((e) => e.id === parseInt(val, 10));
    const tempContents =
      tempPizza?.contents.filter(
        // parentcategoryId 55 és 153 ( kecskemet, szolnok ) == alapszószok kategória
        (element) =>
          element.item?.parentCategoryId !== 55 &&
          element.item?.parentCategoryId !== 153
      ) ?? [];

    if (tempPizza && tempSauce) {
      reduxProps.ProductActions.modifyCreator(cityId?.id ?? -1, {
        ...tempPizza,
        contents: [...tempContents, { item: tempSauce, quantity: 1 }],
        additionalItems: [],
      }).catch(errorToastHandler);
    }
  };

  const defaultPizzaId = pizzas.find((e) => e.name === '28 cm')?.id;

  let asd: number | undefined;

  const [defaultSauceId, setDefaultSauceId] = useState(asd);

  useEffect(() => {
    if (reduxProps != null && reduxProps.creation != null) {
      setDefaultSauceId(
        sauces.find(
          (e) =>
            e.name ===
            reduxProps.creation?.defaultContents?.find((i) =>
              i.item?.name.includes(' alap')
            )?.item?.name
        )?.id
      );
    }
    return () => {};
    // eslint-disable-next-line
  }, [reduxProps.creation]);

  if (!reduxProps.creation || isLoading)
    return <Heading color='black'>Varázsló betöltése...</Heading>;

  return (
    <Grid
      color='black'
      gridTemplateColumns={['1fr', '1fr', '1fr', '7fr 3fr']}
      gridGap={4}
      {...restProps}
    >
      <Flex direction='column'>
        <Heading as='h3' size='lg' textAlign='center' pb={8}>
          Válaszd ki a pizza méretét és az alapízt!
          <br />
          Majd add hozzá a kedvenc feltéteidet!
        </Heading>
        <Grid gridTemplateColumns={['1fr', '1fr', '1fr', '1fr 1fr']}>
          <PizzaCategory
            icon={CREATOR_ICONS.size}
            text='Méret'
            formContent={pizzas}
            reduxProps={reduxProps}
            categoryColor='blue.200'
            defaultValue={defaultPizzaId ? defaultPizzaId.toString() : ''}
            handler={setSizeHandler}
          />
          <PizzaCategory
            icon={CREATOR_ICONS.sauces}
            text='Alapszószok'
            formContent={sauces}
            reduxProps={reduxProps}
            defaultValue={defaultSauceId ? defaultSauceId.toString() : ''}
            categoryColor='#da1515'
            handler={setSauceHandler}
          />
        </Grid>
        <Grid />
      </Flex>
      <SelectorSummary
        handler={resetHandler}
        creation={reduxProps.creation}
        reduxProps={reduxProps}
        creatorName={creatorName}
      />
    </Grid>
  );
}

function PizzaCategory({
  text,
  icon,
  formContent,
  reduxProps,
  defaultValue,
  categoryColor,
  handler,
  ...restProps
}: {
  text: string;
  icon: string;
  formContent: IProduct[];
  reduxProps: ReduxCreatorProps;
  categoryColor: string;
  defaultValue: string;
  handler: (val: string | (string & string[])) => void;
} & BoxProps) {
  const [value, setValue] = React.useState('');

  useEffect(() => {
    formContent.forEach((e) => {
      if (e.id === reduxProps.creation?.id) {
        setValue(e.id?.toString() ?? '-1');
      }
      reduxProps.creation?.defaultContents?.forEach((defaultContent) => {
        if (e.id === defaultContent.itemId) {
          setValue(e.id?.toString() ?? '-1');
        }
      });
    });

    setValue(defaultValue);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setValue(defaultValue);
    handler(defaultValue);
    // eslint-disable-next-line
  }, [defaultValue]);

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    handler(e.target.value);
  };

  return (
    <Flex minW={[0, 0, 0, 0, '160px']} direction='column' {...restProps}>
      <Flex
        justifyContent='center'
        minW={[0, 0, 0, 0, '160px']}
        alignItems='center'
        backgroundColor={categoryColor}
        borderRadius='32px 64px 0 32px'
        mb={4}
      >
        <Image src={icon} m={2} w={10} />
        <Text mr={4} textTransform='uppercase' fontWeight='500'>
          {text}
        </Text>
      </Flex>
      <Box pl={4} mb={8}>
        <FormControl>
          <RadioGroup onChange={handleOnChange} value={value}>
            {formContent.map((e) => (
              <Radio key={e.id} value={e.id?.toString() ?? '-1'}>
                {e.name}
              </Radio>
            ))}
          </RadioGroup>
        </FormControl>
      </Box>
    </Flex>
  );
}
