import {
  Box,
  Button,
  Checkbox,
  CheckboxGroup,
  Flex,
  FormControl,
  Grid,
  Heading,
  Input,
  InputGroup,
  InputLeftAddon,
  Select,
  useToast,
} from '@chakra-ui/core';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import { ALLERGIES, CITY_ID } from '../../config/categories';
import { RegisterFormData } from '../../redux/auth/AuthTypes';
import Card from '../Card';
import { AuthFormProps, AuthWhatsOpen } from '../home/HomeConstants';
import { authConnector } from '../navbar/NavConstants';

const defaultFormData: RegisterFormData = {
  fullName: '',
  email: '',
  password: '',
  confirmPassword: '',
  phone: '',
  birthday: '',
  allergies: [],
};

function RegisterForm({
  reduxProps,
  setwhatsOpen,
  showHeading,
  ...props
}: AuthFormProps): JSX.Element {
  const toast = useToast();
  const [formData, setFormData] = useState<RegisterFormData>(defaultFormData);

  // Storing the birthday and birthmonth on separate select inputs then merge them to form data
  const [birthDay, setBirthDay] = useState('');
  const [birthMonth, setBirthMonth] = useState('');

  const [aszf, setAszf] = useState(false);
  const [invalidateASZF, setInvalidate] = useState(false);

  // Generate select options for days to avoid code redundancy
  const generateDayOptions = (): JSX.Element[] => {
    let maxDays = 0;
    switch (parseInt(birthMonth, 10)) {
      case 1:
      case 3:
      case 5:
      case 7:
      case 8:
      case 10:
      case 12:
        maxDays = 31;
        break;
      case 4:
      case 6:
      case 9:
      case 11:
        maxDays = 30;
        break;
      default:
        maxDays = 29;
    }

    const optionArray: JSX.Element[] = [];
    for (let i = 1; i <= maxDays; i++) {
      if (i < 10) {
        optionArray.push(
          <option key={i} value={`0${i.toString()}`}>
            {i}.
          </option>
        );
      } else {
        optionArray.push(
          <option key={i} value={i}>
            {i}.
          </option>
        );
      }
    }

    return optionArray;
  };

  // Generate allergy checkboxes
  const generateAllergies = (): JSX.Element => {
    const allergyChkboxes: JSX.Element[] = [];
    ALLERGIES.forEach((allergy) => {
      allergyChkboxes.push(
        <Checkbox mr={2} key={allergy.id} value={allergy.id}>
          {allergy.name}
        </Checkbox>
      );
    });

    return <Flex wrap='wrap'>{allergyChkboxes}</Flex>;
  };

  // Simple regex to validate email
  function validateEmail(email: string) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  // Returns true, if every input has correct value
  function validateData(): boolean {
    if (
      formData.fullName === '' ||
      formData.email === '' ||
      formData.password === '' ||
      formData.phone === ''
    ) {
      toast({
        title: 'Hiba',
        description: 'Kérjük töltsön ki minden mezőt!',
        status: 'error',
        position: 'top',
        duration: 5000,
        isClosable: true,
      });
      return false;
    }

    if (!validateEmail(formData.email)) {
      toast({
        title: 'Hiba',
        description: 'Kérjük létező email címet adjon meg!',
        status: 'error',
        position: 'top',
        duration: 5000,
        isClosable: true,
      });
      return false;
    }

    if (formData.password !== formData.confirmPassword) {
      toast({
        title: 'Hiba',
        description: 'Nem egyezik meg a két jelszó!',
        status: 'error',
        position: 'top',
        duration: 5000,
        isClosable: true,
      });
      return false;
    }

    if (formData.password.length < 6) {
      toast({
        title: 'Hiba',
        description: 'Kérjük adjon meg egy minimum 6 karakter hosszú jelszót!',
        status: 'error',
        position: 'top',
        duration: 5000,
        isClosable: true,
      });
      return false;
    }

    return true;
  }

  async function register() {
    if (!aszf) {
      toast({
        title: 'Hiba',
        description: 'Kérjük fogadd el az Általános Szerőzdési Feltételeket!',
        status: 'error',
        position: 'top',
        duration: 9000,
        isClosable: true,
      });

      setInvalidate(true);

      return;
    }
    const isCorrectData = validateData();
    let wasError = false;

    if (isCorrectData) {
      const promise = reduxProps.AuthActions.register(formData).catch(
        (error: Error) => {
          wasError = true;
          toast({
            title: 'Hiba',
            description: error.message,
            status: 'error',
            position: 'top',
            duration: 9000,
            isClosable: true,
          });
        }
      );

      await promise.then(() => {
        if (setwhatsOpen && !wasError) {
          toast({
            title: 'Siker',
            description: 'Sikeres regisztráció!',
            status: 'success',
            position: 'top',
            duration: 3000,
            isClosable: true,
          });
          setwhatsOpen(AuthWhatsOpen.about);
        }
      });
    }
  }
  const { city } = useParams<{ city: string }>();

  const company: string =
    city === CITY_ID[0].name ? 'forno' : 'pastaline' ?? '';
  return (
    <Card color='black' maxW='350px' {...props}>
      {showHeading && (
        <Heading size='md' mb={4}>
          Regisztráció
        </Heading>
      )}
      <FormControl>
        <Grid
          as='form'
          onSubmit={(e) => {
            e.preventDefault();
            register();
          }}
          gridTemplateColumns='[colStart] 1fr 1fr [colEnd]'
          w='100%'
          gridGap={2}
        >
          <Input
            gridColumn='colStart / colEnd'
            type='text'
            name='full-name'
            autoComplete='full-name'
            placeholder='Teljes név'
            size='sm'
            isRequired
            backgroundColor='gray.300'
            _placeholder={{ color: 'black' }}
            value={formData.fullName}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFormData({ ...formData, fullName: e.target.value });
            }}
          />
          <Input
            gridColumn='colStart / colEnd'
            type='email'
            name='email'
            autoComplete='new-email'
            placeholder='E-mail cím'
            size='sm'
            isRequired
            backgroundColor='gray.300'
            _placeholder={{ color: 'black' }}
            value={formData.email}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFormData({ ...formData, email: e.target.value });
            }}
          />
          <Input
            gridColumn='colStart / colEnd'
            type='password'
            name='password'
            autoComplete='new-password'
            placeholder='Jelszó'
            size='sm'
            isRequired
            backgroundColor='gray.300'
            _placeholder={{ color: 'black' }}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFormData({ ...formData, password: e.target.value });
            }}
          />
          <Input
            gridColumn='colStart / colEnd'
            type='password'
            name='confirm-password'
            autoComplete='confirm-password'
            placeholder='Jelszó újra'
            size='sm'
            isRequired
            backgroundColor='gray.300'
            _placeholder={{ color: 'black' }}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFormData({ ...formData, confirmPassword: e.target.value });
            }}
          />
          <InputGroup gridColumn='colStart / colEnd'>
            <InputLeftAddon>06</InputLeftAddon>
            <Input
              type='tel'
              name='phone'
              placeholder='Telefonszám'
              autoComplete='phone'
              size='sm'
              isRequired
              backgroundColor='gray.300'
              _placeholder={{ color: 'black' }}
              value={formData.phone}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setFormData({ ...formData, phone: e.target.value });
              }}
            />
          </InputGroup>

          <Heading size='sm'>Születésnap</Heading>

          <InputGroup gridColumn='colStart / colEnd'>
            <Select
              placeholder='Hónap'
              name='birthmonth'
              backgroundColor='gray.300'
              _placeholder={{ color: 'black' }}
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                setBirthMonth(e.target.value);
                setFormData({
                  ...formData,
                  birthday: `${e.target.value}/${birthDay}`,
                });
                setBirthDay('');
              }}
            >
              <option value='01'>Január</option>
              <option value='02'>Február</option>
              <option value='03'>Március</option>
              <option value='04'>Április</option>
              <option value='05'>Május</option>
              <option value='06'>Június</option>
              <option value='07'>Július</option>
              <option value='08'>Augusztus</option>
              <option value='09'>Szeptember</option>
              <option value='10'>Október</option>
              <option value='11'>November</option>
              <option value='12'>December</option>
            </Select>
            <Select
              placeholder='Nap'
              name='birthday'
              isDisabled={!birthMonth}
              backgroundColor='gray.300'
              _placeholder={{ color: 'black' }}
              value={birthDay}
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                setBirthDay(e.target.value);
                setFormData({
                  ...formData,
                  birthday: `${birthMonth}/${e.target.value}`,
                });
              }}
            >
              {generateDayOptions()}
            </Select>
          </InputGroup>

          <Heading size='sm'>Allergia</Heading>

          <Box gridColumn='colStart / colEnd'>
            <CheckboxGroup
              isInline
              spacing={8}
              onChange={(values) => {
                const allergyIds = values.map((val) => {
                  return { id: val };
                });
                setFormData({ ...formData, allergies: allergyIds });
              }}
            >
              {generateAllergies()}
            </CheckboxGroup>
          </Box>

          <Box gridColumn='colStart / colEnd' w={[289.22, 286]} />
          <Box gridColumn='colStart / colEnd'>
            <Checkbox
              isInvalid={invalidateASZF}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setAszf(e.target.checked);
              }}
            >
              <a href={`/doc/szerzodesi-feltetelek-${company}.pdf`}>
                Elolvastam és elfogadom az Általános Szerződési Feltételeket
              </a>
            </Checkbox>
          </Box>
          <Button
            gridColumn='colStart / colEnd'
            variantColor='red'
            type='submit'
          >
            Regisztrálok
          </Button>
        </Grid>
      </FormControl>
    </Card>
  );
}

export default authConnector(RegisterForm);
