import {
  Box,
  Flex,
  Grid,
  Heading,
  Image,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/core';
import React from 'react';
import { generatePath, useParams } from 'react-router-dom';

import newsImage from '../../assets/news.png';
import orderImage from '../../assets/order.png';
import simplePayLogo from '../../assets/simplePayLogo.png';
import fbLogo from '../../assets/social/fbLogoBig.png';
import instaLogo from '../../assets/social/instagramLogoBig.png';
import { CITY_ID } from '../../config/categories';
import Card from '../Card';
import { InfoRouterLink, NavLink, NavRouterLink } from '../navbar/NavLinks';
import appStoreLogo from '../../assets/social/appstore.svg';
import playStoreLogo from '../../assets/social/playstore.svg';
import { configFile } from '../../configFile';

export function FooterInfo(): JSX.Element {
  const { city } = useParams<{ city: string }>();
  return (
    <Flex color='black' flexDirection={['column', 'row', 'row']} width='100%'>
      <Card
        backgroundColor='#ffffff'
        fontWeight='bold'
        borderRadius={['16px 16px 0px 0px', '16px 0px 0px 16px']}
        m={['0.25rem 0 0.25rem 0', '0.25rem 0.25rem 0.25rem 0']}
        py={4}
        px={8}
        flex='2 0 auto'
      >
        <Heading textAlign='center' mb={2} as='h6' size='md'>
          Házhozszállítás
        </Heading>
        <Heading textAlign='center' mb={2} as='h6' size='sm'>
          Rendelésfelvétel
        </Heading>
        <Flex
          mb={2}
          flexDir='column'
          justifyContent='center'
          alignItems='center'
        >
          {city === 'szolnok' ? (
            <Text>06-56-200-200</Text>
          ) : (
            <Text>06-76-200-200</Text>
          )}
        </Flex>
        <Heading textAlign='center' mb={2} as='h6' size='md'>
          Nyitvatartás
        </Heading>

        {city === 'szolnok' ? (
          <Flex flexDir='column' justifyContent='center' alignItems='center'>
            <Text>H-Cs.: 09:30-22:00</Text>
            <Text>P-Sz.: 09:30-23:00</Text>
            <Text>V.: 11:30-22:00</Text>
          </Flex>
        ) : (
          <Flex flexDir='column' justifyContent='center' alignItems='center'>
            <Text>H-Cs.: 10:00-22:00</Text>
            <Text>P-Sz.: 10:00-23:00</Text>
            <Text>V.: 11:30-22:00</Text>
          </Flex>
        )}
      </Card>
      <Card
        backgroundColor='white'
        fontWeight='600'
        m={['0.25rem 0 0.25rem 0', '0.25rem 0.25rem 0.25rem 0.25rem']}
        borderRadius={0}
        py={4}
        px={8}
        flex='2 0 auto'
      >
        <Flex justifyContent='space-around'>
          <Flex direction='column'>
            <InfoRouterLink
              href={generatePath('/:city/:category', {
                city,
                category: 'pizza',
              })}
            >
              Pizza
            </InfoRouterLink>
            <InfoRouterLink
              href={generatePath('/:city/:category', {
                city,
                category: 'gyros',
              })}
            >
              Gyros
            </InfoRouterLink>
            <InfoRouterLink
              href={generatePath('/:city/:category', {
                city,
                category: 'freshlybaked',
              })}
            >
              Frissensült
            </InfoRouterLink>
            <InfoRouterLink
              href={generatePath('/:city/:category', {
                city,
                category: 'pasta',
              })}
            >
              Tészta
            </InfoRouterLink>
            <InfoRouterLink
              href={generatePath('/:city/:category', {
                city,
                category: 'salad',
              })}
            >
              Saláta
            </InfoRouterLink>
            <InfoRouterLink
              href={generatePath('/:city/:category', {
                city,
                category: 'dessert',
              })}
            >
              Desszert
            </InfoRouterLink>
            <InfoRouterLink
              href={generatePath('/:city/:category', {
                city,
                category: 'drink',
              })}
            >
              Ital
            </InfoRouterLink>
            <InfoRouterLink
              href={generatePath('/:city/:category/default', {
                city,
                category: 'creator',
              })}
            >
              Extra Pizza
            </InfoRouterLink>
          </Flex>

          <Flex
            direction={['column', 'column-reverse']}
            justifyContent='space-between'
          >
            <Box>
              <Flex direction='column'>
                <a
                  style={{ textDecoration: 'none' }}
                  href={`/allergy${city}.pdf`}
                >
                  Allergének
                </a>
                <InfoRouterLink
                  href={generatePath('/:city/:category', {
                    city,
                    category: 'about',
                  })}
                >
                  Rólunk
                </InfoRouterLink>
                <InfoRouterLink
                  href={generatePath('/:city/:category', {
                    city,
                    category: 'news',
                  })}
                >
                  Aktuális
                </InfoRouterLink>
              </Flex>
            </Box>
            <Box flex-direction='column'>
              <Flex
                direction='column'
                alignItems='space-around'
                justifyContent='space-around'
              >
                <Link
                  height='45px'
                  w={['120px', '80px', '110px']}
                  href='https://apps.apple.com/hu/app/pizzasprint/id1591011543?fbclid=IwAR0s5XmqVHIl8Mkd_swCk5E6_hiDWARXL0wyt2oA2wO9ggGHSTOOj0HhJLM'
                >
                  <Image src={appStoreLogo} />
                </Link>
                <Link
                  height='45px'
                  w={['120px', '80px', '110px']}
                  href='https://play.google.com/store/apps/details?id=pizzasprint.app'
                >
                  <Image src={playStoreLogo} />
                </Link>
              </Flex>
            </Box>
          </Flex>
        </Flex>
      </Card>
      <Card
        backgroundColor='#ffffff'
        borderRadius={['0px 0px 16px 16px', '0px 16px 16px 0px']}
        m={['0.25rem 0 0.25rem 0', '0.25rem 0 0.25rem 0.25rem']}
        py={4}
        px={8}
        flex='1 0 64px'
      >
        {city === 'szolnok' ? (
          <Grid
            w='100%'
            h='100%'
            gridTemplateColumns={['64px 64px', '64px']}
            justifyContent='space-around'
            alignItems='center'
          >
            <Link
              height={16}
              width='auto'
              href='https://facebook.com/pizzasprint/'
            >
              <Image src={fbLogo} />
            </Link>
            <Link
              height={16}
              width='auto'
              href='https://www.instagram.com/pizza_sprint_szolnok/'
            >
              <Image src={instaLogo} />
            </Link>
          </Grid>
        ) : (
          <Grid
            w='100%'
            h='100%'
            gridTemplateColumns={['64px 64px', '64px']}
            justifyContent='space-around'
            alignItems='center'
          >
            <Link
              height={16}
              width='auto'
              href='https://facebook.com/kecskemetipizzasprint/'
            >
              <Image src={fbLogo} />
            </Link>
            <Link
              height={16}
              width='auto'
              href='https://www.instagram.com/pizza_sprint_kecskemet/'
            >
              <Image src={instaLogo} />
            </Link>
          </Grid>
        )}
      </Card>
    </Flex>
  );
}

export function FooterLegal(): JSX.Element {
  const { city } = useParams<{ city: string }>();
  const company: string =
    city === CITY_ID[0].name ? 'forno' : 'pastaline' ?? '';

  return (
    <Flex
      flexDirection={['column', 'column', 'row']}
      mt={8}
      w='100%'
      justifyContent='space-around'
      alignItems='center'
      color='link'
      textTransform='uppercase'
      wrap='wrap'
    >
      <Box
        fontSize='0.95rem'
        textTransform='initial'
        color='faded'
        textAlign='center'
      >
        <NavLink
          fontSize='0.95rem'
          href='http://simplepartner.hu/PaymentService/Fizetesi_tajekoztato.pdf'
          textTransform='lowercase'
        >
          <Image src={simplePayLogo} maxH='48px' />
        </NavLink>
      </Box>
      <NavLink
        fontSize='0.95rem'
        href='mailto:ugyfelszolgalat@pizzasprint.hu'
        textTransform='lowercase'
      >
        ugyfelszolgalat@pizzasprint.hu
      </NavLink>
      <NavRouterLink
        target='_blank'
        fontSize='0.875rem'
        href={`/doc/adatkezeles-${company}.pdf`}
      >
        Adatvédelem
      </NavRouterLink>
      <NavRouterLink
        target='_blank'
        fontSize='0.875rem'
        href={`/doc/szerzodesi-feltetelek-${company}.pdf`}
      >
        Szerződési feltételek
      </NavRouterLink>
      <NavRouterLink
        target='_blank'
        fontSize='0.875rem'
        href={`/doc/husegpont.pdf`}
      >
        Hűségpont
      </NavRouterLink>
      <NavRouterLink
        target='_blank'
        fontSize='0.875rem'
        href={`/doc/bankkartyas-tajekoztato-${company}.pdf`}
      >
        Bankkártyás tájékoztató
      </NavRouterLink>
    </Flex>
  );
}

export function FooterImages({ images }): JSX.Element {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { city } = useParams<{ city: string }>();

  return (
    <Flex
      direction={['column', 'column', 'row']}
      width='100%'
      justifyContent='space-evenly'
      alignItems='center'
    >
      <Modal isOpen={isOpen} onClose={onClose} size='full'>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Aktuális szórólapunk</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Image
              src={
                city === 'szolnok'
                  ? `${configFile.serverBaseUrlPicture}${images.szolnok}`
                  : `${configFile.serverBaseUrlPicture}${images.kecskemet}`
              }
            />
          </ModalBody>
        </ModalContent>
      </Modal>
      <div style={{ cursor: 'pointer' }}>
        <Image src={newsImage} onClick={onOpen} />
      </div>
      <NavLink href='tel:0656200200'>
        <Image src={orderImage} />
      </NavLink>
      {/* <InstallPwaApp /> */}
    </Flex>
  );
}
