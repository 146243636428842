export const CITY_ID = [
  { name: 'szolnok', id: 1, address: 'Bocskai u. 16.' },
  { name: 'kecskemet', id: 2, address: 'Kéttemplom köz 12.' },
];
export const CATEGORY_CITY_ID = [
  { name: 'pizza', id: 3, city: CITY_ID[0] },
  { name: 'gyros', id: 5, city: CITY_ID[0] },
  { name: 'freshlybaked', id: 7, city: CITY_ID[0] },
  { name: 'pasta', id: 9, city: CITY_ID[0] },
  { name: 'salad', id: 11, city: CITY_ID[0] },
  { name: 'dessert', id: 13, city: CITY_ID[0] },
  { name: 'drink', id: 15, city: CITY_ID[0] },
  { name: 'creator', id: 17, city: CITY_ID[0] },

  { name: 'pizza', id: 4, city: CITY_ID[1] },
  { name: 'gyros', id: 6, city: CITY_ID[1] },
  { name: 'freshlybaked', id: 8, city: CITY_ID[1] },
  { name: 'pasta', id: 10, city: CITY_ID[1] },
  { name: 'hamburger', id: 749, city: CITY_ID[1] },
  { name: 'salad', id: 12, city: CITY_ID[1] },
  { name: 'dessert', id: 14, city: CITY_ID[1] },
  { name: 'drink', id: 16, city: CITY_ID[1] },
  { name: 'creator', id: 18, city: CITY_ID[1] },
];
export const ALLERGIES = [
  { id: 1, name: 'Glutént tartalmazó gabona' },
  { id: 2, name: 'Rákfélék' },
  { id: 3, name: 'Tojás' },
  { id: 4, name: 'Halak' },
  { id: 5, name: 'Szója' },
  { id: 6, name: 'Tej' },
  { id: 7, name: 'Diófélék' },
];
