import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Input } from '@chakra-ui/core';

class Autocomplete extends Component {
  static propTypes = {
    suggestions: PropTypes.instanceOf(Array),
    setIsAddressSelected: PropTypes.func,
    setData: PropTypes.func,
    data: PropTypes.any,
    isDisabled: PropTypes.bool,
  };

  static defaultProps = {
    suggestions: [],
  };

  constructor(props) {
    super(props);
    //@ts-ignore
    this.myInput = React.createRef();

    this.state = {
      // The active selection's index
      activeSuggestion: 0,
      // The suggestions that match the user's input
      filteredSuggestions: [],
      // Whether or not the suggestion list is shown
      showSuggestions: false,
      // What the user has entered
      userInput: '',
    };
  }

  onChange = (e) => {
    //@ts-ignore
    const { suggestions } = this.props;
    const userInput = e.currentTarget.value;

    const arr = [];
    suggestions.forEach((add) => arr.push(add.name));

    // Filter our suggestions that don't contain the user's input
    const filteredSuggestions = arr.filter(
      (suggestion) =>
        suggestion.toLowerCase().indexOf(userInput.toLowerCase()) > -1
    );

    this.setState({
      activeSuggestion: 0,
      filteredSuggestions,
      showSuggestions: true,
      userInput: e.currentTarget.value,
    });
    //@ts-ignore
    this.props.setIsAddressSelected(true);
    //@ts-ignore
    if (this.props.data.zip !== 5000 || 5008 || 6000)
      //@ts-ignore
      this.props.setData({
        //@ts-ignore
        ...this.props.data,
        street: e.currentTarget.value,
      });
    //@ts-ignore
    this.props.setIsAddressSelected(false);
  };

  onClick = (e) => {
    this.setState({
      activeSuggestion: 0,
      filteredSuggestions: [],
      showSuggestions: false,
      userInput: e.currentTarget.innerText,
    });
    //@ts-ignore
    this.props.setIsAddressSelected(true);
    //@ts-ignore
    this.props.setData({
      //@ts-ignore
      ...this.props.data,
      street: e.currentTarget.innerText,
    });
  };

  onKeyDown = (e) => {
    //@ts-ignore
    const { activeSuggestion, filteredSuggestions } = this.state;

    // User pressed the enter key
    if (e.keyCode === 13) {
      this.setState({
        activeSuggestion: 0,
        showSuggestions: false,
        userInput: filteredSuggestions[activeSuggestion],
      });
    }
    // User pressed the up arrow
    else if (e.keyCode === 38) {
      if (activeSuggestion === 0) {
        return;
      }

      this.setState({ activeSuggestion: activeSuggestion - 1 });
    }
    // User pressed the down arrow
    else if (e.keyCode === 40) {
      if (activeSuggestion - 1 === filteredSuggestions.length) {
        return;
      }

      this.setState({ activeSuggestion: activeSuggestion + 1 });
    }
  };

  render() {
    const {
      onChange,
      onClick,
      onKeyDown,
      state: {
        //@ts-ignore
        activeSuggestion,
        //@ts-ignore
        filteredSuggestions,
        //@ts-ignore
        showSuggestions,
        //@ts-ignore
        userInput,
      },
    } = this;

    let suggestionsListComponent;

    if (
      showSuggestions &&
      userInput &&
      //@ts-ignore
      (this.props.data.zip === 5000 || 5008 || 6000)
    ) {
      if (filteredSuggestions.length) {
        suggestionsListComponent = (
          <ul
            //@ts-ignore
            class='suggestions'
            style={{
              borderTopWidth: 0,
              listStyle: 'none',
              marginTop: '0',
              maxHeight: '143px',
              overflowY: 'auto',
              paddingLeft: '5',
              gridColumn: 'colStart / colEnd',
              //@ts-ignore
              width: `${this.myInput.current.offsetWidth}`,
              textAlign: 'left',
            }}
          >
            {filteredSuggestions.map((suggestion, index) => {
              let className;

              // Flag the active suggestion with a class
              if (index === activeSuggestion) {
                className = 'suggestion-active';
              }

              return (
                <li
                  className={className}
                  key={suggestion}
                  onClick={onClick}
                  style={{
                    cursor: 'pointer',
                    backgroundColor: '#EDF2F7',
                    paddingLeft: 5,
                    paddingTop: 2,
                  }}
                >
                  {suggestion}
                </li>
              );
            })}
          </ul>
        );
      } else {
      }
    }

    return (
      <Fragment>
        <Input
          //@ts-ignore
          ref={this.myInput}
          gridColumn='colStart / colEnd'
          type='text'
          placeholder='Utca'
          size='sm'
          //@ts-ignore
          isDisabled={this.props.isDisabled}
          backgroundColor='gray.300'
          _placeholder={{ color: 'black' }}
          onChange={onChange}
          onKeyDown={onKeyDown}
          value={userInput}
        />
        {
          //@ts-ignore
          (this.props.data.zip === 5000 || 5008 || 6000) &&
            suggestionsListComponent
        }
      </Fragment>
    );
  }
}

export default Autocomplete;
