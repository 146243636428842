import { Box } from '@chakra-ui/core';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
  HomeBackgrounds,
  HomeForms,
  HomeBackgroundsSzolnok,
} from '../components/home/HomeComponents';
import {
  AuthWhatsOpen,
  connector,
  ReduxProps,
} from '../components/home/HomeConstants';
import { CITY_ID } from '../config/categories';
import Navbar from '../components/navbar/Navbar';
import Footer from '../components/footer/Footer';

function HomeScreen(reduxProps: ReduxProps): JSX.Element {
  const [whatsOpen, setwhatsOpen] = useState(
    reduxProps.isLoggedIn ? AuthWhatsOpen.about : AuthWhatsOpen.login
  );

  const { city } = useParams<{ city: string }>();

  const cityId = CITY_ID.find((e) => e.name === city)?.id;

  const [height, setHeight] = React.useState(0);

  useEffect(() => {
    if (reduxProps.categories.length === 0) {
      reduxProps.ProductActions.fetchAllCategory();
      reduxProps.ProductActions.fetchAllProduct();
    }

    reduxProps.NewsActions.fetchHomeBackgrounds();

    return () => {};
  }, [
    cityId,
    reduxProps.NewsActions,
    reduxProps.ProductActions,
    reduxProps.categories.length,
  ]);

  useEffect(() => {
    if (reduxProps.isLoggedIn) {
      setwhatsOpen(AuthWhatsOpen.about);
      reduxProps.AuthActions.getUserInfo();
    } else {
      setwhatsOpen(AuthWhatsOpen.login);
    }
    return () => {};
  }, [reduxProps.isLoggedIn, reduxProps.AuthActions]);

  return (
    <>
      <Navbar />
      <Box
        as='main'
        zIndex={99}
        minHeight={[
          `${height + 400}px`,
          `${height + 440}px`,
          `${height + 440}px`,
          `${height}px`,
        ]}
        mb={['75px', '75px', '75px', '0']}
      >
        <Box position='relative'>
          <HomeForms
            whatsOpen={whatsOpen}
            setwhatsOpen={setwhatsOpen}
            reduxProps={reduxProps}
            mt={[`${height - 10}px`, `${height}px`, `${height}px`, 4]}
          />
        </Box>
        {city === 'szolnok' ? (
          <HomeBackgroundsSzolnok
            reduxProps={reduxProps}
            setHeight={setHeight}
            height={height}
          />
        ) : (
          <HomeBackgrounds
            reduxProps={reduxProps}
            setHeight={setHeight}
            height={height}
          />
        )}
      </Box>
      <Footer />
    </>
  );
}

export default connector(HomeScreen);
