import { theme } from '@chakra-ui/core';
import { customIcons } from './custom-icons';

const customTheme = {
  ...theme,
  ...theme.breakpoints,
  breakpoints: ['36em', '48em', '62em', '80em'],
  icons: {
    ...theme.icons,
    ...customIcons,
  },
  fontSizes: {
    ...theme.fontSizes,
    xd: '16px',
  },
  colors: {
    ...theme.colors,
    bg: '#2e2e2e',
    link: '#dddddd',
    text: '#eeeeee',
    faded: '#cccccc',
    muted: '#aaaaaa',
    darkbutton: {
      500: '#2e2e2e',
      600: '#262626',
      700: '#1e1e1e',
    },
    blood: {
      50: '#d21e47',
      500: '#a61d39',
      900: '#731a22',
    },
    secondary: '#085573',
    tertiary: '#F47E25',
  },
};

export default customTheme;
